import { ListComponent } from './list/list.component';
import { CuisineComponent } from './cuisine/cuisine.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageOrderComponent } from './manage-order.component';

const routes: Routes = [
  { path: '', component: ListComponent },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
 
})
export class ManageOrderRoutingModule { }
