import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// constants
import { callAPIConstants } from '../constants/callAPI-constants';
import { URLConstants } from '../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';
@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;
	public Role;
	constructor(public localstorage: LocalStorageService, private router: Router, private commonService: CommonService,
		private showErrorService: ShowErrorService) {
			console.log(' in logout');
		 }
	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {
		console.log('iside logout method');
		let Id;
		let Pass;
		let remember;
		if (this.localstorage.getToken('Id') && this.localstorage.getToken('Pass')) {
			console.log('inside remember');
			Id = this.localstorage.getToken('Id');
			Pass = this.localstorage.getToken('Pass');
			remember = true;
		}
		if(this.localstorage.getToken('role')==='Super Admin'){
			console.log('in Admin logout');
		this.commonService.callApi(this.callAPIConstants.AdminLogout, '', 'get', false, false, false).then((success) => {
			if (success.status === 1) {
				this.showErrorService.popToast('success', success.message);
				this.router.navigate([URLConstants.LOGIN]);
				this.localstorage.clearToken();
				if (remember) {
					this.localstorage.setToken('Id', Id);
					this.localstorage.setToken('Pass', Pass);
				}
			}
		})
		}else if(this.localstorage.getToken('role')==='vendor'){
			console.log('in vendor logout');
			this.commonService.callApi(this.callAPIConstants.UserLogout, '', 'get', false, false, false).then((success) => {
				if (success.status === 1) {
					this.showErrorService.popToast('success', success.message);
					this.router.navigate([URLConstants.LOGIN]);
					this.localstorage.clearToken();
					if (remember) {
						this.localstorage.setToken('Id', Id);
						this.localstorage.setToken('Pass', Pass);
					}
				}
			})
		}else{
			this.showErrorService.popToast('success','logout sucessfully');
					this.router.navigate([URLConstants.LOGIN]);
					this.localstorage.clearToken();
		}
	}
	/*************************************************************/
}
