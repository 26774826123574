export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    RESET_PASS: '/reset-password',
    SIGNUP: '/register',
    DASHBOARD: '/dashboard',
    OTP: '/enter-otp/',
    /***** Authorized Pages *****/
    CHANGE_PASS: '/change-password',
    MY_PROFILE: '/my-profile',
    VENDOR_PRO:'/vprofile',
    //VENDOR_PROFILE:'/vendor-profile/update',
  VENDOR_PROFILE:'/v-profile',
    MASTER: '/master',
    USERS: '/users',
    ADD_USER:'/users/add',
    ADMINUSERS : '/admin-users',
    ADDADMINUSER: '/admin-users/add-user',
    EDITADMINUSER: '/admin-users/edit-details/',

    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',

    // Email Templates
    EMAIL_TEMPLATE_list: '/email-template',
    EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

    // content Management
    STATIC_PAGE: '/content/Static-Page',
    ADD_STATIC_PAGE: '/content/Add-static-page',
    EDIT_STATIC_PAGE: '/content/edit-static-page/',
    VIEW_STATIC_DETAILS: '/content/view-static-page-details',

    BLOGS_CATEGORY: '/content/blog-category',
    BLOGS: '/content/blogs',
    BLOG_ADD: '/content/blogs-add',
    BLOG_EDIT: '/content/blog-edit/',
    BLOG_PREVIEW: '/content/blog-preview/',
    FAQS_VENDOR:'/content/faqs',
    FAQS: '/faq' ,
    ADD_CATEGORY:'/faq/adds',
    ADD_FAQSCATEGORY:'/faq/edit-category/',
    ADD_FAQ:'/faq/addfaqs',
    ADD_UPDATEFAQ:'/faq/addfaq/',
    CMS: '/cms',
    CMS_DETAIL:'/cms/view/',
    CMS_EDIT:'/cms/edit-details/',
    HOME: '/home',
    HOME_DETAILS: '/home/details',
    VIEW_CMS: '/cms/view-details/',
    ADD_NEW_CMS: '/cms/addNewCms',

    // user list  
    USER: '/users',
    USERS_DETAIL: '/users/view/',
    ADDUPDATEUSER: '/users/add/',
    ///navigate menus
    PAYOUTS:'/payouts',
    REPORTS:'/reports',
    NOTIFY:'/notifications',
    REVIEW:'/review',
    EARNING:'/earning/list',
    WASTE_MANAGEMENT:'/waste-management',
    TICKET_MANAGEMENT:'/ticket-management',
    TICKET:'/ticket',
    DRIVERS:'/drivers',
    DRIVER_VIEW_DETAILS: '/drivers/view-details/',
    DRIVER_DETAIL: '/drivers/edit-details/',
    ADD_DRIVER: '/drivers/add-driver',
    VENDOR:'/vendors',
    VENDOR_DETAIL: '/vendors/edit-details/',
    ADD_VENDOR: '/vendors/add-vendor',
    VIEW_VENDOR: '/vendors/view-vendor/',
    //manage roles
    MANAGE_ORDER:'/manage-order',
    MANAGE_ROLES: '/manage-roles',
    ADD_ROLE:'/manage-roles/add-role',
    EDIT_ROLE:'/manage-roles/edit-details/',
    CUISINE:'/cuisine',
    VENDOR_CUISINE:'/cuisine/vendorlist',
    ADD_CUISINE:'/cuisine/add',
    ADD_UPDATE_CUISINE:'/cuisine/addUpdateCuisine/',
    CUISINE_DETAILS:'/cuisine/view/',
    PRODUCT : '/products',
    PRODUCT_ADD:'/products/addproduct',
    ADD_PRODUCT:'/products/add',
    EDIT_PRODUCT:'/products/update/',
    VIEW_PRODUCT:'/products/view/',
    ATTRIBUTE:'/attribute',
    ADD_ATTRIBUTE:'/attribute/add',
    ATTRIBUTE_DETAILS:'/attribute/view/',
    ATTRIBUTE_EDIT:'/attribute/edit/',
    MENU_CATEGORY:'/category',
    MENU_CATEGORY_ADDUPDATE:'/category/addUpdateCategory',
    CATEGORY_DETAILS:'/category/view/',
    CATEGORY_EDIT:'/category/edit/',
    PRODUCT_MANAGEMENT:'/products',
    MEAL_DEALS:'/mealDeal',
    DETAIL_MEAL_DEALS:'/mealDeal/view/',
    ADD_MEAL_DEALS:'/mealDeal/add',
    EDIT_MEAL_DEALS:'/mealDeal/addupdate/',
    ORDER:'/order',
    COUPAN:'/coupon-management',
    ADD_COUPAN:'/coupon-management/create',
    ViEW_COUPAN:'/coupon-management/view/',
    EDIT_COUPAN:'/coupon-management/edit/',
    VIEW_TICKET:'/ticket-management/vendorlist',
    CREATE_TICKET:'/ticket-management/create',
    ADMIN_TICKET_LIST:'/ticket-management/list',
    ADMIN_ANSWER_TICKET:'/ticket-management/answer/',
    PRODUCT_CATEGORY_LIST:'/category/',
};
