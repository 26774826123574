
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <div class="card-title">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
          </div>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-xl-2 col-sm-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-lg-2 col-sm-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <hr />
              <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">My profile</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              
                <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                  My Profile
                  </li>
              </ol>
          </nav>
       
  
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
              <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                    <div class="text">
                        <h5 class="mb-0 text Uppercase">Profile </h5>
                    </div>
              </div>
  <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
        <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
                 <div class="row">
            <div class="col-md-6 pb-3">
              <div class="card notification-card h-100">
                <label for="">address</label>
                <input type="text" 
                class="form-control" 
                name="address" 
                id="address"
                formControlName="address"
                (keydown.enter)="$event.preventDefault()"
                 placeholder="Search Nearest Location"
                 autocorrect="off"
                 autocapitalize="off"
                  spellcheck="off" type="text" #address>
            
              <div class="card-map">
                <div class="map-container">
                  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                      ></agm-marker>
                  </agm-map>
                </div>
              </div>
                
             </div>
            </div>
            <div class="col-md-6 pb-3">
              <div class="card notification-card ">
                <div class="row ">
                      <div class="col-md-6 form-group">
                        <label for="">businessName</label>
                        <input type="text" class="form-control"
                        formControlName="businessName"
                         name="businessName"
                          id="businessName"
                          >
                      </div>                
                     <div class="col-md-6 form-group">
                       <label for="">contactName</label>
                       <input type="text" class="form-control"
                        name="contactName" 
                        id="contactName"
                        formControlName="contactName">
                     </div>
                     <div class="col-md-6 form-group">
                       <label for="">accountHolderType</label>
                       <input type="text" class="form-control"
                        name="accountHolderType" 
                        id="accountHolderType"
                        formControlName="accountHolderType">
                     </div>
                     <div class="col-md-6 form-group">
                      <label for="">bankName</label>
                      <input type="text" class="form-control"
                       name="bankName" 
                       id="bankName"
                       formControlName="bankName">
                    </div>
                    <div class="col-md-6 form-group">
                      <label for="">accountHolderName</label>
                      <input type="text" class="form-control"
                      formControlName="accountHolderName"
                       name="accountHolderName" 
                       id="accountHolderName"
                       >
                    </div>
                    <div class="col-md-6 form-group" >
                      <label for="">routingNumber</label>
                      <input type="text" class="form-control"
                      formControlName="accountHolderName"
                       name="routingNumber" 
                       id="routingNumber"
                       >
                    </div>
                    <div class="col-md-12 form-group">
                      <label for="">accountNumber</label>
                      <input type="text" class="form-control"
                      formControlName="accountNumber"
                       name="accountNumber" 
                       id="accountNumber"
                      >
                    </div>
                    <!-- 1 categoryId -->
                      
                   </div>
                   </div>
            </div>
                </div>
          <div class="card notification-card mb-3">
            <table class="table table-bordered" formArrayName="availability">
              <tr>
                <th colspan="2">Add Day's:</th>
                <th width="150px"><button type="button" (click)="addavailability()" class="btn btn-primary">Add More</button></th>
              </tr>
              <tr *ngFor="let quantity of availability().controls; let i=index" [formGroupName]="i">
                <td>
                    Day :
                    <input type="text" formControlName="day" value="" class="form-control">
                </td>
                <td>
                  StartTime:
                    <input type="time" formControlName="startTime" class="form-control">
                </td>
                <td>
                  EndTime:
                    <input type="time" formControlName="endTime" class="form-control">
                </td>
                <td>
                    <button (click)="removeavailability(i)" class="btn btn-danger">Remove</button>
                </td>
              </tr>
            </table>
           
              </div>
              <div class="card notification-card ">
                  <div class="row">
                   
                      <div class="col-md-3 form-group">
                        <label for="file">bankProof:</label>
                        <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.bankProof!=''?bankProof:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <button class="btn">Upload a bankProof</button>
                        <input 
                            id="bankProof" 
                            type="file" 
                            name="bankProof"
                            class="form-control"
                            formControlName="bankProof"
                            (change)="fileChangeEvent($event,template,'bankProof')">
                      </div>
                      
                      </div>
                      <div class="col-md-3 form-group">
                        <label for="file">liquourLicense:</label>
                        <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.liquourLicense!=''?liquourLicense:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <button class="btn">Upload a liquourLicense</button>
                        <input 
                        formControlName="liquourLicense"
                            id="liquourLicense" 
                            type="file" 
                            name="liquourLicense"
                            class="form-control"
                            (change)="fileChangeEvent($event,template,'liquourLicense')">
                      </div>
                      </div>
                      <div class="col-md-3 form-group">
                        <label for="file">smartServeCertification:</label>
                        <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.smartServeCertification!=''?smartServeCertification:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <button class="btn">Upload a smartServeCertification</button>
                        <input 
                        formControlName="smartServeCertification"
                            id="smartServeCertification" 
                            type="file" 
                            name="smartServeCertification"
                            class="form-control"
                            (change)="fileChangeEvent($event,template,'smartServeCertification')"
                            >
                      </div>
                      </div>
                

                        <div class="col-md-3 form-group" >
                          <label for="file">foodCertificate:</label>
                          <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.foodCertificate!=''?this.foodCertificate:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                            <button class="btn">Upload a foodCertificate</button>
                          <input 
                          formControlName="foodCertificate"
                              id="foodCertificate" 
                              type="file" 
                              name="foodCertificate"
                              class="form-control"
                              (change)="fileChangeEvent($event,template,'foodCertificate')"
                              >
                        </div>
                        </div>
                        <div class="col-md-3 form-group">
                          <label for="file">foodPremiseInspectionReport:</label>
                          <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.foodPremiseInspectionReport!=''?this.foodPremiseInspectionReport:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                            <button class="btn">Upload a foodPremiseInspectionReport</button>
                          <input 
                          formControlName="foodPremiseInspectionReport"
                              id="foodPremiseInspectionReport" 
                              type="file" 
                              name="foodPremiseInspectionReport"
                              class="form-control"
                              (change)="fileChangeEvent($event,template,'foodPremiseInspectionReport')"
                              >
                        </div>
                        </div>
                        <div class="col-md-3 form-group">
                          <label for="file">foodManagerCertififcate:</label>
                          <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.foodManagerCertififcate!=''?this.foodManagerCertififcate:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                            <button class="btn">Upload a foodManagerCertififcate</button>
                          <input 
                          formControlName="foodManagerCertififcate"
                              id="foodManagerCertififcate" 
                              type="file" 
                              name="foodManagerCertififcate"
                              class="form-control"
                              (change)="fileChangeEvent($event,template,'foodManagerCertififcate')"
                              >
                        </div>
                        </div>
                       
                        <div class="col-md-3 form-group">
                          <label for="file">restaurantLogo:</label>
                          <div class="upload-btn-wrapper">
                          <div class="profile-upload">
                            <img [src]="this.restaurantLogo!=''?this.restaurantLogo:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                            <button class="btn">Upload a restaurantLogo</button>
                          <input 
                          formControlName="restaurantLogo"
                              id="restaurantLogo" 
                              type="file" 
                              name="restaurantLogo"
                              class="form-control"
                              (change)="fileChangeEvent($event,template,'restaurantLogo')"
                             >
                        </div>
                        </div>
                      </div>
                      <div class="row ">
                        <div class="col-md-3 form-group pb-2">
                        <div class="custom-control custom-switch">
                              &nbsp; <div></div>
                              <input type="checkbox" 
                              class="custom-control-input"  
                              formControlName="isProfileCompleted"
                              name= "isProfileCompleted" 
                              id="isProfileCompleted">
                              <label class="custom-control-label" for="isProfileCompleted"> isProfileCompleted</label>
                        </div>
                        </div>
                        <div class="col-md-3 form-group pb-2">
                        <div class="custom-control custom-switch">
                              &nbsp; <div></div>
                              <input type="checkbox" 
                              class="custom-control-input"  
                              formControlName="isProvideTakeAway"
                              name= "isProvideTakeAway"
                              id="isProvideTakeAway">
                              <label class="custom-control-label" for="isProvideTakeAway"> isProvideTakeAway</label>
                            </div>
                      </div>
                      </div>
                  
                      <div class="row">
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                          <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                            (dragEnd)="markerDragEnd($event)"></agm-marker>
                        </agm-map>
                      </div>
             
               <div class="text-right submit-btn-wrap" >
                <button type="submit" class="btn btn-primary submit-btn mr-2">Submit</button>
                <button type="button" class="btn btn-outline-primary submit-line-btn" [routerLink]="[URLConstants.PRODUCT]">Cancel</button>

            </div> 
               </div>
                
               </form>
              
            
            </div>    
      
      </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>