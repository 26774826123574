<div class="assign-order-modal">
	<div class="modal-header">
		<h4 class="modal-title text-center w-100">Aproval User</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" style="width: 500px;">
  <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
    <div class="row">
			<div class="col-md-12">
       
        <ng-container *ngIf="this.isVerify" >
          <div class="col-md-12">
            <p class="text-danger">{{this.title |titlecase |uppercase}} is approved and its role {{this.role |uppercase|titlecase}}</p>
           </div> 
          
        </ng-container>
        <ng-container *ngIf="!this.isVerify" >
          <div class="col-md-12">
            <p class="text-primary">Do you want to approved  {{this.title |titlecase |uppercase}} of role {{this.role |uppercase|titlecase}}</p>
           </div> 
           <div class="col-md-6">
            <button class="btn btn-primary" (click)="UserApproval()" type="submit">Confirmed</button>
        </div> 
          
        </ng-container>
                  
			</div>		
		</div>
    </div>
	</div>
</div>
