import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';

import { FaqComponent } from './faq.component';
import { ListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
  {
    path: 'list', component: ListComponent, pathMatch: 'full',
  },
  {
    path: 'edit-category/:id',
    component: EditDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'adds',
    component: EditDetailsComponent,
    pathMatch: 'full',
  },{
    path:'addfaq/:id',
    component:EditCategoryComponent,
    pathMatch:'full'
  },
  {
    path: 'addfaqs',
    component: EditCategoryComponent,
    pathMatch: 'full',
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule { }
