import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

// Auth Guard
import { CanLoginActivate, CanAuthActivate } from "./common/service/auth-guard";

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { RegisterComponent } from "./public/register/register.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { LoginWithMobileComponent } from "./public/login-with-mobile/login-with-mobile.component";
import { LoginWithEmailComponent } from "./public/login-with-email/login-with-email.component";
import { SocialLoginComponent } from "./public/social-login/social-login.component";
import { EnterOtpComponent } from "./public/enter-otp/enter-otp.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { MasterComponent } from './modules/master/master.component';

// plugin
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// module
import { SharedModule } from './shared/shared.module';
import { MealDealModule } from "./modules/meal-deal/meal-deal.module";
import { ProductsModule } from "./modules/products/products.module";
import { FaqModule } from "./modules/content-management/faq/faq.module";
import { VendorProfileModule } from "./modules/vendor-profile/vendor-profile.module";
import { CategoryModule } from "./modules/category/category.module";
import { CuisineModule } from "./modules/cuisine/cuisine.module";
import { ManageRolesModule } from "./modules/manage-roles/manage-roles.module";
import { AttributeModule } from "./modules/attribute/attribute.module";
import { TicketManagementModule } from "./modules/ticket-management/ticket-management.module";
import { NotificationModule } from "./modules/notification/notification.module";
import { ManageOrderModule } from "./modules/manage-order/manage-order.module";
import { ReviewModule } from "./modules/review/review.module";
import { OrderModule } from "./modules/order/order.module";
import { EarningModule } from "./modules/earning/earning.module";
const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "LoginWithMobile",
    canActivate: [CanLoginActivate],
    component: LoginWithMobileComponent,
    pathMatch: "full",
  },
  {
    path: "register",
    canActivate: [CanLoginActivate],
    component: RegisterComponent,
    pathMatch: "full",
  },
  {
    path: "forgot-password",
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "social-login",
    canActivate: [CanLoginActivate],
    component: SocialLoginComponent,
    pathMatch: "full",
  },
  {
    path: "enter-otp/:phNum/:otp",
    canActivate: [CanLoginActivate],
    component: EnterOtpComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: 'master', component: MasterComponent, pathMatch: 'full' },

      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: "users",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/users/users.module").then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./modules/change-password/change-password.module')
          .then((m) => m.ChangePasswordModule),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./modules/setting/setting.module')
          .then(m => m.SettingModule)
      },
      {
        path: "content",
        loadChildren: () =>
          import("./modules/content-management/content-management.module").then(
            (m) => m.ContentManagementModule
          ),
      },
      {
        path: 'email-template',
        // canActivate: [CanViewListActivate],
        data: { role: 'EmailTemplate' },
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      { path: 'v-profile', loadChildren: () => import('./modules/v-profile/v-profile.module').then(m => m.VProfileModule) },
      { path: 'vprofile', loadChildren: () => import('./modules/vprofile/vprofile.module').then(m => m.VprofileModule) },
      { path: 'review', loadChildren: () => {return ReviewModule }},
      { path: 'order', loadChildren: () => {return OrderModule} },
      {path:'notifications',loadChildren: () =>{return NotificationModule }},
      //{ path: 'mealDeal', loadChildren: () => import('./modules/meal-deal/meal-deal.module').then(m => m.MealDealModule) },  
      { path: 'mealDeal', loadChildren:() => {return MealDealModule}  },
      { path: 'earning', loadChildren: () => { return EarningModule}},
      //{ path: 'attribute', loadChildren: () => import('./modules/attribute/attribute.module').then(m => m.AttributeModule) },
      //{ path: 'category', loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule) },
      //{path: 'category', loadChildren:() => {return CategoryModule;} },
      { path: 'cuisine', loadChildren:() => {return CuisineModule;}  },
      {path: 'category', loadChildren:() => {return CategoryModule;} },
      { path: 'products', loadChildren:() => {return ProductsModule;}},
      {path:'attribute',loadChildren:() => {return AttributeModule;}},
      {path:'faq',loadChildren:()=>{return FaqModule;}},
      { path: 'manage-order', loadChildren: () => {return ManageOrderModule }},
      {path: 'users',canActivate: [CanAuthActivate],loadChildren:() => import("./modules/user-management/users/users.module").then((m) => m.UsersModule)},
      // { path: 'faq', loadChildren: () => import('./modules/content-management/faq/faq.module').then(m => m.FaqModule) },
      { path: 'coupon-management', loadChildren: () => import('./modules/coupon-management/coupon-management.module').then(m => m.CouponManagementModule) },
      { path: 'cms',loadChildren:()=>import('./modules/content-management/cms/cms.module').then(m=>m.CmsModule)},
      //{ path: 'manage-roles', loadChildren: () => import('./modules/manage-roles/manage-roles.module').then(m => m.ManageRolesModule) },
      { path:'manage-roles',loadChildren:()=>{return ManageRolesModule;}},
      { path: "admin-users",canActivate: [CanAuthActivate],loadChildren: () =>import("./modules/user-management/admin-users/admin-users.module").then((m) => m.AdminUsersModule )},
      { path: 'vendors', loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule) },
      { path: 'drivers', loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule) },
      {path:'ticket-management',loadChildren:()=>{return TicketManagementModule;}},
      //{ path:'ticket-management',loadChildren:()=>import('./modules/ticket-management/ticket-management.module').then(m=>m.TicketManagementModule)},
      { path: 'waste-management', loadChildren: () => import('./modules/waste-management/waste-management.module').then(m => m.WasteManagementModule) },
      { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'payouts', loadChildren: () => import('./modules/payouts/payouts.module').then(m => m.PayoutsModule) },
      { path: 'vendor-profile', loadChildren: () => {return VendorProfileModule} },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
 
  { path: 'agm-map', loadChildren: () => import('./modules/agm-map/agm-map.module').then(m => m.AgmMapModule) },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },

];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginWithMobileComponent,
    LoginWithEmailComponent,
    SocialLoginComponent,
    EnterOtpComponent,
    MainComponent,
    MasterComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
    ],
  exports: [RouterModule],
  providers: [
    CanAuthActivate,
    CanLoginActivate,
  ],
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
];
