<!-- BEGIN: Sub Header  -->
<!-- <app-sub-header [subHeaderData]="subHeaderData"></app-sub-header> -->
<!-- END: Sub Header  -->


<div class="dashboard-container">
  <!-- Main start here -->
  <div class="main-content-area">
      <!-- Header start -->

      <!-- Sidebar end -->

      <!-- Body overlay -->
      <div class="overlay"></div>
      <!-- -- Body overlay -->
      <!-- Header end -->
      <div class="main-content-block">
          <h3 class="content-header-title">Ticket question</h3>
          <div class="d-flex align-items-center justify-content-between flex-wrap">
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                  
                      <li class="breadcrumb-item"><a href="dashboard.html"><i class="bx bx-home-alt"></i></a></li>
                      <li class="breadcrumb-item"><a routerLink="/users/list">Ticket Management</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Answer</li>
                  </ol>
              </nav>

          </div>
          <div class="row">
              <div class="col-md-12 mb-2">
                <div class="card notification-card tabs-block p-0">
              
                  <form
                  (ngSubmit)="AnswerForm.form.valid &&
                  AnswerCall(AnswerForm,AnswerFormData)"
                           #AnswerForm="ngForm" novalidate>
                            <div class="card-body">
                            <div class="row">
                              <div class="col-12">

                                <div class="form-group ">
                                  <label class="d-flex text-primary">
                                    
                                  </label>
                                </div>
                              </div>
                                <div class="col-md-12">
                                    <div
                                      class="form-group align-items-center user-details"
                                    >
                          <label class="quetion-apper-wrap">
                                    <div class="quetion-heading">
                                      Answer   
                                    </div>  
                                    <!-- <div class="quetion-status">
                                      <div class="status-heading">
                                        Status
                                      </div>
                                      <div class="custom-control custom-switch light">
                                        <input type="checkbox" class="custom-control-input"
                                            id="switchCheckbox13" checked>
                                        <label class="custom-control-label"
                                            for="switchCheckbox13"></label>
                                    </div>
                                    </div>   -->
                                  </label>  
                               
                                    <textarea 
                                    id="answer"
                                     placeholder="Enter Your Answer"
                                      [ngClass]="((submitted || answer.touched) && answer.invalid)?'has-danger':''"
                                      [(ngModel)]="AnswerFormData.answer"
                                      id="code"
                                      #answer="ngModel"                           
                                      autocomplete="off"
                                      required 
                                     name="answer" rows="4" cols="50" 
                                     class="form-control"></textarea>
                                    </div>
                                </div>
                              
                             </div>
                             
                           
      
                             <div class="text-right submit-btn-wrap"><button type="submit" class="btn btn-primary submit-btn">Submit</button></div>
                            </div> 
                        </form>        
                            
                   
                    <!-- </tab>
                    </tabset> 
                    </div> -->
           </div>
              </div>
          </div>
      </div>
      <footer>
          <div class="footer-text d-flex align-items-centerf justify-content-between">
              <span class="d-block">2020 © IndiaNIC</span>
              <span class="d-flex align-items-center">Crafted with <i class="bx bxs-heart text-danger ml-1 mr-1"></i> in INDIA </span>
          </div>
      </footer>
  </div>
</div>