//import { languageType } from './../../common/constants/categoryType-constants';
import { Component, OnInit,ViewChild, ElementRef, NgZone, TemplateRef  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LoadedImage } from 'ngx-image-cropper';

import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  bsValue = new Date();
  fileToUpload: any;
   imageUrl: any;
   Image:any;
  //bsValue1= new Date();
  uploadImageUrl = environment.uploadImgUrl;
  mealID:any;
  productForm: FormGroup;
   formData = new FormData();
   imageSrc: string;
   callAPIConstants = callAPIConstants;
   URLConstants = URLConstants;
   categoryList: any[] = [];
   foodTypeList:any[]=['Veg','Non-Veg','Eggetarian'];
   productCount:any={};
   public actID:any;
   Result: any;
  MealValue: any= 'Add';
  private _id: any;
   constructor(private activatedRoute: ActivatedRoute, 
   public commonService: CommonService, private router: Router,
   public showErrorService: ShowErrorService,
   public bsModalRef: BsModalRef,
   private modalService: BsModalService,
   public fb:FormBuilder,private mapsAPILoader: MapsAPILoader,
   private ngZone: NgZone,
   public localStorageService: LocalStorageService,
  ) {
    this.activatedRoute.params.subscribe((params) => { this.mealID = params.id; });
    //(this.mealID === undefined)? null :this.getMealdeal();    
    if(this.mealID !==undefined){
      this.getMealdeal();
      this.MealValue='Edit';
     }
  } 
 ngOnInit(): void {
   //this.getMealdeal();
   this.productForm = this.fb.group({
    name: ['',Validators.required],
    description: ['',Validators.required],
    price: ['',],
    discountPercentage: ['',Validators.required],
    //foodType: [''],
    categoryProducts: this.fb.array([ ]),
    image: ['',Validators.required],
    servingPeople:['',Validators.required],
   });
   }
   getMealdeal(){
    this.commonService.callApi(this.callAPIConstants.DetailsMealdeals + this.mealID,
      '', 'get', false, false).then((success) => {
        console.log(success.data) 
        //this._id= success.data._id;
        success.data.categoryProducts.forEach(element => {
          console.log(element);
          let categoryProductsFormGroup =  this.newcategoryproduct(element.catgeory,element.maximumSelectableProducts);
      console.log(categoryProductsFormGroup);
      element.products.forEach(element => {
        console.log(element);
       let productsFormGroup = this.newproductas(element.name);
       (<FormArray> categoryProductsFormGroup.get('products')).push(productsFormGroup);
      });
       (<FormArray>this.productForm.get('categoryProducts')).push(categoryProductsFormGroup);
        });
        this.productForm.controls['name'].patchValue(success.data.name);
        this.productForm.controls['price'].patchValue(success.data.price);
        this.productForm.controls['discountPercentage'].patchValue(success.data.discountPercentage);
        this.productForm.controls['servingPeople'].patchValue(success.data.servingPeople);
        this.productForm.controls['description'].patchValue(success.data.description);
        this.Image = this.uploadImageUrl + success.data.image;
      });
       
   }
 ///****************categoryProduct*************************************/
 categoryProduct(): FormArray {
  return this.productForm.get("categoryProducts") as FormArray
 }
 newcategoryproduct(catgeory='',maximumSelectableProducts='',products=[]):FormGroup{
   return this.fb.group({
    catgeory:[catgeory],
    maximumSelectableProducts:[maximumSelectableProducts],
    products:this.fb.array(products)
   })
 }
 addcategoryproduct() {
  this.categoryProduct().push(this.newcategoryproduct());
}
removecategoryproduct(ti) {
  this.categoryProduct().removeAt(ti);
}
//*****************************************************/
productas(ti) :FormArray{
  return this.categoryProduct().at(ti).get("products") as FormArray 
}
newproductas(name=''){
  return this.fb.group({
  name:[name]
  })
}
addproductas(ti: number) {
  this.productas(ti).push(this.newproductas());
}
removeproductas(ti: number, bi: number) {
  this.productas(ti).removeAt(bi);
}
//*************************************/
 
 /***************getprofileof vendor */
 
  public submitted;
  onSubmit(){
    this.submitted = true;
    (this.mealID)?this.formData.append('_id',this.mealID):''
    this.formData.append('name',this.productForm.value.name);
    console.log(this.productForm.get('categoryProducts').value);
    let des = '';
    this.productForm.get('categoryProducts').value.forEach(element => {
     //des += element.catgeory + ' ' + element.maximumSelectableProducts + ' + ';
    des += element.maximumSelectableProducts + ' ' + element.catgeory + ' + ';
    });
    des = des.substring(0, des.length-3);
    
    this.formData.append('description',des);
    this.formData.append('servingPeople',this.productForm.value.servingPeople);
    this.formData.append('price',this.productForm.value.price);
    this.formData.append('discountPercentage',this.productForm.value.discountPercentage);
   this.formData.append('categoryProducts',JSON.stringify(this.productForm.value.categoryProducts));
      
    this.commonService.callApi(this.callAPIConstants.AddMealDeal, this.formData,'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.router.navigate([this.URLConstants.MEAL_DEALS]);
      } else { this.showErrorService.popToast('error', success.message);
      this.router.navigate([this.URLConstants.MEAL_DEALS]); }
    });

 }
 
   /****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
	/****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
    this.formData.append('image', this.file);
    console.log(this.file);
    
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {
    
  }

 
}
