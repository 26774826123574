import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {

  imageSrc: string;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  AttributeId: string;
  attributeValue='Add';
  this: any;
  // formData = new FormData();
 
 constructor(private activatedRoute: ActivatedRoute,
   public commonService: CommonService, private fb: FormBuilder,
   public showErrorService: ShowErrorService,private router: Router,
   private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe((params) => { this.AttributeId = params.id; });
    (this.AttributeId === undefined)? null :this.getCategoryDetail(); 
    if(this.AttributeId !==undefined){
      this.getCategoryDetail();
      this.attributeValue='Edit';
     }
}
getCategoryDetail(){
  this.commonService.callApi(this.callAPIConstants.getAttributeDetails + this.AttributeId,
    '', 'get', false, false).then((success) => {
     this.myForm.get('name').setValue(success.data.name);
     //this.myForm.get('image').setValue(success.data.image);

 });
 }
get f(){
  return this.myForm.controls;
}
   myForm: FormGroup;
  
  ngOnInit(){
    //this.getCategoryDetail();
    this.myForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]] 
    })
  }
  responseData:any={
     name:''
  }
  submitted=false;
  saveProduct(form){
    (this.AttributeId)?this.responseData._id=this.AttributeId:'' 
    this.submitted=true;
   console.log(this.myForm.value);
    this.responseData.name=form.value.name;
   this.commonService.callApi(this.callAPIConstants.addAttribute, this.responseData,'post', false, true, false).then((success) => {
    if (success.status === 1) {
      this.router.navigate([this.URLConstants.ATTRIBUTE]);
      this.showErrorService.popToast('success', success.message);
    } else {   this.router.navigate([this.URLConstants.ATTRIBUTE]);
      this.showErrorService.popToast('error', success.message); 
  
  }
  });
 }


}
