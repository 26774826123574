<aside class="sidebar">
    <script>
   
    </script>
    <div class="sidebar-inner-wrapper">

        <div class="sidebar-heading d-flex align-items-end justify-content-between w-100 ">
            <a class="navbar-brand">
                <div class="brand-logo">
                    <img src='../../../../assets/images/new-logo.png' class="header-logo" alt="Image Not uploaded" />
                    <!-- <img class="img-fluid" src="assets/images/new-logo.png" alt="branding logo"> -->
                </div>
            </a>
            <span (click)="closeSidebar()" class="sidebar-close-icon"><em class="bx bx-x"></em></span>
        </div>
        <ng-container *ngIf="roles==='Super Admin'" >
            <div class="sidebar-menu">
                <ul class="sidebar-menu-list">
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()" href="#" class="menu-link">
                            <i class='bx bx-home-alt'></i>
                            <span class="menu-title">Dashboard</span>
                        </a>
                    </li>
                                      
                    <!-- <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subManageMenu')" [ngClass]="subManageMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <i class='bx bx-link-alt'></i>
                            <span class="menu-title">Manage Order's</span>
                            <i class='bx bxs-chevron-right'></i>
                        </a>
                       
                    </li> -->
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.MANAGE_ORDER]" class="menu-link">
                            <i class='bx bx-check-circle'></i>
                            <span class="menu-title">Manage Order</span>
                        </a>
                    </li>
                    <li class="settign-menu menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subContentMenu')" [ngClass]="subContentMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <i class='bx bx-cog'></i>
                            <span class="menu-title">Contents</span>
                            <i class="bx bxs-chevron-right"></i>
                        </a>
                        <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subContentMenu ? 'show' : null">
                            <li class="menu-list-item">
                                <a id="cms" (click)="closeSidebarMenuItem('cms');" [routerLink]="[URLConstants.CMS]" class="menu-link">
                                    <i class='bx bxs-right-arrow-alt'></i>
                                    <span class="menu-title">Cms</span>
                                </a>
                            </li>
                            <li class="menu-list-item">
                                <a (click)="closeSidebar()" [routerLink]="[URLConstants.FAQS]" class="menu-link">
                                    <i class='bx bxs-right-arrow-alt'></i>
                                    <span class="menu-title">FAQ's</span>
                                </a>
                            </li>
                           
                        </ul>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.USERS]" class="menu-link">
                            <i class='bx bx-user'></i>
                            <span class="menu-title">User's</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.PAYOUTS]" class="menu-link">
                            <i class='bx bx-notification'></i>
                            <span class="menu-title">Payout</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.CUISINE]" class="menu-link">
                            <i class='bx bx-heart-circle'></i>
                            <span class="menu-title">Cuisine</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.MANAGE_ROLES]" class="menu-link">
                            <i class='bx bx-note'></i>
                            <span class="menu-title">Roles</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]"class="menu-link">
                            <i class='bx bx-message-detail'></i>
                            <span class="menu-title">Manage Email</span>
                        </a>
                    </li>
                    
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.WASTE_MANAGEMENT]" class="menu-link">
                            <i class='bx  bx-trash-alt'></i>
                            <span class="menu-title">Wasted Mile</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.ADMIN_TICKET_LIST]" class="menu-link">
                            <i class='bx bx-purchase-tag-alt'></i>
                            <span class="menu-title">Ticket's</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.PAYOUTS]" class="menu-link">
                            <i class='bx bx-check-circle'></i>
                            <span class="menu-title">settings</span>
                        </a>
                    </li>
                   
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="roles==='vendor'" >
            <div class="sidebar-menu">
                <ul class="sidebar-menu-list">
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()"  (click)="openCloseSubMenu('dashboard')" href="#" class="menu-link">
                            <i class='bx bx-home-alt'></i>
                            <span class="menu-title">Dashboard</span>
                        </a>
    
                    </li>
                    
                    
                    <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subManageMenu')" [ngClass]="subManageMenu ? 'submenu-open' : null">
                        <a style="cursor: pointer;" class="menu-link">
                            <i class='bx bx-link-alt'></i>
                            <span class="menu-title">Menu Category</span>
                            <i class='bx bxs-chevron-right'></i>
                        </a>
                        <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subManageMenu ? 'show' : null">
                            <li class="menu-list-item">
                                <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.ATTRIBUTE]"class="menu-link">
                                    <i class='bx bx-check-circle'></i>
                                    <span class="menu-title">Attributes</span>
                                </a>
                            </li>
                            <li class="menu-list-item">
                                <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.MENU_CATEGORY]"class="menu-link">
                                    <i class='bx bx-check-circle'></i>
                                    <span class="menu-title">Category</span>
                                </a>
                            </li>
                        <li class="menu-list-item">
                            <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.VENDOR_CUISINE]"class="menu-link">
                                <i class='bx bx-check-circle'></i>
                                <span class="menu-title">Cuisine</span>
                            </a>
                        </li>
                       </ul>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.PRODUCT_MANAGEMENT]" (click)="openCloseSubMenu('subProductMenu')" [ngClass]="subProductMenu ? 'submenu-open' : null" class="menu-link">
                         <i class='bx bx-cube '></i> 
                            <span class="menu-title">Product Management</span>
                        </a>
                    </li>

                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.MEAL_DEALS]" (click)="openCloseSubMenu('subProductMenu')" [ngClass]="subProductMenu ? 'submenu-open' : null" class="menu-link">
                            <i class='bx bx-message-square-check'></i>
                            <span class="menu-title">Meal Deals</span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.COUPAN]" class="menu-link">
                            <i class='bx bx-badge-check'></i>
                            <span class="menu-title">Loyalty</span>
                        </a>
                    </li>
                    <li class="menu-list-item has-child-submenu level1">
                        <a (click)="closeSidebar()"[routerLink]="[URLConstants.ORDER]" href="#" class="menu-link">
                            <i class='bx bx-store-alt'></i>
                            <span class="menu-title">Orders</span>
                        </a>
    
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.REVIEW]"class="menu-link">
                            <i class='bx bx-target-lock'></i>
                            <span class="menu-title">Review </span>
                        </a>
                    </li>
                   
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.NOTIFY]"class="menu-link">
                            <i class='bx bx-notification'></i> 
                            <span class="menu-title">Notifications </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.EARNING]" class="menu-link">
                            <i class='bx  bx-line-chart  '></i>
                            <span class="menu-title">Earnings </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.VIEW_TICKET]"class="menu-link">
                            <i class='bx bx-wrench'></i>
                            <span class="menu-title">Support </span>
                        </a>
                    </li>
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" [routerLink]="[URLConstants.FAQS_VENDOR]" class="menu-link">
                            <i class='bx bxs-right-arrow-alt'></i>
                            <span class="menu-title">FAQ's</span>
                        </a>
                    </li>
                   
                    <li class="menu-list-item">
                        <a (click)="closeSidebar()" href="role-permission.html" [routerLink]="[URLConstants.VENDOR_PROFILE]"class="menu-link">
                            <i class='bx bx-user'></i>
                            <span class="menu-title">My Profile </span>
                        </a>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
    
</aside>
