import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// environment
import { environment } from 'src/environments/environment';

// interface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { defaultPermissionConstants } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  uploadImageUrl = environment.uploadImgUrl;
  public CmsId: any;
  objectImage:any = {};
  imageToShow: any;
  VendorId: any;
  constructor(public commonService: CommonService,
    private sanitizer: DomSanitizer,public getPermissionsService: GetPermissionsService,
    private activatedRoute: ActivatedRoute,private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe((params) => { this.CmsId = params.id; });
    console.log(this.CmsId)
    this.setBreadcrumbs();
    this.getPageDetails();
    this.VendorId =this.localStorageService.getToken('VendorId');
  }

  ngOnInit() {
     //this.createImageFromBlob(this.objectImage)
   }
  
  
  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'CMS Management', url: this.URLConstants.CMS },
      { localeKey: 'Static Pages', url: this.URLConstants.CMS },
      { localeKey: 'View Details', url: null },
    ];
    this.subHeaderData = {
      title: 'View Details',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Get Details
  @Parameter   : NA
  @Return      : Detail
  /*************************************************************/
  
  public Detail: any = {};
  getPageDetails() {
    this.commonService.callApi(this.callAPIConstants.Product_Detail + this.CmsId + '/' + this.VendorId, '', 'get', false, false).then((success) => {
      if (success.status === 1) { 
        this.Detail = success.data;
        console.log(this.Detail);
        this.objectImage = this.uploadImageUrl+success.data.image;
        console.log(this.Detail);
        
         //this.objectImage = this.uploadImageUrl+ success.data.image;
      }
    });
  }
  /*************************************************************/


}
