import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CuisineRoutingModule } from './cuisine-routing.module';
import { CuisineComponent } from './cuisine.component';
import { ListComponent } from './list/list.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
@NgModule({
  declarations: [CuisineComponent, ListComponent, EditDetailsComponent, ViewDetailsComponent, VendorListComponent],
  imports: [
    CommonModule,
    CuisineRoutingModule,
    SharedModule,FormsModule,ReactiveFormsModule
  ],schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CuisineModule { }
