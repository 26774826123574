<div class="main-content-area">
  <div class="main-content-block">
      <h3 class="content-header-title">{{this.Display}}</h3>
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
           
              <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item">List</li>

              <li class="breadcrumb-item active" aria-current="page">
                  {{tabs.EarningList? 'EarningList' : ''}}
              </li>
          </ol>
      </nav>
      
      <div class="m-content animated fadeIn">
          <!-- BEGIN: Responsive Data Table  -->
          <!-- <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
          </app-responsive-data-table> -->

          <!-- END: Responsive Data Table  -->
          <div class="animated fadeInUp box-wrapper notification-card card tabbing p-0">
              <div class="m-portlet  settings-wrapper approve-template custom-tabs">
                  <!-- BEGIN: Add new category and Faqs buttons -->
                 
                  <!-- END: Add new category and Faqs buttons -->

                  <tabset class="tab-links tab-content" id="content">
                      <tab id="tab1" (selectTab)="onSelect('EarningList')">
                          <ng-template tabHeading>
                              <i class="bx align-middle mr-1"></i><span class="align-middle">Earning List</span>
                          </ng-template>

                          <app-responsive-data-table [tableSetupData]="tableSetupData" [accessPermission]="accessPermission" *ngIf="tabs.EarningList">
                          </app-responsive-data-table>
                      </tab>

                      <tab [active]="tabs.faqCategory" (selectTab)="onSelect('WithDrawList')"
                          customClass="date-icon common-icon">
                          <ng-template tabHeading (selectTab)="onSelect('WithDrawList')">
                              <i class="bx align-middle mr-1"></i><span class="align-middle">WithDrawList List</span>
                          </ng-template>

                          <app-responsive-data-table [tableSetupData]="tableSetupData" [accessPermission]="accessPermission" *ngIf="tabs.WithDrawList">
                          </app-responsive-data-table>
                      </tab>
                  </tabset>
              </div>
          </div>
      </div>
  </div>
</div>