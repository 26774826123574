<style>
    .color-squre {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }
    
    .color-squre-mini {
        height: 10px;
        width: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }
    
    .color-primary {
        position: relative;
    }
    
    .color-primary .color-squre {
        background: #5A4FC4;
    }
    
    .color-secound .color-squre {
        background: #F19A64;
    }
    
    .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px #ccc;
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
    }
    
    .example-box {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;
    }
    
    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    
    .cdk-drag-placeholder {
        opacity: 0;
    }
    
    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
    
    .example-box:last-child {
        border: none;
    }
    
    .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
    
    .download-dropdown .download-dropdown-menu {
        width: 100%;
        color: var(--dt-text-color);
        top: 3rem !important;
        position: absolute;
        right: 0;
        z-index: 1000;
        float: left;
        min-width: 660px;
        margin: .125rem 0 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: .25rem;
        animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        border-radius: 5px;
        box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
        background: var(--input-bg);
        display: none;
    }
    
    .left-col {
        flex: 0 0 20%;
        min-width: 175px;
        height: 350px;
        overflow-y: auto;
    }
    
    .left-col .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px 15px 20px;
        background: var(--input-bg);
    }
    
    .left-col .list-group-item .handle {
        margin-right: 10px;
    }
    
    .left-col .list-group-item .handle .custom-checkbox label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
        font-weight: 600!important;
    }
    
    .right-col .col-left {
        flex: 0 0 65%;
        margin-right: 1rem;
    }
    
    .right-col .col-right {
        flex: 0 0 auto;
    }
    
    .right-col .col-right .right-col-list {
        max-height: 200px;
        overflow: auto;
    }
    
    .template-list-outer {
        height: 220px;
        overflow-y: auto;
    }
    
    .template-list-outer .template-list .list-group-item {
        padding-top: 9px;
        padding-bottom: 9px;
        border: 0;
    }
    
    .download-dropdown.open .download-dropdown-menu {
        display: block;
    }
    
    .bottom-buttons button {
        min-width: 90px;
        color: var(--text-color);
        border-color: var(--border-color);
        background: var(--bg-white);
    }
    
    .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px;
        background: var(--input-bg)
    }
    
    .list-group-item label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
    }
    
    .filter-form {
        position: relative;
    }
    
    .field-form {
        width: calc(100% - 100px);
    }
    
    .filter-form .row-add {
        position: absolute;
        right: 10px;
        bottom: 7px;
    
       
    }
    .filter-form .row-add .bx{
        font-size: 28px;
        }
    .save-filter {
        display: flex;
        align-items: center;
    }
    
    .caption-image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-block;
    }
    
    .caption-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 21px;
    }
    
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before {
        top: 9px;
    }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper" [hidden]="showSkeletonLoader1">
    <div class="card profile-card animated fadeIn pt-0">
        <!-- TABLE HEADER START-->
        <div *ngIf="this.conditions.showTableHeader" class="card-collapse">
            <div class="row data-filter justify-content-end">
                 
                <div class="col-md-6 col-lg-6 text-md-right">
                    <div class="button-continer text-right">
                        <div class="btn-group" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                            <div>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <div class="dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="width:650px">
                                        <div class="card">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'col-md-12':''">
                                                            <ng-container *ngIf="i.columnVisibility">
                                                                <div class="custom-checkbox">
                                                                    <label>
                                                                      <input type="checkbox" name="status"
                                                                          (change)="selectColumns(tableConfig?.cols)"
                                                                          [(ngModel)]="i.isSelected">
                                                                      <span></span>
                                                                      {{i.colName}}
                                                                  </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="Save Template" [(ngModel)]="options.templateName" #templateName="ngModel" [disabled]="!count">
                                                            <!-- <div class="error-block text-left" *ngIf="((submitted  || templateName.touched) && templateName.invalid)">
                                                              {{showErrorService.showError('templateName', templateName.errors)}}
                                                          </div> -->
                                                        </div>

                                                        <button class="btn btn-primary" [disabled]="!count" (click)="saveDownloadTemplate(tableConfig?.cols)">
                                                          Save Template
                                                      </button>
                                                        <span [style.background]="color" [cpPosition]="'bottom-left'" class="color-squre mb-2" [(colorPicker)]="color" [cpOKButton]="true" [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                        <div class="row">
                                                            <div *ngFor="let i of downloadTemplatesArr" class="save-filter col-md-12 mt-2">
                                                                <span [style.background]="i.color" class="color-squre-mini" [(colorPicker)]="i.color" [disabled]="true" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                                <span class="ml-2" (click)="showTemplate(i)">{{i.description}}
                                                              </span>
                                                                <span class="fa fa-close ml-2" aria-hidden="true" tooltip="Delete" placement="bottom" (click)="CallAPIdeleteDownloadTemplate(i)"></span>
                                                                <span class="fa fa-eye ml-2" aria-hidden="true" tooltip="View" placement="bottom" (click)="showTemplate(i)"></span>
                                                                <span class="fa fa-check ml-2" aria-hidden="true" tooltip="Edit" placement="bottom" (click)="editTemplate(i)"></span>
                                                            </div>
                                                            <div *ngIf="!downloadTemplatesArr?.length">
                                                                No Templates Found
                                                            </div>
                                                        </div>
                                                        <div class=" row mt-2">
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2 col-md-2" (click)="downloadTemplate('pdf')" [disabled]="!count">Pdf
                                                          </button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('excel')" [disabled]="!count">Excel</button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('csv')" [disabled]="!count">CSV</button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('print')" [disabled]="!count">Print</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <!-- download CSV and EXCEL -->
                        <ng-container *ngIf="this.conditions.showExport">
                            <div class="dropdown">
                                <button class="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <!-- <span>Import</span> -->
                              </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="importCSVandExcel('csv')">
                                      CSV File</a>
                                    <a class="dropdown-item" (click)="importCSVandExcel('excel')">
                                      Excel File
                                  </a>
                                </div>
                            </div>
                        </ng-container>

                        <!-- download CSV and EXCEL -->
                    </div>
                </div>
            </div>
        </div>
        <!-- ON COLLAPSE COLUMN HIDE SHOW -->
        <div class="card-body">
            <!-- Data Table Start -->
            <!-- {{tableConfig.cols withDrawList | json}} -->
            <ng-container *ngIf="this.type ==='earninglist'||this.type ==='withDrawList'">
                <form #EarningForm="ngForm" (ngSubmit)="submits(EarningForm,EarningLists)" class="filter-form">
                   <div class="row row-15" style="max-width:600px;">
                <div class="col-md-3">
                    <ng-select class="edit-filter" [multiple]="false" 
                    [clearable]="true" 
                      [items]="EarningLists.filterStatus" 
                      (change)="changeEarning($event)"
                        placeholder="filter category "
                        [(ngModel)]="EarningLists"
                        >
                    </ng-select>
                </div>
                <div class="col-md-3">
                    <input
                       class="form-control"
                       type="text"
                       name="fromDate"
                        id="fromDate"
                        [(ngModel)]="EarningLists.fromDate"
                        #fromDate="ngModel"                           
                        autocomplete="off"
                       required
                       bsDatepicker [bsValue]="EarningLists.fromDate"
                     />
                    </div>
                    <div class="col-md-3">
                     <input
                       class="form-control"
                       type="text"
                       name="toDate"
                        id="toDate"
                        [(ngModel)]="EarningLists.toDate"
                        #toDate="ngModel"                           
                        autocomplete="off"
                       required
                       bsDatepicker [bsValue]="EarningLists.toDate"
                     />
                   
                    </div>
                    <div class="col-md-3">
                        <button type="submit" class="btn btn-primary ">Searching</button>
                       </div>
                </div>
                    </form>
               
            </ng-container>
            <!-- filter/download/search buttons -->
            <div class="user-listing-filterOptions mb-2 d-block">
                <div class="row mb-2">
                    <div class="col-sm-6 position-static">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="left-buttons d-flex search-input-section">
                                    <ng-container *ngIf="this.type ==='faqsListing'||this.type ==='categoryProductList' ||this.type ==='categoryList'" >
                                        <input type="text" class="form-control icon-search float-left"
                                         placeholder="search" name="search"
                                          #search="ngModel" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="searchData($event)">
                                    </ng-container>
                                    <label *ngIf="this.conditions.search">
                                        <div class="search-input-wrapper position-relative">
                                        <form>
                                        <div class="form-group position-relative">
                                        <em class="bx bx-search"></em>
                                        <input type="text" class="form-control icon-search" placeholder="search" name="search" #search="ngModel" [(ngModel)]="searchText" autocomplete="off" (keyup)="searchData($event)" >
                                        <div class="remove-icon"><em class="bx bx-x" *ngIf="searchText" (click)="removeSearch()"></em></div>
                                        </div>
                                        </form>
                                        </div>
                                        </label>
                                       
                                        <div *ngIf="this.type ==='userlist'">
                                            <ng-select class="edit-filter" [multiple]="false" 
                                            [clearable]="true" 
                                              [items]="roleList" (change)="changeFn($event)"
                                                placeholder="filter category ">
                                            </ng-select>
                                        </div>
                                      
                                    <div *ngIf="this.conditions.showFilter " class="custom-dropdown filter-data-dropdown position-static mx-0 px-0" [ngClass]="status ? 'open' : ''">
                                        <button (click)="openFilter()" class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white mx-0" type="button">
                                            <i class="bx bx-filter d-lg-none"></i>
                                            <span class="d-none d-sm-none d-lg-inline-block">Filter Data</span>
                                        </button>
                                        <div class="custom-dropdown-menu filter-dropdwon w-100 dropdown-icon" (click)="$event.stopPropagation()" style="margin-top: 22px;z-index: 1031;">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <!-- New code  -->
                                                            <div class="col-md-12 mb-3">
                                                                <!-- Get Filters -->
                                                                <div *ngFor="let i of savedFilters" class="save-filter col-md-12 mt-2">
                                                                    <span [style.background]="i.color" class="color-squre-mini" [(colorPicker)]="i.color" [disabled]="true" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                                    <span class="ml-2" (click)="applyLatestFilter('',i)">{{i.description}}
                                                                    </span>
                                                                    <span class="bx bx-trash-alt mr-1 text-danger ml-2" aria-hidden="true" tooltip="Delete" placement="bottom" (click)="CallAPIdeleteSaveFilter(i)"></span>
                                                                    <span class="bx bx-edit text-primary cursor-pointer ml-2" aria-hidden="true" tooltip="Edit" placement="bottom" (click)="editFilter(i)"></span>
                                                                </div>
                                                                <div *ngIf="!savedFilters?.length">
                                                                    No save filters Found
                                                                </div>
                                                                <!-- Get Filters -->
                
                                                            </div>
                                                            <div class="col-md-12">
                                                                
                                                                <div class="filter-details-form" *ngFor="let i of filteredArr; let index=index">
                                                                    <form #filterForm="ngForm" class="filter-form">
                                                                        <div class="field-form">
                                                                            <div class="and-or-data mt-3 mb-3">
                                                                                <button [ngClass]="condition =='$and'?'btn btn-primary':'btn btn-outline-primary'" (click)="conditionChange('$and')">And</button>
                                                                                <button [ngClass]="condition =='$or'?'btn btn-primary':'btn btn-outline-primary'" (click)="conditionChange('$or')">Or</button>
                                                                            </div>
                                                                            <div class="target-form">
                                                                                <div class="target-details">
                                                                                    <div class="row select-field-wrap">
                                                                                        <div class="select-field-block">
                                                                                            <ng-select [items]="filterCols"
                                                                                             [(ngModel)]="i.key" bindLabel="colFieldname" 
                                                                                             bindValue="colFieldname" 
                                                                                             [ngClass]="((submitted || colFieldname.touched) && colFieldname.invalid)?'has-danger':''" 
                                                                                             name="colFieldname{{index}}" placeholder="Select Field"
                                                                                              #colFieldname="ngModel"
                                                                                                [multiple]="false" required [hideSelected]="true">
                                                                                            </ng-select>
                                                                                            <div class="error-block text-left" *ngIf="((submitted || colFieldname.touched) && colFieldname.invalid)">
                                                                                                Select Field
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="select-field-block">
                                                                                            <ng-select class="ng-select-line" [items]="typeArr" bindValue="value" bindLabel="name" placeholder="Contains" [clearable]='true' [multiple]="false" [dropdownPosition]="'auto'" [closeOnSelect]="true" name="contains{{index}}" #contains="ngModel" required
                                                                                                [(ngModel)]="i.type" (change)="typeArrChanges(i)">
                                                                                            </ng-select>
                                                                                            <div class="error-block text-left" *ngIf="((submitted || contains.touched) && contains.invalid)">
                                                                                                Select Type
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- Contains -->
                                                                                        <div class="select-field-block" *ngIf="i.type !== 'date'"> 
                                                                                            
                                                                                                <input type="text" class="form-control" placeholder="" name="value{{index}}" [(ngModel)]="i.value" autocomplete="off" (keydown)=" (i.type == 'greaterThan' || i.type=='lessThan' ? restrictKeyPressService.AllowNumbers($event) : '')" #value="ngModel" required>
                                                                                                <div class="error-block text-left" *ngIf="((submitted || value.touched) && value.invalid)">
                                                                                                    Enter Value
                                                                                                </div>
                                                                                          
                                                                                        </div>
                                                                                        <div class="select-field-block" *ngIf="i.type === 'date'">
                                                                                         
                                                                                                <input placeholder="Date range picker" type="text" class="form-control" bsDaterangepicker [(ngModel)]="i.dateRange" name="daterange{{index}}" #daterange="ngModel" (bsValueChange)="rangeSelected($event, index)" required>
                                                                                                <div class="error-block text-left" *ngIf="((submitted || daterange?.touched) && daterange?.invalid)">
                                                                                                    Select Date Range
                                                                                                </div>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
        
                                                                        </div>
                                                                        <div class="row-add">
                                                                            <em class="bx bxs-plus-circle  text-success" (click)="addTask()"></em>
                                                                            <em class="bx bxs-minus-circle mr-1 text-danger" *ngIf="filteredArr.length > 1" (click)="removeTask(index)"></em>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 mb-2 mt-2">
                                                                <div class="d-flex align-items-start flex-wrap">
                                                                    <button type="button" class="btn btn-primary" (click)="applyLatestFilter(filterForm)">Search</button>
                                                                    <button class="btn btn-outline-primary" (click)="clearFilter()">Clear</button>
                                                                    <div class="form-group d-flex flex-column mx-sm-2 mb-0">
                                                                  
                                                                            <input type="text" class="filtersave form-control" placeholder="Enter name to save filter" name="description" #description="ngModel" [(ngModel)]="options.description1" autocomplete="off" required>
                                                                            <div class="error-block text-left" *ngIf="((submitted1 || description.touched || description.dirty) && description.invalid)">
                                                                                Enter name
                                                                            </div>
                                                                      
                                                                    </div>
        
                                                                    <button class="btn btn-primary" (click)="toSaveFilter(filterForm, options.description1, filterColor)">Save
                                                                    & search</button>
                                                                    <!-- <span [style.background]="filterColor" [cpPosition]="'bottom-left'" class="color-squre mb-2" [(colorPicker)]="filterColor" [cpOKButton]="true" [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-md'"></span> -->
                                                                    <!-- <div class="dropdown colorbox m-0">
                                                                        <button (click)="openColorDropdownFilter()" class="btn dropdown-toggle dropdown-btn" type="button" id="dropdownMenuButton2" data-toggle="dropdown1" aria-haspopup="true" aria-expanded="false">
                                                                            <span class="color-box"></span>
                                                                        </button>
                                                                        <ul class="dropdown-menu dropdown-menu-right dropdown-icon" [ngClass]="statusFilterColorPicker ? 'show' : ''" aria-labelledby="dropdownMenuButton2">
                                                                            <li (click)="selectColor('yellow')"><a class="dropdown-item"><span class="color-shade bg-yellow"></span><span
                                                                                        class="color-text">Yellow</span></a></li>
                                                                            <li (click)="selectColor('green')"><a class="dropdown-item"><span class="color-shade bg-green"></span><span
                                                                                        class="color-text">Green</span></a></li>
                                                                            <li (click)="selectColor('purple')"><a class="dropdown-item"><span class="color-shade bg-purple"></span><span
                                                                                        class="color-text">Purple</span></a></li>
                                                                            <li (click)="selectColor('rose')"><a class="dropdown-item"><span class="color-shade bg-rose"></span><span
                                                                                        class="color-text">Rose</span></a></li>
                                                                            <li (click)="selectColor('grey')"><a class="dropdown-item"><span class="color-shade bg-grey"></span><span
                                                                                            class="color-text">Grey</span></a></li>
                                                                        </ul>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="this.conditions.showApplyStatus && this.selectedUserList.length" class="d-flex flex-wrap">
                                        <button type="button" class="btn btn-default delete-btn minW-0 btn-bg-white" (click)="submitStatusForm()">
                                        <i class='bx bx-trash-alt'></i>
                                        </button>
                                        <button type="button" class="btn btn-default switch-btn minW-0 btn-bg-white" (click)="submitStatusForm()">
                                        <!-- <img src="/assets//images/icon/slide-icon.svg" width="18" /> -->
                                        <i class='bx bx-toggle-left'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                            
                                    <ng-container *ngIf="this.type ==='faqsListing'"  >
                                        <ng-select class="edit-filter" [multiple]="false" 
                                        [clearable]="true" 
                                          [items]="roleLists" (change)="changefaq($event)"
                                            placeholder="category ">
                                        </ng-select>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="this.conditions.search">
                                        <form>
                                            <div class="form-group position-relative">
                                                <div class="search-icon"><em class="bx bx-search"></em></div>
                                                
                                                <input type="text" class="form-control icon-search" placeholder="search" name="search" #search="ngModel" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="searchData($event)">
                                                <div class="remove-icon"><em class="bx bx-x"></em></div>
                                            </div>
                                        </form>
                                    </ng-container> -->
                                 
                            </div>
                        </div>
                       
                    </div>
                    <div class="col-sm-6">
                        <div class="right-buttons d-flex justify-content-end">
                            <div *ngIf="this.conditions.showExport" class="download-btn download-dropdown" [ngClass]="statusDownload ? 'open' : ''">
                                <button (click)="openDownloadButton()" class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white mr-2" type="button" id="downloadDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="bx bx-download d-lg-none"></i>
                                    <span class="d-none d-sm-none d-lg-inline-block">Download</span>
                                </button>
                                <div class="download-dropdown-menu pt-3 pr-3 pb-3 pl-2 dropdown-menu-right dropdown-icon" aria-labelledby="downloadDropdown">
                                    <div class="container-fluid">
                                        <div class="row flex-nowrap">
                                            <div class="left-col p-0">
                                                <!-- <ul class="list-group handle-list border-left-0 mr-2" *ngFor="let temp of tempSettingsData">
                                                    <li class="list-group-item toggle-vis d-flex" data-column="3" *ngFor="let col of temp.columns">
                                                        <span class="handle">
                                                            <i class='bx bx-grid-vertical'></i>
                                                        </span>
                                                        <div class="custom-checkbox">
                                                            <label for="name">
                                                                <input type="checkbox" name="name" id="name" autocomplete="off" checked="">
                                                                <span></span>{{col.key}}
                                                            </label>
                                                        </div>
                                                    </li>

                                                </ul> -->

                                                <ul class="list-group handle-list border-left-0 mr-2" *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'':''">
                                                    <li *ngIf="i.columnVisibility" class="list-group-item">
                                                        <div class="custom-checkbox">
                                                            <label>
                                                          <input type="checkbox" name="status"
                                                              (change)="selectColumns(tableConfig?.cols)"
                                                              [(ngModel)]="i.isSelected">
                                                          <span></span>
                                                          {{i.colName}}
                                                      </label>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3">
                                                <div class="row m-0">
                                                    <div class="col-left">
                                                        <div class="input-group mb-3">
                                                            <!-- <input type="text" class="form-control" placeholder="" /> -->
                                                            <input type="text" class="form-control" placeholder="Save Template" [(ngModel)]="options.templateName" #templateName="ngModel">
                                                            <div class="input-group-append">
                                                                <button (click)="saveDownloadTemplate(tableConfig?.cols)" class="btn btn-primary" type="button">Save Template</button>
                                                            </div>
                                                            <!-- <span class="fa fa-close ml-2" aria-hidden="true" tooltip="Delete" placement="bottom" (click)="CallAPIdeleteDownloadTemplate(i)"></span> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-right">
                                                        <div class="dropdown colorbox m-0">
                                                            <button (click)="openCloseColorDropdown()" class="btn dropdown-toggle dropdown-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span id="downloadDropdownColor" class="color-box"></span>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-right dropdown-icon" [ngClass]="statusColorPicker ? 'show' : ''" aria-labelledby="dropdownMenuButton2">
                                                                <!-- <li *ngFor="let color of colors"><a class="dropdown-item" href="#"><span class="color-shade bg-yellow"></span><span
                                                                    class="color-text">{{color.name}}</span></a></li> -->
                                                                <li (click)="selectColor('#FDAC41','yellow')"><a class="dropdown-item"><span class="color-shade bg-yellow"></span><span
                                                                            class="color-text">Yellow</span></a></li>
                                                                <li (click)="selectColor('#39DA8A','green')"><a class="dropdown-item"><span class="color-shade bg-green"></span><span
                                                                            class="color-text">Green</span></a></li>
                                                                <li (click)="selectColor('#b87ee5','purple')"><a class="dropdown-item"><span class="color-shade bg-purple"></span><span
                                                                            class="color-text">Purple</span></a></li>
                                                                <li (click)="selectColor('#FF5B5C','rose')"><a class="dropdown-item"><span class="color-shade bg-rose"></span><span
                                                                            class="color-text">Rose</span></a></li>
                                                                <li (click)="selectColor('#b3c0ce','gray')"><a class="dropdown-item"><span class="color-shade bg-gray"></span><span
                                                                                class="color-text">Gray</span></a></li>
                                                                <li (click)="selectColor('#00cfdd','blue')"><a class="dropdown-item"><span class="color-shade bg-skyblue"></span><span
                                                                                    class="color-text">Sky Blue</span></a></li>
                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="row m-0 flex-column">
                                                    <div class="scrollable template-list-outer right-col-list">
                                                        <ul class="list-group template-list">
                                                            <li class="list-group-item" *ngFor="let temp of tempSettingsData">
                                                                {{temp.description}}
                                                                <span class="d-inline-flex align-items-center">
                                                                    <a (click)="CallAPIdeleteDownloadTemplate(temp)"><i class='bx bx-trash-alt'></i></a>
                                                                <span class="bx bx-edit text-primary cursor-pointer ml-2" aria-hidden="true" tooltip="Edit" placement="bottom" (click)="editTemplate(temp)"></span>
                                                                </span>
                                                                <div *ngIf="!tempSettingsData?.length">
                                                                    No Templates Found
                                                                </div>
                                                                <!-- <div class="d-flex align-items-center justify-content-between">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="badge-outer border-0 circle round d-flex align-items-center mr-2">
                                                                            <span class="badge badge-success circle"></span>
                                                                        </div>
                                                                        <span>Every column except "Profile Pic"</span>
                                                                    </div>
                                                                    
                                                                </div> -->
                                                            </li>
                                                            <!-- <li class="list-group-item">
                                                                <div class="d-flex align-items-center justify-content-between">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="badge-outer border-0 circle round d-flex align-items-center mr-2">
                                                                            <span class="badge badge-danger circle"></span>
                                                                        </div>
                                                                        <span>Only Name &amp; Email</span>
                                                                    </div>
                                                                    <span class="d-inline-flex align-items-center">
                                                                        <button class="btn btn-small btn-primary btn-sm p-0 mr-1">save</button>
                                                                        <a href=""><i class='bx bx-trash-alt'></i></a>
                                                                    </span>
                                                                </div>
                                                            </li> -->
                                                        </ul>
                                                    </div>
                                                    <div class="bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3">
                                                        <button (click)="downloadTemplate('excel')" type="button" class="btn border mr-2" id="ExportReporttoExcel">
                                                            <span class="mr-2">
                                                                <img class="img-fluid" src="assets/images/icon/excel-icon.svg" alt="Excel" />
                                                            </span>
                                                            Excel
                                                          </button>
                                                        <button (click)="downloadTemplate('csv')" type="button" class="btn border" id="ExportReporttoCSV">
                                                            <span class="mr-2">
                                                                <img class="img-fluid" src="assets/images/icon/csv-icon.svg" alt="Excel" />
                                                            </span>
                                                            CSV
                                                          </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="this.conditions.showImport" class="dropdown importFileDropdown custom-dropdown" dropdown>
                                <button class="btn glow-secondary btn-secondary dropdown-toggle pr-4 minW-md-0" type="button" aria-controls="dropdown-animated">
                                    <i class="bx bxs-file-import d-lg-none"></i>
                                    <span class="d-none d-sm-none d-lg-inline-block">Import File</span>
                                </button>
                                <ul class="dropdown-menu p-0 dropdown-menu-right dropdown-icon " *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdown-animated">
                                    <li><a class="dropdown-item" href="#"><i
                                                class='bx bx-trash-alt  mr-2 icon-btn'></i>CSV
                                                File</a></li>
                                    <li><a class="dropdown-item" href="#"><i
                                                class='bx bx-notepad  mr-2 icon-btn'></i>Excel
                                                File</a></li>
                                </ul>
                            </div>
                            <ng-container *ngIf="this.conditions.showButton && !conditions.modal">
                                <button type="button" class="nav-link  btn btn-primary listlink" [routerLink]="[this.conditions.showButton.routerLink]">
                                  <em class="fa fa-plus" aria-hidden="true"></em>
                                  <span>{{this.conditions.showButton.buttonName}}</span>
                              </button>
                            </ng-container>
                            <ng-container *ngIf="this.conditions.showButton  && conditions.modal">
                                <button type="button" class="btn btn-primary listlink" (click)="openModal(type)">
                                  <em class="fa fa-plus" aria-hidden="true"></em>
                                  <span>{{this.conditions.showButton.buttonName}}</span>
                              </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)" class="custom-table table table-striped" id="table" *ngIf="tableData?.data" aria-describedby="table" width="100%">
                <thead>
                    <tr>
                        <!-- responsive button -->
                        <!-- <th></th> -->
                        <!-- responsive button -->
                        <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort" *ngFor="let i of tableConfig?.cols" id="th1" [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                            <ng-container *ngIf="i.type!=='multipleSelection' && i.type!=='switch' && i.type !='columnSettings'">
                                {{i.colName}}
                            </ng-container>
                            <ng-container *ngIf="i.type =='columnSettings' ">

                                <div class=" btn-group" dropdown [insideClick]="true">
                                    <em dropdownToggle class=" fa fa-cog">
                                  </em>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                        <div class="card">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                                        <div *ngFor="let setting of colSettingCols" class="example-box" cdkDrag [ngClass]="(setting.columnVisibility)?'col-md-12':''">
                                                            <ng-container *ngIf="setting.drag">
                                                                <div class="custom-checkbox">
                                                                    <label>
                                                                      <input type="checkbox" name="status"
                                                                          [(ngModel)]="setting.isVisible"
                                                                          [disabled]="setting.disable">
                                                                      <span></span>
                                                                      {{setting.colName}}
                                                                  </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="text-right filter-btn">
                                                            <div class="col-md-12">
                                                                <button type="submit" class="nav-link btn btn-primary ml-2" (click)="saveColumnSettings(colSettingCols)">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-container *ngif="this.type === 'productlist'" >
                                <div>
                                    <input type="text"  class="form-control"  (keyup.enter)="OnInput($event)" >
                                </div>
                            </ng-container>
                            <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
                            <div *ngIf="i.type=='multipleSelection'" class="custom-checkbox">

                                <label>
                                  <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                      (change)="selectAllUser(i)">
                                  <span></span>
                              </label>
                            </div>

                        </th>
                    </tr>
                </thead>

                <tbody>
                    <ng-container>
                       
                        <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">  
                            <!-- <td></td> -->
                            <td [ngClass]="j.type=='action'?' ':''" [hidden]="!j.isVisible" *ngFor="let j of tableConfig?.cols">
                                <div *ngIf="j.type=='multipleSelection'" class="custom-checkbox">
                                    <ng-container *ngIf="type === 'promoCodeList';else basicMultipleSelection">
                                        <label *ngIf="i['usedInOrders'].length == 0">
                                    <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                    (change)="selectMultipleUser(i)"><span></span>
                                    </label>
                                    </ng-container>
                                    <ng-template #basicMultipleSelection>
                                        <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                    (change)="selectMultipleUser(i)"><span></span></label>
                                    </ng-template>
                                </div>
                                <!-- <div *ngIf="j.type=='switch'" class="custom-control custom-switch light" (click)="changeStatus(i)">
                                    <input type="checkbox" id="id" class="custom-control-input" [ngModel]="i[j.colFieldname]" (click)="$event.stopPropagation()">
                                    <label for="id" class="custom-control-label"></label>
                                </div> -->
                                <div *ngIf="j.type=='switch'">
                                    <label class="switch ">
                    <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]" (click)="changeStatus(i)"
                    >
                    <span class="slider round"></span>
                  </label>
                                </div>
                                <div *ngIf="j.type=='image'" class="thumb-img">

                                    <span class="caption-image">
                                      <img
                                        [src]="i[j.colFieldname] ?  uploadImageUrl + i[j.colFieldname] : 'assets/images/noImageAvailable.png'"
                                        alt="no img">
                                    </span>

                                </div>
                                <ng-container *ngIf="j.type=='action'">
                                    <div class="d-flex w-100">
                                    <div *ngFor="let k of tableConfig?.actions" class="action-icon ">
                                        <ng-container *ngIf="k.type === 'icon'">
                                            <span class="cursor">
                                              <em [class]="k.class" aria-hidden="true" [tooltip]="k.tooltip"
                                                  (click)="performAction(k,i)">
                                              </em>
                                          </span>
                                        </ng-container>
                                        <div *ngIf="k.type === 'button'">
                                            <ng-container>
                                                <button type="button" [class]="k.class" (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                </ng-container>
                                <div *ngIf="j.type =='symbol'" class="align-center">
                                    <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                    <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                </div>
                                <div *ngIf="j.type=='text'">
                                    {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] | date:'dd MMM yyy') : i[j.colFieldname] ? i[j.colFieldname] : '--')}}
                                </div>
                                <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <!-- Data Table End -->
            
        </div>
        <!-- TABLE FOOTER START-->
        <!-- <p>{{tableData | json}}</p> -->
        <div class="card-footer" *ngIf="this.conditions.showTableFooter">
            <div class="custom-pagination d-flex align-items-center justify-content-between" *ngIf="tableData.data.length">
                <div class="d-flex align-items-center">
                    <span class="record-count mr-1">Showing</span>
                    <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList" placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'" [labelForId]="tableData.type">
                    </ng-select>
                    <span class="record-count ml-2">out of {{total}} entries</span>
                </div>
                <div class="pagination-list">
                    <pagination [itemsPerPage]='itemsPerPage' [(ngModel)]="currentPage" #customPagination [totalItems]="total" (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next" firstText="&laquo;" lastText="&raquo;">
                    </pagination>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
        <!-- TABLE FOOTER END-->
    </div>
</div>