<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
      <div class="card animated fadeIn">
          <div class="card-header text-left">
              <div class="card-title">
                  <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
              </div>
          </div>
          <div class="card-body">
              <div class="container-fluid">
                  <div class="form-group row">
                      <div class="col-xl-2 col-sm-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card animated fadeIn">
          <div class="card-header text-left">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
          </div>
          <div class="card-body">
              <div class="container-fluid">
                  <div class="form-group row">
                      <div class="col-lg-2 col-sm-3">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-3">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <hr />
                  <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-2">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-md-2">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</div>

<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">Meal deal</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              
                <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                  {{MealValue}}
                  </li>
              </ol>
          </nav>
       
  
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
              <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                    <div class="text">
                        <h5 class="mb-0 text Uppercase">Meal deal </h5>
                    </div>
              </div>
  <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
        <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
      <div class="row">
            <div class="col-md-6 pb-3">
                  <div class="card notification-card ">
                    <div class="row ">
                          <div class="col-md-6 form-group">
                            <label for="">name</label>
                            <input type="text" class="form-control"
                            formControlName="name"
                             name="name"
                              id="name"
                              >
                          </div>                
                         <div class="col-md-6 form-group">
                           <label for="">servingPeople</label>
                           <input type="text" class="form-control"
                            name="servingPeople" 
                            id="servingPeople"
                            formControlName="servingPeople">
                         </div>
                          
                       </div>
                       </div>
                </div>
            <div class="col-md-6 pb-3">
              <div class="card notification-card ">
                <div class="row ">
                      <div class="col-md-6 form-group">
                        <label for="">price</label>
                        <input type="text" class="form-control"
                        formControlName="price"
                         name="price"
                          id="price"
                          >
                      </div>                
                     <div class="col-md-6 form-group">
                       <label for="">discountPercentage</label>
                       <input type="text" class="form-control"
                        name="discountPercentage" 
                        id="discountPercentage"
                        formControlName="discountPercentage">
                     </div>                      
                   </div>
                   </div>
            </div>
     </div>
          <div class="card notification-card mb-3">
            <table class="table table-bordered" formArrayName="categoryProducts">
                <tr>
                    <th colspan="2">Category:</th>
                    <th width="150px"><button type="button" (click)="addcategoryproduct()" class="btn btn-primary">Add More</button></th>
                  </tr>
                  <tr *ngFor="let teacher of categoryProduct().controls; let ti=index">
                   
                    <div [formGroupName]="ti" style="border: 1px solid blue; padding: 10px; width: 96%; display: inline-block; margin: 7px;">
        
          Category Name: <input type="text" formControlName="catgeory">
         Max items user can add <input type="text" formControlName="maximumSelectableProducts">
         <button type="button" class="btn-primary" (click)="addproductas(ti)">Add Product</button> 
         <!-- <button (click)="removeTeacher(ti)">Remove</button> -->
          
          <div formArrayName="products">
   
            <div *ngFor="let batch of productas(ti).controls; let bi=index">
   
              <div [formGroupName]="bi" style="border: 1px solid red; padding: 10px; margin: 5px; float:left">
   
                Name :<input type="text" formControlName="name">
                
                <button class="btn-danger" (click)="removeproductas(ti,bi)">Remove </button>             
   
              </div>
   
            </div>
   
          </div>
          
   
                   </div>
                    <td>
                        <button (click)="removecategoryproduct(ti)" class="btn btn-danger">Remove</button>
                    </td>
                  </tr>
            </table>
           
                
           
              </div>
              <div class="card notification-card ">
                  <div class="row">
                      <div class="col-md-3 form-group">
                        <label for="file">Image:</label>
                        <div class="upload-btn-wrapper">
                         
                            <div class="profile-upload" *ngIf="!this.mealID">
                                <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                              </div>
                              <div class="profile-upload" *ngIf="this.mealID">
                                <img [src]="this.Image?ImageURL||Image:'../../../assets/images/default-upload.png'" class="default-img" />
                              </div>
                          <button class="btn">Image</button>
                        <input 
                            id="bankProof" 
                            type="file" 
                            name="image"
                            class="form-control"
                            formControlName="image"
                            (change)="fileChangeEvent($event,template)">
                      </div>
                      
                      </div>
                      </div>              
               <div class="text-right submit-btn-wrap" >
                <button type="submit" class="btn btn-primary submit-btn mr-2">Submit</button>
                <button type="button" class="btn btn-outline-primary submit-line-btn" [routerLink]="[URLConstants.PRODUCT]">Cancel</button>

            </div> 
               </div>
                
               </form>
              
            
            </div>    
      
      </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>