import { Component, OnInit, TemplateRef } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';

// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';


// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Router } from '@angular/router';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  constructor(private commonService: CommonService, private router: Router, private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService, public bsModalRef: BsModalRef, private modalService: BsModalService) { 
  }

  ngOnInit(): void {

  }
  /****************************************************************************
  @Purpose     : Get smtpsmssetting
  @Parameter   : NA
  @Return      : admin
  /****************************************************************************/
  public ticketFormData: any ={
      subject: "",
      question: ""
  }
  /****************************************************************************
  @Purpose     : Update smtpsm-setting
  @Parameter   : form, formData
  @Return      : NA
  /****************************************************************************/
  public submitted = false;
  CreateTicket(form, formData): void {
    console.log('submitted');
     let responsedata ={
      subject: formData.subject,
      question:formData.question 

      }
      console.log(responsedata);    
      this.submitted = true;
      if (form.valid) {
        this.commonService.callApi(this.callAPIConstants.createTicket,responsedata, 'post', false, false).then((resposnseData) => {
          if (resposnseData.status === 1) {
            this.router.navigate([URLConstants.TICKET])
            this.showErrorService.popToast('success', 'Updated Successfully!');
          } else { this.showErrorService.popToast('error', resposnseData.message); }
        });
      }
     }
 }
