import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
// import { defaultPermissionConstants } from '../../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {

  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;

  constructor(private getPermissionsService: GetPermissionsService, private commonService: CommonService) {
    this.setBreadcrumbs();
    // START: Role Permission //
    // this.accessPermission = defaultPermissionConstants;
    // END: Role Permission //
  }

  ngOnInit() { this.setDTableInitialData(); }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Category', url: null},
   
    ];
    this.subHeaderData = {
      title: 'Menu Category',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      { type: 'text', colName: 'name', colFieldname: 'name', isVisible: true, sort: true, columnVisibility: true, filter: true },
    
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='categoryProductList';
    this.tableSetupData.actions = [
      
    ];
    this.tableSetupData.params = { deleteParams: 'id', statusParams: 'id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,
      //showButton: { routerLink: this.URLConstants.MENU_CATEGORY_ADDUPDATE, buttonName: 'Add CATEGORY' },
    };
  }
  /*********************************************************************************************/



}
