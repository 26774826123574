import { vehicleType, categoryConstants } from './../../../common/constants/categoryType-constants';
import { Component, OnInit, TemplateRef } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';

// plugin
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// interfaces
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  fileToUpload: any;
  imageUrl: any;
  mealID:any;
  myForm: FormGroup;
  imageSrc: string;
  uploadImgUrl =environment.uploadImgUrl;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  formData = new FormData();
  categoryList: any[] = [];
  foodTypeList:any[]=['Veg','Non-Veg','Eggetarian'];
  productCount:any={};
  public actID:any;
  MealValue:any='Add';
  constructor(private activatedRoute: ActivatedRoute, 
  public commonService: CommonService, private router: Router,
  public showErrorService: ShowErrorService,
  public fb:FormBuilder,
  public bsModalRef: BsModalRef,
  private modalService: BsModalService,
  private localStorageService: LocalStorageService) {
    // this.actID= this.activatedRoute.params.subscribe((params) => { 
    //   this.mealID = params.id; 
    // });
    this.activatedRoute.params.subscribe((params) => { this.mealID = params.id; });
    (this.mealID === undefined)? null :this.getmealDetail();    
    if(this.mealID !==undefined){
      this.getmealDetail();
      this.MealValue='Edit';
     } 
}
categoryCount(event){
  console.log(event);
  function getCount(){
     return event.productCount
  }
  this.productCount = event.productCount;
  console.log(this.productCount);  
}
getCategoryList(){
  let  CategoryList={
      "page": 1
  }
  this.commonService.callApi(this.callAPIConstants.categoryList,CategoryList,'post', false, true, false).then((success) => {
    if (success.status === 1) {
      this.categoryList = success.data;
      //this.showErrorService.popToast('success', success.message);
    } 
  });
}
  
 get f(){
   return this.myForm.controls;
 }

 onFileSelect(e: File[]){
   console.log('setting image');
   let fileName = e[0];
   let fileType = fileName.type;
   this.formData.append('image', e[0]);
 }
 handleFileInput(file: FileList) {
  this.fileToUpload = file.item(0);

  //Show image preview
  let reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageUrl = event.target.result;
  }
  reader.readAsDataURL(this.fileToUpload);
  this.formData.append('image', file[0]);
}
 onFileChange(event) {
   const reader = new FileReader();  
   if(event.target.files && event.target.files.length) {
     const [image] = event.target.files;
     reader.readAsDataURL(image); 
     reader.onload = () => {
       this.imageSrc = reader.result as string;
       this.myForm.patchValue({
         fileSource: reader.result
       });
     };
   }
 }
  ngOnInit(){
   this.getCategoryList();
  }
 /*************************************************************
  @Purpose     : Get Meal Details
  @Parameter   : NA
  @Return      : Meal
  /*************************************************************/
  getmealDetail() {
    this.commonService.callApi(this.callAPIConstants.DetailsMealdeals + this.mealID,
       '', 'get', false, false).then((success) => {
        console.log(success.data);
         this.MealForm ={
           _id:success.data._id,
          name:success.data.name, 
          description :success.data.description,
          price:success.data.price, 
          discountPercentage:success.data.discountPercentage, 
          foodType:success.data.foodType, 
          categoryProducts: [ 
            { 
               catgeory: success.data.categoryProducts[0].catgeory,
               maximumSelectableProducts: success.data.categoryProducts[0].maximumSelectableProducts, 
               products:[  { 
                 name:success.data.categoryProducts[0].products[0].name
                }
              ] 
            } 
          ] ,
          image:this.uploadImgUrl+success.data.image,
          type:success.data.type
          }
  
    });
  }
  // public cateCount =this.categoryCount.apply(null);
  MealForm :any ={
    _id:'',
    name:'', 
    description :'',
    price:'', 
    discountPercentage:'', 
    foodType:'select foodType', 
    categoryProducts: [ 
      { 
         catgeory: 'select Category',
         maximumSelectableProducts:0, 
         products:[  { 
           name:''
          }
        ] 
      } 
    ] ,
    image:null,
    type:'image/jpeg'
    }
    public submitted =false;
  addMeal(form,mealData){
    this.submitted = true;
  (this.mealID)?this.formData.append('_id',this.mealID):''
 
    //this.categoryCount.prototype;
    //console.log(this.MealForm.categoryProducts[0].maximumSelectableProducts) 
    this.formData.append('name',mealData.name);
    this.formData.append('description',mealData.description);
    this.formData.append('price',mealData.price);
    this.formData.append('discountPercentage',mealData.discountPercentage);
    this.formData.append('foodType',mealData.foodType);
    // this.formData.append('image',mealData.image)
    this.formData.append('type',mealData.type)
    this.formData.append('categoryProducts',JSON.stringify(mealData.categoryProducts));
    this.commonService.callApi(this.callAPIConstants.AddMealDeal, this.formData,'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.router.navigate([this.URLConstants.MEAL_DEALS]);
      } else { this.showErrorService.popToast('error', success.message);
      this.router.navigate([this.URLConstants.MEAL_DEALS]); }
    });
  }
/****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
	/****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
    this.formData.append('image', this.file);
    console.log(this.file);
    
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {
    
  }


}
