import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '../../common/service/theme/theme.service';
import { ShowErrorService } from '../../common/service/show-error.service';


// environment

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  passwordType: boolean;
  public user: any = {};
  public users: any = {};
  uploadImageUrl = environment.uploadImgUrl;
  Rolecast:any={};
  vendor= false;
  superAdmin=false;
  formcheck;

  constructor(private themeService: ThemeService, private router: Router,
    private localStorageService: LocalStorageService,
     private commonService: CommonService, private showErrorService: ShowErrorService
  ) {
 
 
  }
  ngOnInit(): void {
    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("loginSwitchTheme").setAttribute('checked', '');
  }
  selectChangeHandler (tabName:string) {
    console.log(tabName);
    this.formcheck = tabName;
    //update the ui
    this.vendor = false;
    this.superAdmin = false;
    if(tabName==='superAdmin'){
      this.superAdmin = true
    } else if(tabName==='vendor'){
      this.vendor = true
    }
    // this.Rolecast = event.target.value;
  }

  

  onLogin(form) {
    var numbers = /^[0-9]+$/;
    var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if ((form.value.emailOrPhone.match(email)) || (form.value.emailOrPhone.match(numbers) && form.value.emailOrPhone.length == 10)) {
      if(form.value.emailOrPhone ==='seed_admin@grr.la'){
        this.user = {
          authName: form.value.emailOrPhone,
          password: form.value.password
        }
        this.commonService.callApi(this.callAPIConstants.AdminLogin, this.user, 'post', true, false).then((success) => {
          if (success.status === 1) {
            console.log(success.data)
            this.localStorageService.setToken('accessToken', success.access_token);
            this.localStorageService.setToken('firstname', success.data.firstname);
            this.localStorageService.setToken('lastname', success.data.lastname);
            this.localStorageService.setToken('role',success.data.role.role);
            if (success.data.photo) {
              this.localStorageService.setToken('photo', this.uploadImageUrl + success.data.photo);
            }
            this.localStorageService.setToken('theme', success.data.theme);
            this.localStorageService.setToken('menuAlignment', success.data.menuAlignment);
            var isDark = success.data.theme == "Dark" ? true : false;
            this.themeService.switchThemeAfterLogin(isDark);
            this.localStorageService.setToken('role_permission',
             this.commonService.encrypt(success.data.rolePermission));
             if(success.data.role.role == 'Super Admin'){
              this.localStorageService.setToken('AdminId',
               this.commonService.encrypt(success.data.role._id));
              }
            //this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.DASHBOARD]);
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
      }else {
        console.log('in vendor');
        this.users = {
          emailOrMobile: form.value.emailOrPhone,
          password: form.value.password
        }
        console.log(this.users);
         this.commonService.callApi(this.callAPIConstants.UserLogin,this.users,'post', true, false).then((success) => {
          if (success.status === 1) {
            console.log(success.data)
            this.localStorageService.setToken('lat',success.data.latitude);
            this.localStorageService.setToken('lng',success.data.longitude);
            this.localStorageService.setToken('accessToken', success.extra_meta.token);
            this.localStorageService.setToken('firstname', success.data.fullName);
            this.localStorageService.setToken('lastname', success.data.lastname);
            this.localStorageService.setToken('role',success.data.role);
            if (success.data.photo) {
              this.localStorageService.setToken('photo', this.uploadImageUrl + success.data.photo);
            }
            this.localStorageService.setToken('theme', success.data.theme);
            let Vertical ='Vertical';
            this.localStorageService.setToken('menuAlignment',Vertical);
            var isDark = success.data.theme == "Dark" ? true : false;
            this.themeService.switchThemeAfterLogin(isDark);
            this.localStorageService.setToken('role_permission',

             this.commonService.encrypt(success.data.rolePermission));
              if(success.data.role === 'vendor'){
                this.localStorageService.setToken('VendorId',success.data._id);
              }
            //this.showErrorService.popToast('success', success.message);
            this.router.navigate([this.URLConstants.DASHBOARD]);
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
      }
     
     }

  }

  /*************************************************************/
  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

}
