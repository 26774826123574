import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TicketManagementRoutingModule } from './ticket-management-routing.module';
import { TicketManagementComponent } from './ticket-management.component';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import { AnswerTicketComponent } from './answer-ticket/answer-ticket.component';


@NgModule({
  declarations: [TicketManagementComponent,CreateComponent,ListComponent, VendorListComponent, AnswerTicketComponent],
  imports: [
    CommonModule,
    TicketManagementRoutingModule,
    SharedModule
  ]
})
export class TicketManagementModule { }
