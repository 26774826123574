<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <div class="card-title">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
          </div>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-xl-2 col-sm-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-lg-2 col-sm-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <hr />
              <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">Product</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb"> 
                <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{Productvalue|titlecase}}
                  </li>
              </ol>
          </nav>
          <div class="card notification-card">
            
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
              <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                    <div class="text">
                        <h5 class="mb-0 text Uppercase">{{Productvalue}} Product</h5>
                    </div>
              </div>
              <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
                <form #form="ngForm" (ngSubmit)="form.form.valid &&
                  addProduct(form,productData)" novalidate>
                   <div class="row form-group">
                          <div class="col-md-4">
                           <label for="">Product Name <sup class="bx bxs-star danger"></sup></label>
                           <input type="text" class="form-control"
                           [(ngModel)]="productData.name"
                            name="name" required maxlength="15" required
                             id="name" minlength="3"
                             [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''"
                              [class.error-border]="name.invalid && form.submitted"
                             #name="ngModel"  pattern="[a-zA-Z][a-zA-Z ]+">
                             
                             <div *ngIf="((submitted || name.touched || name.dirty) && name.invalid)" class="error-block">
                              <div [hidden]="!name.errors.required">
                                Name is required
                              </div>
                              <div [hidden]="!name.errors.pattern">
                                Only alphabetsallowed
                              </div>
                              <div [hidden]="!name.errors.minlength">
                                Please Enter Atleast 4 Characters
                              </div>
                              <div [hidden]="!name.errors.maxlength">
                                Please Enter Atleast 15 Characters
                              </div>
                              
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label for="">foodType <sup class="bx bxs-star danger"></sup></label>
                            <ng-select [items]="foodTypeList" bindLabel="name" bindValue="name"
                            [searchable]='true' [multiple]="false"
                              [clearable]='false'  #foodType="ngModel"
                              name="foodType" 
                              id="foodType"
                              placeholder="foodType"  required
                              [(ngModel)]="productData.foodType" 
                              [ngClass]="((submitted ||foodType.touched ) && (foodType.errors||foodType.invalid))?'has-danger':''"

                             >
                            </ng-select>
                          </div>
                          <div class="col-md-4">
                            <label for="">Product Price in CAD<sup class="bx bxs-star danger"></sup></label>
                            <input type="number" class="form-control"
                            [(ngModel)]="productData.price"
                             name="price" 
                             id="price" pattern="^[0-9]*$"
                             [ngClass]="((submitted ||price.touched ) && (price.errors||price.invalid))?'has-danger':''"

                             #price="ngModel" required>
                             <div *ngIf="((submitted || price.touched || price.dirty) && price.invalid)" class="error-block">
                              <div [hidden]="!price.errors.required">
                                Price is required
                              </div>
                              <div [hidden]="!price.errors.pattern">
                                Only Number allowed
                              </div>                            
                            </div>
                          </div>
                    </div>
                     <div class="row form-group" >  
                     
                      <div class="col-md-4">
                        <label for="">attribute <sup class="bx bxs-star danger"></sup></label>
                        <ng-select [items]="attributeList" bindLabel="name" bindValue="_id"
                        [searchable]='true' [multiple]="false"
                          [clearable]='false' #attribute_id="ngModel" 
                          name="attribute_id" 
                          id="attribute_id"
                          placeholder="attribute_id" (change)="attributeget($event)"
                          [(ngModel)]="productData.attribute[0].id" required
                         >
                        </ng-select>
                      </div>
                      <div class="col-md-4">
                        <label for="">Attribute:Price <sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control" required
                        [(ngModel)]="productData.attribute[0].price"
                         name="attribute_Price" id="attribute_Price"
                          pattern="^[0-9]*$"
                         #attribute_Price="ngModel">
                         <div *ngIf="((submitted || attribute_Price.touched || attribute_Price.dirty) && attribute_Price.invalid)" class="error-block">
                          <div [hidden]="!attribute_Price.errors.required">
                            Price is required
                          </div>
                          <div [hidden]="!attribute_Price.errors.pattern">
                            Only Number allowed
                          </div>                            
                        </div>
                      </div>
                        <div class="col-md-4">
                          <label for="">Discount in Percentage:- <sup class="bx bxs-star danger"></sup></label>
                          <input type="text" class="form-control"
                          [(ngModel)]="productData.discountPercentage"
                           name="discountPercentage" 
                           id="discountPercentage" pattern="^[0-9]*$"
                           #discountPercentage="ngModel" required>
                           <div *ngIf="((submitted || discountPercentage.touched || discountPercentage.dirty) && discountPercentage.invalid)" class="error-block">
                            <div [hidden]="!discountPercentage.errors.required">
                              Discount% is required
                            </div>
                            <div [hidden]="!discountPercentage.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>
                      </div>
                    <div class="row form-group">        
                        <div class="col-md-4">
                          <label for="">Select Product Category <sup class="bx bxs-star danger"></sup></label>
                            <ng-select [items]="categoryList" bindLabel="name" bindValue="_id"
                            [searchable]='true' [multiple]="false"
                              [clearable]='false' #categoryId="ngModel" 
                              name="categoryId" 
                              placeholder="categoryId" 
                              [(ngModel)]="productData.categoryId" required>
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                          <label for="">Select Cuisine <sup class="bx bxs-star danger"></sup></label>
                          <ng-select [items]="cuisineList" bindLabel="name" bindValue="_id"
                          [searchable]='true' [multiple]="false"
                            [clearable]='false' #cuisineId="ngModel" 
                            name="cuisineId" 
                            placeholder="cuisineId" 
                            [(ngModel)]="productData.cuisineId"
                            (keyup.enter)="searchData($event)" required>
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <label for="">Variant :Price <sup class="bx bxs-star danger"></sup></label>
                          <input type="text" class="form-control"
                          [(ngModel)]="productData['variant'][0].price"
                           name="variant_price" id="variant_price" pattern="^[0-9]*$"
                           #variant_price="ngModel" required>
                           <div *ngIf="((submitted || variant_price.touched || variant_price.dirty) && variant_price.invalid)" class="error-block">
                            <div [hidden]="!variant_price.errors.required">
                              must give Price of variant 
                            </div>
                            <div [hidden]="!variant_price.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>
                    </div>
                      <div class="row form-group">    
                        <div class="col-md-4">
                          <label for="">calories <sup class="bx bxs-star danger"></sup></label>
                          <input type="text"  class="form-control"
                          [(ngModel)]="productData.calories"
                           pattern="^[0-9]*$"
                          name="calories" id="calories"
                           #calories="ngModel" required>
                          <div *ngIf="((submitted || calories.touched || calories.dirty) && calories.invalid)" class="error-block">
                            <div [hidden]="!calories.errors.required">
                              calories is required
                            </div>
                            <div [hidden]="!calories.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">fat <sup class="bx bxs-star danger"></sup></label>
                          <input type="text"  class="form-control" pattern="^[0-9]*$"
                           [(ngModel)]="productData.fat" name="fat" id="fat"
                           #fat="ngModel" required>
                           <div *ngIf="((submitted || fat.touched || fat.dirty) && fat.invalid)" class="error-block">
                            <div [hidden]="!fat.errors.required">
                              fat is required
                            </div>
                            <div [hidden]="!fat.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label for="">protein <sup class="bx bxs-star danger"></sup></label>
                          <input type="text" [(ngModel)]="productData.protein"
                           name="protein" id="protein"  class="form-control" pattern="^[0-9]*$"
                           #protein="ngModel" required>
                           <div *ngIf="((submitted || protein.touched || protein.dirty) && protein.invalid)" class="error-block">
                            <div [hidden]="!protein.errors.required">
                              protein is required
                            </div>
                            <div [hidden]="!protein.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>
                      </div>
                    <div class="row  form-group">
                        <div class="col-md-4">
                          <label for="">carbohydrate <sup class="bx bxs-star danger"></sup></label>
                          <input type="text" class="form-control"
                          [(ngModel)]="productData.carbohydrate" 
                          name="carbohydrate" id="carbohydrate" pattern="^[0-9]*$"
                          #carbohydrate="ngModel" required>
                          <div *ngIf="((submitted || carbohydrate.touched || carbohydrate.dirty) && carbohydrate.invalid)" class="error-block">
                            <div [hidden]="!carbohydrate.errors.required">
                              carbohydrate is required
                            </div>
                            <div [hidden]="!carbohydrate.errors.pattern">
                              Only Number allowed
                            </div>                            
                          </div>
                        </div>                     
                        <div class="col-md-4">
                          <label for="">Variant :Name <sup class="bx bxs-star danger"></sup></label>
                          <ng-select [items]="VariantNameList" 
                          bindLabel="name" bindValue="name"
                          [searchable]='true' [multiple]="false"
                            [clearable]='false' #variant_name="ngModel"
                            name="variant_name"
                            id="variant_name"
                            placeholder="variant_name" 
                            [(ngModel)]="productData.variant[0].name" required
                           >
                          </ng-select>
                          
                        </div>
                        <div class="col-md-4">
                       
                        <input type="text" class="form-control"
                        [(ngModel)]="productData.attribute[0].name" hidden
                         name="attribute_name" id="attribute_name" placeholder="Attribute Name"
                         #attribute_name="ngModel" required minlength="3" max="15">
                        
                      </div>
                       
                    </div>
                    <div class="row">  
                        <div class="col-md-6">
                          <div class="form-group">
                          <label for="">Cuisine Preference <sup class="bx bxs-star danger"></sup></label>
                          <ng-select [items]="cuisinePreferenceList" bindLabel="name" bindValue="name"
                          [searchable]='true' [multiple]="false"
                            [clearable]='false'  #cuisinePreference="ngModel"
                            name="cuisinePreference"
                            id="cuisinePreference"
                            placeholder="cuisinePreference" 
                            [(ngModel)]="productData.cuisinePreference" required
                           >
                          </ng-select>
                        </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                          <label for="">Product Description </label>
                          <input type="text"  class="form-control"
                          [(ngModel)]="productData.description" 
                          name="description" 
                          id="description"  pattern="[a-zA-Z][a-zA-Z ]+"
                          #description="ngModel"
                            required maxlength="75" minlength="3"
                          >
                          <div *ngIf="((submitted || description.touched || description.dirty) && description.invalid)" class="error-block">
                            <div [hidden]="!description.errors.required">
                              Description is required
                            </div>
                            <div [hidden]="!description.errors.pattern">
                              Only alphabetsallowed
                            </div>
                            <div [hidden]="!description.errors.minlength">
                              Please Enter Atleast 4 Characters
                            </div>
                            <div [hidden]="!description.errors.maxlength">
                              Please Enter Atleast 75 Characters
                            </div>
                            
                          </div>
                          </div>
                          </div>
                          <div class="col-md-4">
                         
                                <div class="form-group">
                                  <label for="name">Product Image</label>
                                  <div class="upload-btn-wrapper">
                                           <div class="profile-upload" *ngIf="!this.productId">
                                             <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                                           </div>
                                           <div class="profile-upload" *ngIf="this.productId">
                                            <img [src]="productData.image?productData.image||ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                                          </div>
                                             <button class="btn">Upload a Image</button>                                        
                                             <input 
                                             [(ngModel)]="productData.image"
                                             id="image"  class="form-control"
                                             type="file" name="image" 
                                             class="form-control" #image="ngModel"
                                             (change)="fileChangeEvent($event,template)">
                                               <!-- <input type="file" name="myfile" /> -->
                                             </div>
                               </div>
                          </div>
                    </div>
                      <div class="row form-group">
                        <div class="m-4 custom-control col-md-3 custom-switch">
                          &nbsp; <div></div>
                           <input type="checkbox" 
                           class="custom-control-input" 
                           [(ngModel)]="productData.mealDeals"
                           name = "mealDeals" #mealDeals="ngModel"
                           id="customSwitch1">
                           <label class="custom-control-label" for="customSwitch1">Meal Deal</label>
                         </div>                     
                        <div class="m-4 custom-control col-md-3 custom-switch">
                         &nbsp; <div></div>
                         <input type="checkbox" 
                         class="custom-control-input" 
                         [(ngModel)]="productData.isVegan"
                         name= "isVegan" #isVegan="ngModel"
                         id="customSwitch2">
                         <label class="custom-control-label" for="customSwitch2"> Vegan</label>
                       </div>
                  </div>
                 

                  <div class="text-right submit-btn-wrap" >
                    <button type="submit" class="btn btn-primary submit-btn mr-2" [disabled]="!form.form.valid">Submit</button>
                    <button type="button" class="btn btn-outline-primary submit-line-btn"(click)="resetproduct()">Cancel</button>
                </div> 
                </form>
                
            </div>    
        </div>
      </div>
    </div>
    <ng-template #template>
      <div class="modal-header">
          <h4 class="modal-title">
              Crop
              <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                  <em class="la la-close"></em>
              </a>
          </h4>
  
      </div>
      <div class="modal-body">
          <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="modal-footer">
          <div class="row">
              <div class="col-md-12 text-right">
                  <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
              </div>
          </div>
      </div>
  </ng-template>