import { ViewComponent } from './view/view.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';

import { MealDealComponent } from './meal-deal.component';
import { AddComponent } from './add/add.component';

const routes: Routes = [
  { 
    path: 'list',
    component: ListComponent 
  },
  {
    path: 'addupdate/:id',
    //canActivate: [CanCreateActivate],
    component: AddComponent,
    pathMatch: 'full',
  },
  {
    path: 'add',
     component: AddComponent,
      pathMatch: 'full',
  },
  {
    path: 'view/:id',
     component: ViewComponent,
      pathMatch: 'full',
  },
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MealDealRoutingModule { }
