<!-- BEGIN: Sub Header  -->

<!-- END: Sub Header  -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Category</li>
                <li class="breadcrumb-item">
                    <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                 {{this.CategoryValue}}
                </li>
            </ol>
        </nav>
        <div class="card notification-card">
          
          <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
            <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                  <div class="text">
                      <h5 class="mb-0 text Uppercase">{{CategoryValue}}</h5>
                  </div>
            </div>
            <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
                <form [formGroup]="myForm" (ngSubmit)="submit()" novalidate>
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                      <label for="name">Name</label>
                      <input 
                          formControlName="name"
                          id="name" 
                          type="text" 
                          class="form-control">
                      <div *ngIf="f.name.touched && f.name.invalid" class="error-block">
                          <div *ngIf="f.name.errors.required">Name is required.</div>
                          <div *ngIf="f.name.errors.minlength">Name should be 3 character.</div>
                      </div>
                  </div>  
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                      <label for="name">file</label>
                      <div class="upload-btn-wrapper">
                        <div class="profile-upload" *ngIf="!this.categoryID">
                            <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <div class="profile-upload" *ngIf="this.categoryID">
                            <img [src]="this.Image?ImageURL||Image:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                             
                                 <button class="btn">Upload a file</button>
                             
                                 <input 
                                 formControlName="image"
                                 id="image" 
                                 type="file" 
                                 class="form-control"
                                 (change)="fileChangeEvent($event,template)"
                                 >
                                   <!-- <input type="file" name="myfile" (change)="onFileSelect($event.target.files)" /> -->
                                 </div>
                   </div>
                </div>
            </div>
                 
                   
                            
                   
                      <div class="text-right submit-btn-wrap" >
                          <button type="submit" class="btn btn-primary submit-btn mr-2">Submit</button>
                          <button type="button" class="btn btn-outline-primary submit-line-btn" [routerLink]="[URLConstants.DASHBOARD]">Cancel</button>
                      </div> 
                  </form>
          </div>    
      </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>