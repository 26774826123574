import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../../common/service/common.service';
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../../common/service/show-error.service';
import { RestrictKeyPressService } from '../../../../common/service/restrict-key-press.service';
import { Router } from '@angular/router';
// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-assign-modal',
  templateUrl: './assign-modal.component.html',
  styleUrls: ['./assign-modal.component.scss']
})
export class AssignModalComponent implements OnInit {

  public callAPIConstants = callAPIConstants;
  title;
  searchText;
  commonObject:any = {};
  public dataResp;
  public city;
  public submitted = false;
  public data;
  public type;
  public apiname;
  public answerResponse :any=[];
  public orderList;
  public driver:any={};
  public  allDrviersList: any =[];
  public ID:any;
  public  QuestionerName:any;
  constructor(public modalService:BsModalService,
    public router: Router,
    public modalRef : BsModalRef, 
    public commonService : CommonService,
    public showErrorService: ShowErrorService, 
    public broadCasterService: BroadcasterService,
     public restrictKeyPressService: RestrictKeyPressService) {  
  }
  ngOnInit(): void {
    // this.getDriverDetails();
    // this.filterOrders(event);
    // console.log(this.filterOrders);
    
    if(this.data){    
      this.commonObject= this.data;
      this.ID = this.data._id;
      console.log(this.ID);
      
      console.log(this.commonObject);
      this.QuestionerName=this.data.ticketCreator.fullName;
      console.log(this.QuestionerName);
      
     }     
  }
  public AnswerFormData: any ={
    answer: '',
  }
   //getcountries List API 
   AnswerCall(form, formData): void{
    let obj = {
      answer: formData.answer,
      ticketId: this.ID
    }
    this.commonService.callApi(this.callAPIConstants.adminAnswerTicket,obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.answerResponse = success.data;
        this.showErrorService.popToast('success', success.message);
        console.log(this.answerResponse);
        
      }
    }); 
 }
  
   




}
