import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewRoutingModule } from './review-routing.module';
import { ReviewComponent } from './review.component';
import { ListComponent } from './list/list.component';


@NgModule({
  declarations: [ReviewComponent, ListComponent],
  imports: [
    CommonModule,
    ReviewRoutingModule,
    SharedModule
  ],schemas:[CUSTOM_ELEMENTS_SCHEMA]
  
})
export class ReviewModule { }
