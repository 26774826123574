<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">FAQ's</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
             
                  <li class="breadcrumb-item">
                      <a ><i class="bx bx-home-alt"></i></a>
                  </li>
                  <li class="breadcrumb-item">Category</li>
                  <li class="breadcrumb-item active" aria-current="page">
                     ADD
                  </li>
              </ol>
          </nav>
      <div class="card notification-card tabs-block p-0">
                <nav class="nav nav-tabs p-0">
                    <div class="m-portlet  settings-wrapper approve-template custom-tabs">
                        
                    </div>
                  </nav>
                      <form (ngSubmit)="saveFaqsForm.form.valid && 
                      saveFaqsCategory(saveFaqsForm, saveCategoryFaqsFormData)"
                          #saveFaqsForm="ngForm">
                          <div class="card-body">
                          <div class="row">
                              <div class="col-md-6">
                                  <div
                                    class="form-group align-items-center mb-md-5 mb-3 user-details"
                                  >
                                  <label>
                                    faqCategoryName**<sup class="bx bxs-star danger"></sup
                                  ></label>
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="faqCategoryName"
                                      [(ngModel)]="saveCategoryFaqsFormData.faqCategoryName"
                                      placeholder="Enter Faq Name"
                                      id="faqCategoryName"
                                      #faqCategoryName="ngModel"
                                      autocomplete="on"       
                                      required
                                    />
                                    <div *ngIf="((submitted || faqCategoryName.touched || faqCategoryName.dirty) && faqCategoryName.invalid)" class="alert alert-danger">
                                      <div [hidden]="!faqCategoryName.errors.required">
                                        fat is required
                                      </div>
                                      <div [hidden]="!faqCategoryName.errors.pattern">
                                        Only Number allowed
                                      </div>                            
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div
                                    class="form-group align-items-center mb-md-5 mb-3 user-details"
                                  >
                                  <label>
                                    faqCategoryId*<sup class="bx bxs-star danger"></sup
                                  ></label>
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="faqCategoryId"
                                      [(ngModel)]="saveCategoryFaqsFormData.faqCategoryId"
                                      placeholder="Enter Faq ID"
                                      id="faqCategoryId"
                                      #faqCategoryId="ngModel"
                                      autocomplete="on"
                                    />
                                    <div *ngIf="((submitted || faqCategoryId.touched || faqCategoryId.dirty) && faqCategoryId.invalid)" class="alert alert-danger">
                                      <div [hidden]="!faqCategoryId.errors.required">
                                        fat is required
                                      </div>
                                      <div [hidden]="!faqCategoryId.errors.pattern">
                                        Only Number allowed
                                      </div>                            
                                    </div>
                                  </div>
                              </div>
                           </div>
                           
                         
    
                            <div class="btn-blocks d-flex align-items-center justify-content-end mb-md-0 mb-2">
                              <button class="btn btn-primary" [disabled]="!saveFaqsForm.form.valid">Save Changes</button>
                            </div>
                          </div> 
                      </form>        
                          
                 
                  <!-- </tab>
                  </tabset> 
                  </div> -->
         </div>
      </div>
    </div>