import { SharedModule } from '../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryRoutingModule } from './category-routing.module';
import { CategoryComponent } from './category.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { ListComponent } from './list/list.component';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import{ViewDetailsComponent} from './view-details/view-details.component';
import { ListCategoryComponent } from './list-category/list-category.component';


@NgModule({
  declarations: [CategoryComponent, EditDetailsComponent,ListComponent,ViewDetailsComponent, ListCategoryComponent],
  imports: [
    CommonModule,
    CategoryRoutingModule,SharedModule,
    ReactiveFormsModule,FormsModule
  ],schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class CategoryModule { }
