import { environment } from './../../../../environments/environment.staging';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// interface
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

// constatns
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
// service
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';
import { isDefined } from '@angular/compiler/src/util';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-vproduct',
  templateUrl: './vproduct.component.html',
  styleUrls: ['./vproduct.component.scss']
})
export class VproductComponent implements OnInit {

  fileToUpload: any;
  imageUrl: any;
  imageSrc: string;
  uploadImgUrl= environment.uploadImgUrl
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  productForm: FormGroup;
   formData = new FormData();
  myForm: FormGroup;
  categoryList: any[] = [];
  cuisineList:any[]=[];
  attributeList:any[]=[];
  foodTypeList:any[]=['Veg','Non-Veg','Eggetarian'];
  VariantNameList:any[]=['Small','Large','Extra Large'];
  cuisinePreferenceList:any[]=['Treat Mode','Fit Mode'];
  productId:any;
  VendorId: string;
  Productvalue:any='Add';
 constructor(private activatedRoute: ActivatedRoute,
   public commonService: CommonService, private fb: FormBuilder,
   public showErrorService: ShowErrorService, private router: Router,
   public bsModalRef: BsModalRef,
   private modalService: BsModalService,
   private localStorageService: LocalStorageService) {
    this.activatedRoute.params.subscribe((params) => { this.productId = params.id; });
    this.VendorId =this.localStorageService.getToken('VendorId');
    //(this.productId === undefined)? null :this.getProduct();
    if(this.productId !== undefined){
      this.getProduct();
      this.Productvalue='Edit';
    }   
}
  ngOnInit(){
    this.getCategoryList();
    this.getCusineList();
    this.getAttribute();
    this.productForm=this.fb.group({ 
    name: ['',Validators.required],
    image:null,
    description:['',Validators.required], 
    price:['',Validators.required],
    discountPercentage:['',Validators.required], 
    categoryId: [''], 
    cuisineId:[''], 
    foodType:[''], 
    variant:this.fb.array([ ]),
    attribute:this.fb.array([ ]), 
    calories:[''],
     fat:[''],
    protein:[''],
     carbohydrate:[''],
    cuisinePreference:[''], 
    isVegan:['']
  })
  }
/*******************GetProduct Details************************************************/
getProduct(){
  this.commonService.callApi(this.callAPIConstants.Product_Detail + this.productId + '/' + this.VendorId,
    '', 'get', false, false).then((success) => {
     console.log(success.data);
    
      
       
    
 });
}

/*****************Search****************************************/
  public searchText;
  searchData(event) {
    let page = 1;
    if (event.target && event.target.value) {
      this.searchText = event.target.value;
     }
   else {
       delete this.searchText;
    }
  }
  get f(){
    return this.myForm.controls;
  } 
/********************form array************************************/
 /**********************Variant***************************** */
variant() : FormArray {
  return this.productForm.get("variant") as FormArray
}
newvariant(name='',price=''):FormGroup{
  return this.fb.group({
     name: [name], 
    price:[price]
  })
}
addvariant() {
  this.variant().push(this.newvariant());
}
removevariant(i:number) {
  this.variant().removeAt(i);
}
/****************************************************** */
/*******************************attribute */
attribute() : FormArray {
  return this.productForm.get("attribute") as FormArray
}
newattribute(id='',name='',price=''):FormGroup{
  return this.fb.group({
    id:[id],
    name: [name], 
    price:[price]
  })
}
addattribute() {
  this.attribute().push(this.newattribute());
}
removeattribute(i:number) {
  this.attribute().removeAt(i);
}
 

  /********** form default data**************************************/
 public productData= {
   name:'' ,
   image:null,
   description:'', 
   price:'',
   discountPercentage:'', 
   categoryId: '', 
   cuisineId:'', 
   foodType:'', 
   variant:[{ 
       name: "", price:'' , 
      }], 
   attribute:[ {
          id: "", 
          name: "", price: '',
    }], 
   calories:'',
    fat:'',
   protein:'',
    carbohydrate:'',
   cuisinePreference:'Cuisine Preference',
    mealDeals:false, 
   isVegan:false
    }

    attributeget(event){
      console.log(event);
      function getCount(){
         return event.productCount
      }
      this.productData.attribute[0].name = event.name;
      if(event.price === undefined)
      {
           this.productData.attribute[0].price ='0'
      }else {
        this.productData.attribute[0].price = event.price;
      }
     // this.productCount = event.productCount;
      //console.log(this.productCount);  
    }
    /***********List of Category ***************/
    getCategoryList(){
      let  CategoryList={
          "page": 1
      }
      this.commonService.callApi(this.callAPIConstants.categoryList,CategoryList,'post', false, true, false).then((success) => {
        if (success.status === 1) {
          this.categoryList = success.data;
          console.log('category list call');
          
          //this.showErrorService.popToast('success', success.message);
        } 
      });
    }
    /***********List of Cuisine ***************/
   getCusineList(){
    let Cuisinelist ={
        page: 1,
        searchQuery:this.searchText
      }  
    this.commonService.callApi(this.callAPIConstants.getCuisine,Cuisinelist,'post', false, true, false).then((success) => {
      if (success.status === 1) {
        this.cuisineList = success.data;
        console.log('in get cuisine list'); 
       } 
    });
   } 
   /***********List of Attribute ***************/
   getAttribute(){
    let AttributeList ={
      page: 1,
    }  
  this.commonService.callApi(this.callAPIConstants.getAttribute,AttributeList,'post', false, true, false).then((success) => {
    if (success.status === 1) {
      this.attributeList = success.data;
    
      console.log('inside get Attribute list');
    } 
  });
   }
   /***********Add Product ***************/
   resetproduct(){
   this.productData= {
      name:'' ,
      image:null,
      description:'', 
      price:'',
      discountPercentage:'', 
      categoryId: '', 
      cuisineId:'', 
      foodType:'', 
      variant:[{ 
          name: "", price:'' , 
         }], 
      attribute:[ {
             id: "", 
             name: "", price: '',
       }], 
      calories:'',
       fat:'',
      protein:'',
       carbohydrate:'',
      cuisinePreference:'Cuisine Preference',
       mealDeals:false, 
      isVegan:false
       }
   
   }
   display:any;
   displayed(e :string){
      console.log(e);
      this.display=e;
   }
  public submitted =false;
  onSubmit(){
      
  }
  addProduct(form, productDatas){
    this.submitted = true;
    (this.productId)?this.formData.append('_id',this.productId):'';
      console.log(productDatas);
            this.formData.append('name',productDatas.name);
            // this.formData.append('image',productDatas.image);
            this.formData.append('description',productDatas.description);
            this.formData.append('price',productDatas.price);
            this.formData.append('discountPercentage',productDatas.discountPercentage);
            this.formData.append('categoryId',productDatas.categoryId);
            this.formData.append('cuisineId',productDatas.cuisineId);
            this.formData.append('foodType',productDatas.foodType);
            this.formData.append('variant',JSON.stringify(productDatas.variant));
            this.formData.append('attribute',JSON.stringify(productDatas.attribute));
            this.formData.append('calories',productDatas.calories);
            this.formData.append('fat',productDatas.fat);
            this.formData.append('protein',productDatas.protein);
            this.formData.append('carbohydrate',productDatas.carbohydrate);
            this.formData.append('cuisinePreference',productDatas.cuisinePreference);
            this.formData.append('mealDeals',JSON.stringify(productDatas.mealDeals));
            this.formData.append('isVegan',JSON.stringify(productDatas.isVegan));
            this.commonService.callApi(this.callAPIConstants.Add_Product, this.formData,'post', false, true, false).then((success) => {
              if (success.status === 1) {
                this.router.navigate([this.URLConstants.PRODUCT]);
                this.showErrorService.popToast('success', success.message);
              } else { 
                this.router.navigate([this.URLConstants.PRODUCT]);
                this.showErrorService.popToast('error', success.message); }
            });
         }
/****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
	/****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  imageCropped(event) {
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>): void {
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
  
  //Show image preview

    this.formData.append('image', this.file);
    console.log(this.file);
    
    // this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fd, 'post', true, true).then((success) => {
    //   (success.status === 1) ? this.admin.photo = success.data.filePath : this.showErrorService.popToast('error', success.message);
    // });
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {
    // if(this.admin.photo){
    //   this.admin.photo = null;

    // } 
  }


}
