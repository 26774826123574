import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styles: [],
})
export class ListComponent implements OnInit, DoCheck {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;

  constructor(private commonService: CommonService,public getPermissionsService: GetPermissionsService) {
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('role');
    // END: Role Permission //
  }

  ngOnInit() {
    this.setDTableInitialData();
    this.setBreadcrumbs();
  }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Roles & Permissions', url: null },
    ];
    this.subHeaderData = {
      title: 'Users',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {}, saveColumnKey: null };
  setDTableInitialData() {
    const tempData = [
      { type: 'text', colName: 'Role', colFieldname: 'role', isVisible: true, sort: true, filter: true },
      { type: 'switch', colName: 'status', colFieldname: 'status', isVisible: true, sort: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    if (!this.accessPermission.read && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'roleList';
    this.tableSetupData.saveColumnKey = 'roleListing';
    this.tableSetupData.actions = [
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'text-primary bx bx-edit',
        type: 'icon',permission: this.accessPermission.edit,
      },
      {
        id: 3, buttonTitle: 'Delete', tooltip: 'Delete', class: 'text-danger bx bx-trash-alt',
        type: 'icon',permission: this.accessPermission.delete,
      },
    ];
    this.tableSetupData.params = { statusParams: 'ids',deleteParams: 'ids' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: false,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: true,
      showButton: { routerLink: this.URLConstants.ADD_ROLE, buttonName: 'Add Role' },
    };
  }
  /*************************************************************/
}
