
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
      <div class="card animated fadeIn">
          <div class="card-header text-left">
              <div class="card-title">
                  <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
              </div>
          </div>
          <div class="card-body">
              <div class="container-fluid">
                  <div class="form-group row">
                      <div class="col-xl-2 col-sm-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-4">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card animated fadeIn">
          <div class="card-header text-left">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
          </div>
          <div class="card-body">
              <div class="container-fluid">
                  <div class="form-group row">
                      <div class="col-lg-2 col-sm-3">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <div class="form-group row">
                      <div class="col-xl-2 col-lg-3">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-xl-10 co-lg-12">
                          <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                      </div>
                  </div>
                  <hr />
                  <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-2">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                      <div class="col-md-2">
                          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- END : Show Skeleton Loader -->
    <div class="main-content-area">
          <div class="main-content-block">
            <h3 class="content-header-title">Product</h3>
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                  
                    <li class="breadcrumb-item">
                      <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                    </li>
                      <li class="breadcrumb-item active" aria-current="page">
                     Product
                      </li>
                  </ol>
              </nav>
                <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
                  <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                        <div class="text">
                            <h5 class="mb-0 text Uppercase">Profile </h5>
                        </div>
                  </div>
      <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
        <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
           <div class="row">
            <div class="form-group">
                <label for="name">Product Image</label>
                <div class="upload-btn-wrapper">
                         <div class="profile-upload" *ngIf="!this.productId">
                           <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                         </div>
                         <div class="profile-upload" *ngIf="this.productId">
                          <img [src]="ImageURL?productData.image||ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                        </div>
                           <button class="btn">Upload a Image</button>                                        
                           <input 
                           formControlName="image"
                           id="image"  class="form-control"
                           type="file" name="image" 
                           class="form-control" 
                           (change)="fileChangeEvent($event,template)">
                             <!-- <input type="file" name="myfile" /> -->
                           </div>
             </div>
            
           </div>
            <div class="row">
            <div class="col-md-6 pb-3">
                <div class="card notification-card ">
                  <div class="row ">
                        <div class="col-md-6 form-group">
                          <label for="">Name</label>
                          <input type="text" class="form-control"
                        formControlName="name"
                         name="name" required maxlength="15" required
                          id="name" minlength="3"
                            pattern="[a-zA-Z][a-zA-Z ]+">
                        </div>                
                       <div class="col-md-6 form-group">
                         <label for="">Description</label>
                         <input type="text"  class="form-control"
                        [(ngModel)]="productData.description" 
                        name="description" 
                        id="description"  pattern="[a-zA-Z][a-zA-Z ]+"
                        formControlName="description"
                          required maxlength="75" minlength="3"
                        >
                       </div>
                       <div class="col-md-6 form-group">
                         <label for="">price</label>
                         <input type="number" class="form-control"
                         name="price" 
                         id="price" pattern="^[0-9]*$"
                         formControlName="price"
                         required>
                       </div>
                       <div class="col-md-6 form-group">
                        <label for="">Discount Percentage</label>
                        <input type="text" class="form-control"
                        formControlName="discountPercentage"
                          name="discountPercentage" 
                          id="discountPercentage" pattern="^[0-9]*$"
                           required>
                      </div>                   
                        
                    </div>
               </div>
              </div>
              <div class="col-md-6 pb-3">
                <div class="card notification-card ">
                  <div class="row ">
                    <div class="col-md-6 form-group">
                        <label for="">Category</label>
                        <ng-select [items]="categoryList" bindLabel="name" bindValue="_id"
                        [searchable]='true' [multiple]="false"
                          [clearable]='false'
                          name="categoryId" 
                          placeholder="categoryId" 
                         formControlName="categoryId" required>
                        </ng-select>
                      </div>
                      <div class="col-md-6 form-group" >
                        <label for="">Cuisine</label>
                        <ng-select [items]="cuisineList" bindLabel="name" bindValue="_id"
                        [searchable]='true' [multiple]="false"
                          [clearable]='false' 
                          name="cuisineId" 
                          placeholder="cuisineId" 
                         formControlName="cuisineId"
                          (keyup.enter)="searchData($event)" required>
                        </ng-select>
                      </div>
                     <fieldset>

                        <div class="form-check">
                            <input class="form-check-input"  (click)="displayed('Treat')"  type="radio" formControlName="cuisinePreference" name="cuisinePreference" id="fixed" value="fixed" >
                            <label class="form-check-label" for="Treat">
                             Treat Mode
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input"(click)="displayed('Fit')"  type="radio"formControlName="cuisinePreference" name="cuisinePreference" id="percentage" value="percentage" checked>
                            <label class="form-check-label" for="Fit">
                              Fit Mode
                            </label>
                          </div>
                     </fieldset>
                        
                     </div>
                     </div>
              </div>
          </div>
          <div class="row">
            <div *ngIf="this.display === 'Treat'" class="card notification-card mb-3">
                <table class="table table-bordered" formArrayName="variant">
                  <tr>
                    <th colspan="2">Add:</th>
                    <th width="150px"><button type="button" (click)="addvariant()" class="btn btn-primary">Add More</button></th>
                  </tr>
                  <tr *ngFor="let quantity of variant().controls; let i=index" [formGroupName]="i">
                   
                    <td>
                      Name:
                        <input type="text" formControlName="name" class="form-control">
                    </td>
                    <td>
                      Price:
                        <input type="text" formControlName="price" class="form-control">
                    </td>
                    <td>
                        <button (click)="removevariant(i)" class="btn btn-danger">Remove</button>
                    </td>
                  </tr>
                </table>
               
              </div>
              <div *ngIf="this.display === 'Fit'" class="card notification-card mb-3">
                <div class="row">
                    <div class="col-md-6">
                    <label for="">calories</label>
                    <input type="text" class="form-control"
                    formControlName="calories"
                      name="calories" 
                      id="calories" pattern="^[0-9]*$"
                       required>
                    </div>
                    <div class="col-md-6">
                        <label for="">protein</label>
                        <input type="text" class="form-control"
                        formControlName="protein"
                          name="protein" 
                          id="protein" pattern="^[0-9]*$"
                           required>
                     </div>
                     <div class="col-md-6">
                        <label for="">carbohydrate</label>
                        <input type="text" class="form-control"
                        formControlName="carbohydrate"
                          name="carbohydrate" 
                          id="carbohydrate" pattern="^[0-9]*$"
                           required>
                        </div>
                    <div class="col-md-6">
                            <label for="">fat</label>
                            <input type="text" class="form-control"
                            formControlName="fat"
                              name="fat" 
                              id="fat" pattern="^[0-9]*$"
                               required>
                     </div>
                     <div class="col-md-6">
                        <label for="">Attribute</label>
                        <ng-select [items]="attributeList"
                        bindLabel="name" 
                        placeholder="Select" 
                        formControlName="attribute"
                        [searchable]='true' [multiple]="false"
                          [clearable]='false'
                          name="attribute_id" 
                          id="attribute_id"
                          placeholder="attribute_id" (change)="attributeget($event)">
                    </ng-select>
                     
                    </div>

                </div>
                <div class="row">
                   
                    <div class="col-md">
                        <table class="table table-bordered" formArrayName="attribute">
                            <tr>
                              <th colspan="2">Add:</th>
                              <th width="150px"><button type="button" (click)="addattribute()" class="btn btn-primary">Add More</button></th>
                            </tr>
                            <tr *ngFor="let quantity of attribute().controls; let i=index" [formGroupName]="i">
                              <td>
                                  
                                  <input hidden type="text" formControlName="id" class="form-control">
                              </td>
                              <td>
                                Name:
                                  <input type="text" formControlName="name" class="form-control">
                              </td>
                              <td>
                                Price:
                                  <input type="text" formControlName="price" class="form-control">
                              </td>
                              <td>
                                  <button (click)="removeattribute(i)" class="btn btn-danger">Remove</button>
                              </td>
                            </tr>
                          </table>
                    </div>
               
            </div>
              </div>
          </div>
         
        </form>
      </div>    
          
          </div>
    </div>
    <ng-template #template>
        <div class="modal-header">
            <h4 class="modal-title">
                Crop
                <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                    <em class="la la-close"></em>
                </a>
            </h4>
    
        </div>
        <div class="modal-body">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
                </div>
            </div>
        </div>
    </ng-template>