import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, NgZone, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// environment
import { environment } from 'src/environments/environment';
// plugins
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

// interfaces
import { ITableSetupData } from '../../../common/interfaces/interfaces';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { LoginComponent } from 'src/app/public/login/login.component';
import { async } from '@angular/core/testing';
import { TemplateDetailsComponent } from '../modals/template-details/template-details.component';
import { CommonModalComponent } from '../modals/common-modal/common-modal.component';
import { AssignModalComponent } from '../modals/assign-modal/assign-modal.component';
import { CusineDelModalComponent } from '../modals/cusine-del-modal/cusine-del-modal.component';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';


@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],

})
export class ResponsiveDataTableComponent implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('filterForm', { static: false }) filterForm;  //EarningForm
  @ViewChild('EarningForm', { static: false }) EarningForm; 
  bsValue = new Date();
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public uploadImageUrl = environment.uploadImgUrl;
  dtOptions: any = {};
  //  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  @Input() searchText: any;
  search:any;
  public color: any = '';
  public filterColor: any = '#4184EF';
  public tempSettingsData:  any = [];
  showSkeletonLoader1: boolean = true;
  // @Input() accessPermission: any;
  @Input() columns: Array<any>;
  @Input() accessPermission: any;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  public downloadUrl: any = 'http://commonapi.node.indianic.com/public/'
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions1: any;
  public columnVisibilityOptions2: boolean = false;
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleFilterButton: boolean = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadUserFile: string;
  link: string;
  cols: any = [];
  configs: any = {};
  selectRole:any[]=[];
  VendorId:any;
  EarningLists:any={
    page:1,
    filterStatus :['credit','debit'],
    fromDate:'',
    toDate:'',
  };
  roleList:any=['vendor','driver','user'];
  roleLists:any=['Vendor','Driver','User'];
  public colSettingCols: Array<any> = [];
  listingAddUpdateBroadCaster: any;
  calenderData: any = {};
  status: boolean = false;
  statusDownload: boolean = false;
  statusColorPicker: boolean = false;
  statusFilterColorPicker: boolean = false;
  serverName:any;
  colors = [
    {
      hex: '#00cfdd',
      name: 'blue'
    },
    {
      hex: '#b3c0ce',
      name: 'gray'
    },
    {
      hex: '#FF5B5C',
      name: 'rose'
    },
    {
      hex: '#b87ee5',
      name: 'purple'
    },
    {
      hex: '#39DA8A',
      name: 'green'
    },
    {
      hex: '#FDAC41',
      name: 'yellow'
    },
  ];

  selectedColor: '';
  faqlist: {  };
  categoryfaq: any ='user';
  EarningList: { page: number; filterStatus: string; fromDate: string; toDate: string; };

  selectColor(hash,col) {
   this.color = col;
   document.getElementById("downloadDropdownColor").style.backgroundColor=hash;
   this.openCloseColorDropdown();
    }

  active:boolean = false;
 
  selectedColorName: '';
 
  selector(){
    if(!this.selectedColor) {
      return 'Color';
    }
    else {
      return '<span style="background: ' + this.selectedColor + '"></span> ' + this.selectedColorName;
    }
  }
  changeEarning(event){
    this.APIparameters =[];
    console.log(event);
    let EarningLists={
      page:1,
      filterStatus :event,
      fromDate:this.EarningLists.fromDate,
      toDate:this.EarningLists.toDate,

    }
    this.EarningLists.filterStatus =event;
    this.APIparameters= this.EarningLists;
    //this.APIparameters.filter.push(filter)
    console.log(this.APIparameters);
    //this.callApiToGetData();
    
  }
  changeFn(event){
    console.log(event);
   this.APIparameters.filter=[]
    let filter = {
      key: "role",
      value: event
    }
    this.APIparameters.filter.push(filter)
    console.log(this.APIparameters);
    this.callApiToGetData();
    
  }
  changefaq(event){ 
    console.log(event)
    let ev =event; 
    this.APIparameters.category = ev;
    console.log(this.APIparameters);
    this.callApiToGetData();

  }
  setColor(color, colorName) {
    this.selectedColor = color;
    this.selectedColorName = colorName;
    this.active = false;
  }
  

  toggleDropdown() {
    this.active = !this.active;
   
  }

  // open filter button
  openFilter(){
    this.status = !this.status;       
  }

  // open download button
  openDownloadButton(){
    this.statusDownload = !this.statusDownload;       
  }

  // open color dropdown
  openCloseColorDropdown(){
    this.statusColorPicker = !this.statusColorPicker;
  }

  // open color filter dropdown
  openColorDropdownFilter() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }
  // filter related variables
  typeArr = [
    { name: 'contains', value: 'contains' },
    { name: 'greaterThan', value: 'greaterThan' },
    { name: 'lessThan', value: 'lessThan' },
    { name: 'date', value: 'date' },
  ]
  // ngOnChanges(changes:SimpleChanges){
  //  console.log(changes);
  //  let dat = changes['searchText'];
  //    if(dat.currentValue === true){
  //       this.search = dat;
  //    }
  //  console.log(this.search);
   
  // }
  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;

  constructor(public commonService: CommonService, 
    public showErrorService: ShowErrorService,
    private router: Router, public bsModalRef: BsModalRef,
     private modalService: BsModalService,private localStorageService: LocalStorageService,
    public restrictKeyPressService: RestrictKeyPressService,
     public broadCaster: BroadcasterService, public zone: NgZone) {
    this.APIparameters = { page: 1, pagesize: 10 };
     this.VendorId =this.localStorageService.getToken('VendorId');
     console.log(this.VendorId);
     
    this.listingAddUpdateBroadCaster = this.broadCaster.on<boolean>('listingAddUpdate').subscribe((success) => {
      if (success) {
        this.callApiToGetData();
      }
    });
  }

  ngOnInit() {
    console.log(this.EarningForm); 
    console.log("aa gaye table me");
    this.EarningLists={
      page:1,
      filterStatus :['credit','debit'],
      fromDate:'',
      toDate:'',
      pagesize:10
    };
    this.faqlist ={
      page : 1,
      pagesize: 10,
      category: "User",
      columnKey: "faqsListing",
      searchText: "",
      filter: [
        { key: "faqQuestion",
          type: "contains",
          value: ""
        } ],
      condition: "$or"
   }
    this.addTask();
    // this.filteredArr[0].method = 'exclude';
    // this.tableConfig = this.tableSetupData;
    // this.configs = this.tableSetupData;

    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;

    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.colSettingCols.push(element)
      }
      console.log(this.tableConfig.cols);

    });
    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    //if (this.accessPermission.statusUpdate && this.accessPermission.delete) {
    this.statusList = ['Active', 'Inactive', 'Delete'];
    // }
    // else if (this.accessPermission.statusUpdate || (this.accessPermission.statusUpdate && !this.accessPermission.delete)) {
    //   this.statusList = ['Active', 'Inactive'];
    //  } 
    //else if (!this.accessPermission.statusUpdate && this.accessPermission.delete) {
    //  this.statusList = ['Delete'];
    // } 
    //else {
    // this.conditions.showApplyStatus = false;
    const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
   // if (this.tableConfig.cols[index]) { this.tableConfig.cols[index].isVisible = true; }
    // }
    /*************************************************************/
  }
  addTask() {
    this.filteredArr.push({
      condition:this.condition
    })
  }

  ngAfterViewInit() {
    this.callApiToGetData();
    // options for datatable
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }
  OnInput(event: any) {
    this.serverName = event.target.value;
    }
  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // if (this.listingAddUpdateBroadCaster) {
    //   .unsubscribe();
    // }
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: any;
  public CSV: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;
  public deleteDownloadTemplate: any;
  public deleteCuisine:any;

  
  // tslint:disable-next-line: cognitive-complexity
  async callApiToGetData() {
    console.log(this.type);
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.CSV = this.callAPIConstants.UserCSVforDataTable;
        this.EXCEL = this.callAPIConstants.UserEXCELforDataTable;
        this.DeleteUser = this.callAPIConstants.UserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.APIparameters.searchText = this.searchText;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      } else if (this.type === 'adminUserlist') {
        this.getMethodName = this.callAPIConstants.AdminList;
        this.DeleteUser = this.callAPIConstants.AdminDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.AdminChangeStatus;
        // this.getMethodName = this.callAPIConstants.AdminUserlist;
        // this.CSV = this.callAPIConstants.AdminCSVforDataTable;
        // this.EXCEL = this.callAPIConstants.AdminEXCELforDataTable;
        // this.DeleteUser = this.callAPIConstants.AdminDeleteUsers;
        // this.ChangeStatus = this.callAPIConstants.AdminChangeStatus;
        // this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        // this.getFilterlist = this.callAPIConstants.AdminGetColumnData;
        // this.saveFilter = this.callAPIConstants.saveFilter;
        // this.getFilter = this.callAPIConstants.AdminGetFilters;
        // this.deleteFilter = this.callAPIConstants.deleteFilter;
        // this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadAdminUserFile;
      
        // this.downloadUserFile = this.callAPIConstants.downloadAdminUserFile;
      } else if (this.type === 'adminUserlist1') {
        this.APIparameters.page = 1;
        this.APIparameters.pagesize = 5;
      } 
      else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.getFilterlist = this.callAPIConstants.EmailColumnData;
        // this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
        console.log( this.ChangeStatus);
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'cmsListing') {
        this.getMethodName = this.callAPIConstants.cmsListing;
        console.log(this.getMethodName);
        this.DeleteUser = this.callAPIConstants.cmsDetail​;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        console.log(this.APIparameters);
        
      }else if(this.type ==='earninglist'){
        this.APIparameters.page=1;
        this.APIparameters.pagesize=10;
        this.getMethodName = this.callAPIConstants.earningList;
      
      }else if(this.type ==='withDrawList'){
        this.getMethodName = this.callAPIConstants.withdrawList;
        this.APIparameters['page']=1;
      }
      else if(this.type ==='mealdealist'){
        this.getMethodName = this.callAPIConstants.mealdealList;
        this.DeleteUser = this.callAPIConstants.mealdealDelete;
        this.ChangeStatus = this.callAPIConstants.mealdealStatus;
        
        console.log(this.getMethodName);
      }else if(this.type === 'vendorcuisineList'){
        let responseData ={
          page: 1,
          searchQuery: this.searchText
        }
      this.getMethodName = this.callAPIConstants.getCuisine;
      this.APIparameters['searchQuery'] = this.searchText;
      }
      else if(this.type === 'cuisineList'){  //cuisineList   
         let responseData ={
            page: 1,
            searchQuery: this.searchText
          }
        this.getMethodName = this.callAPIConstants.getCuisine_Admin;
        this.DeleteUser = this.callAPIConstants.DeleteCuisine;
        this.ChangeStatus = this.callAPIConstants.changeCuisineStatus;
        this.APIparameters['page'] = 1;
        this.APIparameters['searchQuery']=this.searchText;
        console.log(this.getMethodName);
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      }
      else if (this.type === 'countriesListing') {
        this.getMethodName = this.callAPIConstants.CountriesListing;
        this.ChangeStatus = this.callAPIConstants.changeCountriesStatus;
        //this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCountries;

      }
      else if (this.type === 'currencyListing') {
        this.getMethodName = this.callAPIConstants.currenciesListing;
        this.ChangeStatus = this.callAPIConstants.changeCurrenciesStatus;
        // this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCurrencies;

      }
      else if (this.type === 'timezoneListing') {
        this.getMethodName = this.callAPIConstants.timezoneListing;
        this.ChangeStatus = this.callAPIConstants.changeTimezoneStatus;
        // this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteTimezones;

      }else if(this.type === 'vendorFaq'){
        this.getMethodName = this.callAPIConstants.VendorFaqsListing;
        this.APIparameters['category'] = 'Vendor'
        this.APIparameters = this.faqlist;
        
      } else if (this.type == 'faqsListing') {

        this.getMethodName = this.callAPIConstants.FaqsListing;
        this.APIparameters = this.faqlist;
        this.ChangeStatus = this.callAPIConstants.FaqsStatusChange;
        this.DeleteUser = this.callAPIConstants.DeleteFaqs;
      }else if(this.type==='faqsCategoryListing'){
        this.getMethodName = this.callAPIConstants.FaqsCategoryListing;
        this.ChangeStatus = this.callAPIConstants.FaqsCategoryStatus;
        this.DeleteUser = this.callAPIConstants.FaqsCategoryDelete;
      }
      else if (this.type == 'StaticPageList') {
        this.getMethodName = this.callAPIConstants.Listing;
        this.ChangeStatus = this.callAPIConstants.ChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteBlog;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.BlogDetails = this.callAPIConstants.GetBlogDetails
      }
      else if (this.type === 'notificationList') {
        this.getMethodName = this.callAPIConstants.getNotifications;
      }else if (this.type ==='productlist') {
        console.log( ' in prod');
        
        let responseData ={
          page :1,
          searchQuery:this.searchText
        }
        this.getMethodName = this.callAPIConstants.productList;
        this.APIparameters['page']=1;
        this.APIparameters['searchQuery'] =this.searchText;
        console.log(this.APIparameters);
        this.ChangeStatus = this.callAPIConstants.Product_Status;
        this.DeleteUser = this.callAPIConstants.Delete_Product;
      
        
      }else if(this.type === 'coupanlist'){
        this.getMethodName = this.callAPIConstants.CoupanList;
        this.ChangeStatus = this.callAPIConstants.CoupanChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteCoupan; 
      }else if(this.type ==='attributeList'){
           console.log('in Attribute');
        this.getMethodName = this.callAPIConstants.getAttribute;
        this.ChangeStatus = this.callAPIConstants.changeAttributeStatus;
        this.DeleteUser = this.callAPIConstants.deleteAttribute;
      }else if(this.type ==='categoryProductList'){
        this.getMethodName = this.callAPIConstants.ProductCategoryList;
      }
      else if (this.type === 'categoryList'){
        console.log('in category'); 
        this.getMethodName = this.callAPIConstants.categoryList;
        this.DeleteUser = this.callAPIConstants.DeleteCategory;
        console.log(this.DeleteUser);
        
        this.ChangeStatus = this.callAPIConstants.changeCategorystatus;
        console.log(this.ChangeStatus);
        
        //this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      }else if(this.type ==='attributeList'){
        console.log('in category'); 
        this.getMethodName = this.callAPIConstants.getAttribute;
        this.ChangeStatus = this.callAPIConstants.changeAttributeStatus;
        console.log(this.ChangeStatus);
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if(this.type ==='vendorlist'){
         
        this.getMethodName = this.callAPIConstants.vendorTicketList;
        this.APIparameters['page'] = 1;
        this.APIparameters['pagesize']=10;
      }
      else if(this.type ==='adminTicketlist'){
        console.log('admin ticket'); 
        this.getMethodName = this.callAPIConstants.adminTicketList;
        //this.ChangeStatus = this.callAPIConstants.changeAttributeStatus;
        //console.log(this.ChangeStatus);
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      }else if(this.type ==='orderlist'){
        console.log('admin ticket'); 
        this.getMethodName = this.callAPIConstants.Orderlist;
      }else if(this.type==='reviewlist'){
        console.log('in review');
        let reviewData={
          page: 1,
          _id: this.VendorId
        }
        this.getMethodName = this.callAPIConstants.Reviewlist;
        this.APIparameters['page']= 1;
        this.APIparameters['_id'] = this.VendorId;
      }

    }
    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, 'post', false, false, false).then((success) => {
        if (success.status === 1) {
          this.showSkeletonLoader1 = false;
          console.log(success.data);
          this.tableData.data = [];
          console.log(success.meta);
          if(success.data.listing!== undefined){
            this.tableData.data = success.data.listing;
            this.total = success.total;
          } else if(success.meta!== undefined){
            console.log('pahuche');
            this.tableData.data = success.data;
            this.total = success.meta.total;
          } else  {
            this.tableData.data = success.data;
            this.total = success.total;
          }
          console.log(this.tableData.data);
          console.log(this.total);
          this.tempSettingsData = success.data.templateSettings;
          //console.log(success.data.templateSettings.length)
          if (success.data.templateSettings && success.data.templateSettings.length) {
            console.log('i n template');
            
            var hash=this.colors.find(a=>a.name=="green").hex;
            document.getElementById("downloadDropdownColor").style.backgroundColor=hash;
          }

          if (success.data.latestColumns && success.data.latestColumns.length) {
            console.log(' i in leneth');
            this.colSettingCols = [];
            let arr = [];
            success.data.latestColumns.forEach((e, lindex) => {
              if (e.drag) {
                this.colSettingCols.push(e);
              }
              if (e.filter) {
                this.filterCols.push(e);
              }
              this.tableSetupData.cols.forEach((c, colIndex) => {
                if (c.colName === e.colName) {
                  arr = this.arraymove(this.tableSetupData.cols, colIndex, lindex);
                } else if (c.colName === 'Select') {
                  this.tableSetupData.cols.splice(colIndex, 1)
                }
              })
            });
            this.tableSetupData.cols = arr;
            setTimeout(() => {
              this.tableConfig.cols = [];
              this.tableSetupData.cols.unshift({ type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },)
              this.tableConfig.cols = this.tableSetupData.cols
              this.colSettingCols = this.colSettingCols
              // if(this.type != 'cuisineList'){
                this.tableData.data = success.data.listing;
              // } else {
                this.tableData.data = success.data;
              // }
              console.log(this.tableData.data);
              this.tableConfig.cols.forEach(async (element) => {
                if (success.data.columnSettings && this.type !== 'adminUserlist1') {
                  success.data.columnSettings.forEach((dataApi) => {
                    if (element.colFieldname === dataApi.key) {
                      element.isVisible = dataApi.status;
                    }
                  });
                }
                else {
                  if (this.columns && this.columns.length) {
                    this.columns.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  }
                }
              });
              // if (!this.accessPermission.statusUpdate) {
              const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
              if (this.tableConfig.cols[index]) {
                this.tableConfig.cols[index].isVisible = false;
                this.tableConfig.cols[index].filter = false;
                if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
              }
              // }
            }, 0);
          }
          else {
            this.getLatestData(success);
          }
          
          this.savedFilters = success.data.filterSettings;
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }
    console.log(this.tableData.data);
    
    
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  getLatestData(success) {
    this.tableConfig = this.tableSetupData;
    this.configs = this.tableSetupData;
    this.cols = [];
    this.filterCols = [];
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.cols.push(element)
      }
      if (element.filter) {
        this.filterCols.push(element)
      }
    });
    console.log(this.filterCols, 'filterCols');

    this.tableConfig.cols.forEach(async (element) => {
      if (success.data.columnSettings && this.type !== 'adminUserlist1') {
        success.data.columnSettings.forEach((dataApi) => {
          if (element.colFieldname === dataApi.key) {
            element.isVisible = dataApi.status;
          }
        });
      }
      else {
        if (this.columns && this.columns.length) {
          this.columns.forEach((dataApi) => {
            if (element.colFieldname === dataApi.key) {
              element.isVisible = dataApi.status;
            }
          });
        }

      }
    });
    //if (!this.accessPermission.statusUpdate) {
    // const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
    // if (this.tableConfig.cols[index]) {
    //   this.tableConfig.cols[index].isVisible = false;
    //   this.tableConfig.cols[index].filter = false;
    //   if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
    // }
    //}
    //this.tableData.data = success.data.listing;
     this.rerenderDataTable();

  }
  // *************************************************************//
  saveColumnSettings(tableColumnSettings) {
    this.CallAPIchangeColumnVisibility(tableColumnSettings)
  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = "";
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      }
    })
    if (!this.count) {
      delete this.options.templateName;
    }
    else {
    }
  }
  toggleDownload() {
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    })
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  saveDownloadTemplate(tableConfigCols) {
    this.submitted = true;
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({ key: element.colFieldname, status: element.isSelected });
      }
    });
    const data: any = { columns: this.downloadColumnsArr, color: this.color, description: this.options.templateName };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options._id) {
      data.templateId = this.options._id;
    }
    if (this.options.templateName) {
      this.commonService.callApi(this.downloadColumns, data, 'post').then((success) => {
        if (success.status === 1) {
          this.CallAPIgetDownloadTemplates();
          this.toggleDownload();
          this.showErrorService.popToast('success', 'template saved successfully');
          this.callApiToGetData();
        }
        else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
    else {
      this.showErrorService.popToast('error', 'please enter template Name');
    }
  }
  CallAPIdeleteDownloadTemplate(i) {
    this.showErrorService.confirmpopToast('warning', 'Are you sure,You Want To Delete ?').then((result) => {
      if(result.value === true) {
        this.commonService.callApi(this.deleteDownloadTemplate + i._id, '', 'delete', false, false).then((success) => {
          if (success.status === 1) {
            
            this.showErrorService.popToast('success', success.message);
            this.callApiToGetData();
            this.CallAPIgetDownloadTemplates();
            this.toggleDownload();
          } else { this.showErrorService.popToast('error', success.message); }
        });
      }
      
    });

    
  }
  
  showTemplate(i) {
    this.modalService.show(TemplateDetailsComponent, { initialState: { columns: i.columns, type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' });
  }

  downloadTemplate(key) {
    let templateData: any = { filteredFields: [] };
    key == 'print' ? templateData.type = 'pdf' : templateData.type = key;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) {
        if (element.isSelected) {
          templateData.filteredFields.push(element.colFieldname);
        }
      }
    });
    this.commonService.callApi(this.downloadUserFile, templateData, 'post').then(res => {
      if (res.status == 1) {
        if (key == 'print') {
          window.open(this.downloadUrl + 'pdf/' + res.data.filePathAndName, '_blank');
        }
        else {
          this.link = this.downloadUrl + key + '/' + res.data.filePathAndName;
          fetch(this.link).then(res1 => res1.blob()) // Gets the response and returns it as a blob
            .then((blob: any) => {
              var link: any = document.createElement('a');
              let objectURL = URL.createObjectURL(blob);
              link.href = objectURL;
              link.target = "_blank";
              link.download = res.data.filePathAndName;
              link.click();
              link.remove();
            });
        }
      }
      else {
        this.showErrorService.popToast('errror', res.message);
      }
    })
  }
  editTemplate(i) {
    console.log(i);
    this.options = i;
    this.options.templateName = i.description;
    this.color = i.color;
    var hash=this.colors.find(a=>a.name==i.color).hex;
    document.getElementById("downloadDropdownColor").style.backgroundColor=hash;
    this.count = true;
    this.tableConfig.cols.forEach(ele => {
      i.columns.forEach(ele1 => {
        if (ele1.status && ele1.key == ele.colFieldname) {
          ele.isSelected = true;
        }
        else {
          ele.isSelected = false;
        }
      })

    })
    console.log(this.tableConfig.cols);

  }
  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }

  applyLatestFilter(form?, item?) {
    console.log('in apply');
    console.log(item);
    this.submitted = true;
    if (form) {
    
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
        //condition: this.condition
      }
      this.clearFilter();
     // this.callApiToGetData();
    } else {
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: item.filter,
        condition: item.condition
      }
      this.clearFilter();
      this.callApiToGetData();
    }
    this.openFilter();

  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV or Excel file of datatable( with and without filter )
  /********************************************************************************/
  public download = {};
  public isFilter: boolean;

  CallAPIdownloadCSVandExcel(data, isFilter) {
    const downloadArr: any[] = [];
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) {
        downloadArr.push(element.colFieldname);
      }
    });
    isFilter ?
      this.download = {
        columns: downloadArr,
        filter: this.filter,
      }
      :
      this.download = {
        columns: downloadArr,
      };
    data === 'csv' ?
      this.commonService.callApi(this.CSV, this.download, 'post', false, false).then((success) => {
        window.open(success.data, '_blank');
      })
      :
      this.commonService.callApi(this.EXCEL, this.download, 'post', false, false).then((success) => {
        window.open(success.data, '_blank');
      });
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data) {

    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data._id);
        if (x === -1) {
          this.selectedUserList.push(data._id);
        }
      } else {
        this.selectedUserList.push(data._id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  submitStatusForm(statusForm?, statusFormData?) {
    // this.submitted = true;
    if (this.selectedUserList.length) {
      // DELETE
      // if (statusFormData.status === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete?').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      // }
      // DELETE
      // STATUS
      // if (statusFormData.status === 'Inactive' || statusFormData.status === 'Active') {
      //   let data;
      //   statusFormData.status === 'Inactive' ?
      //     data = { [this.params.statusParams]: this.selectedUserList, status: false } :
      //     data = { [this.params.statusParams]: this.selectedUserList, status: true };
      //   this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
      //   const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
      //   this.tableConfig.cols[index].checkbox = false;
      // }
      // STATUS
    } else {
      this.showErrorService.popToast('error', 'Select Users To Modify');
    }
    // statusForm.reset();
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [2, 5, 10, 25, 100];

  public itemsPerPage: number = this.localStorageService.getToken('itemsPerPage') ? parseInt(this.localStorageService.getToken('itemsPerPage')) : 10;
  currentPage = this.localStorageService.getToken('currentPage') ? parseInt(this.localStorageService.getToken('currentPage')) : 1;
  pagination(e) {
    this.localStorageService.setToken('currentPage',e.page);
    this.localStorageService.setToken('itemsPerPage',e.itemsPerPage);
    this.deselectMultipleUser();
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.currentPage = e.page;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
    
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.pagesize = e;
    this.APIparameters.page = 1;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
    this.deselectMultipleUser();
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  importCSVandExcel(key) {
    if (this.type == 'countriesListing') {
      if (key == 'csv') {
        // this.commonService.router.navigate([URLConstants.COUNTRYBULKUPLOAD, 'master', this.type, 'csv'])
      }
      else {
        // this.commonService.router.navigate([URLConstants.COUNTRYBULKUPLOAD, 'master', this.type, 'excel'])
      }
    }
  }
  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) {
        count++;
      }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex(
        (o) => o.isVisible === true && o.columnVisibility === true
      );
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }
  

  CallAPIchangeColumnVisibility(tableConfigCols) {
    console.log('i table config');
    
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.columnVisibleArr.push({ key: element.colFieldname, status: element.isVisible });
      }
    });

    const data: any = { columns: this.columnVisibleArr };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
      data.latestColumns = this.colSettingCols;
    }

    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success) => {
      success.status === 1 ?
        this.showErrorService.popToast('success', 'Updated Successfully') : this.showErrorService.popToast('error', success.message);
      this.callApiToGetData();
    });
    this.columnVisibleArr = [];
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      element.isVisible = true;
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data) {
   if(this.type ==='mealdealist'){
    console.log(callAPI,data);
    this.commonService.callApi(callAPI+data.mealDealId, data,
    'delete', false, false, false).then((success)=>{
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
      } });
    this.selectedUserList = [];
   } else if(this.type=='cuisineList'){
     console.log(callAPI,data);
     
      this.commonService.callApi(callAPI+data.cuisineId[0], data,
         'delete', false, false, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          setTimeout(() => {
            this.callApiToGetData();
          });
          console.log('inside call api change status');
          
          this.selectedUserList = [];
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }else{

    this.commonService.callApi(callAPI, data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
        console.log('inside call api change status');
        
        this.selectedUserList = [];
      } else {
        this.showErrorService.popToast('error', 'Something Went Wrong!');
      }
    });}
  }

  // *************************************************************//

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        await dtInstance.destroy();
        // Call the dtTrigger to rerender again
        await this.dtTrigger.next();
      });
    } else { await this.dtTrigger.next(); }
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/
  onSorted(event) {
    console.log('in sorting');
    
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    let sort_index = this.tableConfig.cols.findIndex((e)=> e.colFieldname == event.sortColumn.colFieldname);
    this.tableConfig.cols[sort_index]['columnSorted'] = true;
    console.log(this.tableConfig.cols);
    this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
  console.log(this.APIparameters);
  
    this.callApiToGetData();
  }
  // onSorted(event) {
  //   console.log(event, "ee");

  //   this.APIparameters.sort = {};
  //   event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
  //   this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
  //   this.callApiToGetData();
  // }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/

  performAction(k, i) {
    console.log(k)
    // VIEW action
    if (k.id === 1) {
      if (this.type === 'userlist') {
        console.log('in user list',this.URLConstants.USERS_DETAIL,i._id);
        
        this.router.navigate([this.URLConstants.USERS_DETAIL, i._id]);
      } else if(this.type === 'StaticPageList'){
        this.router.navigate([this.URLConstants.VIEW_STATIC_DETAILS, i._id]);
      }else if(this.type ==='cuisineList'){
        this.router.navigate([this.URLConstants.CUISINE_DETAILS, i._id]);
      }else if(this.type ==='driverListing'){
        this.router.navigate([this.URLConstants.DRIVERS, i._id]);
      }else if(this.type === 'cmsListing'){
        console.log('cms')
        this.router.navigate([this.URLConstants.CMS_DETAIL, i._id]);
      }else if(this.type === 'categoryList'){
        console.log('category')
        this.router.navigate([this.URLConstants.CATEGORY_DETAILS, i._id]);
      }else if(this.type ==='productlist'){
        this.router.navigate([this.URLConstants.VIEW_PRODUCT, i._id]);
      }else if(this.type ==='mealdealist'){
        this.router.navigate([this.URLConstants.DETAIL_MEAL_DEALS, i._id]);
      }else if(this.type ==='coupanlist'){
        this.router.navigate([this.URLConstants.ViEW_COUPAN, i._id]);

      }else if(this.type==='attributeList'){
        this.router.navigate([this.URLConstants.ATTRIBUTE_DETAILS, i._id]);
      }
  
    } else if (k.id === 2) {
      if(this.type ==='mealdealist'){
          this.router.navigate([this.URLConstants.EDIT_MEAL_DEALS, i._id]);
      }else if(this.type ==='productlist'){
        this.router.navigate([this.URLConstants.EDIT_PRODUCT, i._id]);
      }else if(this.type ==='cuisineList'){
        this.router.navigate([this.URLConstants.ADD_UPDATE_CUISINE, i._id]);
      }else if(this.type === 'faqsCategoryListing'){
        this.router.navigate([this.URLConstants.ADD_FAQSCATEGORY, i._id]);
      }else if(this.type ==='faqsListing'){
        this.router.navigate([this.URLConstants.ADD_UPDATEFAQ, i._id]);
      }else if (this.type === 'adminUserlist') {
        //this.router.navigate([this.URLConstants.ADMINUSER_DETAIL, i._id]);
      }else if (this.type === 'emailTemplateList') {
        this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
      }else if(this.type === 'coupanlist'){
        this.router.navigate([this.URLConstants.EDIT_COUPAN,i._id]);
      }
       else if (this.type === 'cmsListing') {
        console.log('cms')
        this.router.navigate([this.URLConstants.CMS_EDIT, i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.EDIT_ROLE, i._id]);
      }else if (this.type === 'countriesListing') {
        this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
      } else if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.ADDUPDATEUSER, i._id, 'edit']);
      }
      else if(this.type === 'adminTicketlist'){
        console.log('in adminaswer');
        console.log(this.URLConstants.ADMIN_ANSWER_TICKET);
        //this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
        this.router.navigate([this.URLConstants.ADMIN_ANSWER_TICKET, i._id]);
      }
      else if (this.type === 'currencyListing') {
        this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
      }
      else if (this.type === 'timezoneListing') {
        this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
      } else if (this.type === 'StaticPageList') {
        this.router.navigate([this.URLConstants.EDIT_STATIC_PAGE,i._id]);
      }
      else if (this.type === 'categoryList') {
        this.router.navigate([this.URLConstants.CATEGORY_EDIT,i._id]);
        
      }else if(this.type === 'attributeList'){
        this.router.navigate([this.URLConstants.ATTRIBUTE_EDIT,i._id]);
      }
    } else if (k.id === 3) {
     
      this.showErrorService
      .confirmpopToast("warning", "Do you want to delete?")
      .then((result) => {
        if (result.value === true) {
          const dataObj = {
            [this.params.deleteParams]: [i._id],
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
        }
      });
    }else if(k.id===4){
      this.openDeleteCuisneModal('edit',i)
    }
    else if(k.id===5){
      this.openAssignModal('edit',i)
    }
  }
  // SWITCH action
  changeStatus(d) {
    let dataObj:any;
    console.log('im in change status');
    if(this.type ==='productlist'){
     dataObj = {
        [this.params.statusParams]: d._id,
        status: !d.status,
      };
    }else{
      dataObj = {
      [this.params.statusParams]: [d._id],
      status: !d.status,
    };
    }
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
  }
  openDeleteCuisneModal(e,i){
    if(e === 'edit'){
      const Data =  Object.assign({},i)
       this.bsModalRef = this.modalService.show(CusineDelModalComponent, {
         initialState: {
           title: 'Edit',
           data: Data,
           type: this.type
       },
       class: 'modal-dialog-centered'
       });
     }
     else{
       this.bsModalRef = this.modalService.show(CusineDelModalComponent, {
         initialState: {
           title: 'Add',
           data: {},
           type: this.type
 
       },
       class: 'modal-dialog-centered'
       });
     }
  }
  openAssignModal(e,i){  
    if(e === 'edit'){
     const Data =  Object.assign({},i)
      this.bsModalRef = this.modalService.show(AssignModalComponent, {
        initialState: {
          title: 'Edit',
          data: Data,
          type: this.type
      },
      class: 'modal-dialog-centered'
      });
    }
    else{
      this.bsModalRef = this.modalService.show(AssignModalComponent, {
        initialState: {
          title: 'Add',
          data: {},
          type: this.type

      },
      class: 'modal-dialog-centered'
      });
    }
    }
    
  openModal(type) {
    if (this.type === 'countriesListing') {
      this.modalService.show(CommonModalComponent, { initialState: { type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
    }
    else if (this.type === 'currencyListing') {
      this.modalService.show(CommonModalComponent, { initialState: { type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
    }
    else if (this.type === 'timezoneListing') {
      this.modalService.show(CommonModalComponent, { initialState: { type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
    }
  }
  /***************************************************************/
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];
  drop(event: CdkDragDrop<string[]>) {
    console.log(this.colSettingCols);

    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }
  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedUserList.push(element._id);
          }
        } else {
          this.selectedUserList.push(element._id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }

  modifyFilterArr(fieldName, event) {
    const index = this.filter.findIndex((o) =>
      Object.keys(o).includes(fieldName)
    );
    if (index > -1) {
      this.filter[index][fieldName] = event;
    } else {
      this.filter.push({ [fieldName]: event });
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  public filter = [];
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getSatutsFilterlistData(colName) {
    if (colName === 'status') {
      this.statusFilterlist = ['Active', 'Inactive'];
    }
  }

  selectFilterStatus(fieldName, event) {
    const index = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    if (fieldName === 'status') {
      // tslint:disable-next-line: triple-equals
      if (event == 'Active') {
        this.modifyFilterArr(fieldName, [true]);
        // tslint:disable-next-line: triple-equals
      } else if (event == 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
        // tslint:disable-next-line: triple-equals
      } else if (event == '') {
        this.modifyFilterArr(fieldName, []);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }

  getSelectedFilterValueFromTyeAhead(data) {
    const index1 = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index1 > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    const index = this.filter.findIndex((o) => Object.keys(o).includes(data.fieldName));
    if (index > -1) { this.filter[index][data.fieldName] = data.value; } else {
      this.filter.push({ [data.fieldName]: data.value });
    }
  }

  applyFilter() {
    if (this.filter.length) {
      this.disableResetFilter = false;
      this.APIparameters.filter = this.filter;
      this.callApiToGetData();
    }
  }

  resetFilter() {
    this.disableResetFilter = true;
    this.filter = [];
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.APIparameters.filter = [];
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose : SAVE and DELETE FILTER
  /***************************************************************/

  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(saveFilters, { class: 'modal-dialog-centered' });
  }



  public dataOfSaveFilter: any;
  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      this.commonService.callApi(this.getFilter, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.dataOfSaveFilter = success.data;
        }
      });
    }
  }
  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService.callApi(`${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.downloadTemplatesArr = success.data;
        }
      });
    }
  }

  showSaveFilter(i) {
    this.disableResetFilter = false;
    this.APIparameters.filter = i.filter;
    this.callApiToGetData();
  }

  CallAPIdeleteSaveFilter(i) {
    this.disableResetFilter = true;
    this.commonService.callApi(this.deleteFilter + i._id, '', 'delete', false, false).then((success) => {
      if (success.status && success.status === 1) {
        this.showErrorService.popToast('success', success.message);
      } else {
        this.showErrorService.popToast('error', success.message);
      }
      this.callApiToGetData();
    });
  }
  /****************************************************************************
      // To get search list
      /****************************************************************************/
  searchData(event) {
    this.APIparameters.page = 1;
    if (event.target && event.target.value) {
      // if(this.)
      this.APIparameters.searchText = event.target.value;
    }
    else {
      delete this.APIparameters.searchText;
    }
    this.callApiToGetData();

  }
  /***************************************************************/
  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/
  searchValue(data) {
    // console.log(data);
    this.APIparameters.searchText = data;
    this.callApiToGetData();
  }

  // For performing selected action on the list
  actionSelected(action) {
    // DELETE
    if (action === 'Delete') {
      this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
        if (result.value === true) {
          const data = {
            userIds: this.selectedUserList
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
        } else {
          this.deselectMultipleUser();
        }
      });
    }
    // DELETE
    // STATUS
    if (action === 'Inactive' || action === 'Active') {
      let data;
      action === 'Inactive' ?
        data = { userIds: this.selectedUserList, status: false } :
        data = { userIds: this.selectedUserList, status: true };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
      this.deselectMultipleUser();
    }
    // STATUS
  }

  // To get the selected dateType info
  dateSelected(e, index) {
    console.log(e, 'e');

    if (e.name !== 'custom') {
      console.log(e, 'e');
      var date = new Date();
      let presentDate = (e.name === 'Previous Month' || e.name === 'Previous Quarter' || e.name === 'Previous Year') ? 'month/01/year' : date.toLocaleDateString();
      let value = {
        calendarSpecificCount: e.value,
        calendarSpecificType: e.type,
        presentDate: presentDate
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  // when getting startDate and endDate
  rangeSelected(e, index) {
    if (e) {
      let value = {
        startDate: e[0].toLocaleDateString(),
        endDate: e[1].toLocaleDateString()
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  // To clear the filter
  clearFilter() {
    this.filteredArr = [];
    this.addTask();
    this.filterColor = '#4184EF'
    this.callApiToGetData();
    this.filterForm.reset();
    this.EarningForm.reset();
    this.submitted = false;
    this.submitted1 = false;
  }

  typeArrChanges(item) {
    delete (item.selectedDate);
    delete (item.value);
  }

  // To save the filter
  toSaveFilter(form, filterName, color) {
     this.submitted1 = true;
    this.submitted = true;
    if (form && form.valid) {
      this.APIparameters = {
        key: this.APIparameters.columnKey,
        condition: this.condition,
        description: filterName,
        color: color,
        filter: this.filteredArr,
        filterId: this.filterId
      }
      this.commonService.callApi(this.saveFilter, this.APIparameters, 'post').then((success) => {
        if (success.status && success.status === 1) {
          this.APIparameters = {
            page: 1,
            pagesize: 10,
            columnKey: this.APIparameters.columnKey,
            filter: this.filteredArr,
            condition: this.condition
          }
          this.callApiToGetData();
          this.clearFilter();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }

  editFilter(e) {
    console.log(e, 'e');

    this.filteredArr = e.filter;
    this.options.description1 = e.description;
    this.filterColor = e.color;
    this.condition = e.condition;
    this.filterId = e._id;
  }

  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.searchText;
    // setTimeout(() => {
    this.callApiToGetData();
    // });
    }
//*************************************************************/
/// Earning Report and withdraw search filter
//**************************************************************/
submits(form,formsdata){
   
  console.log(formsdata)
  this.APIparameters =[];
    //console.log(event);
  this.EarningList['filterStatus']= formsdata.filterStatus;
  this.EarningList['page']=1;
  this.EarningList['fromDate']= formsdata.fromDate;
  this.EarningList['toDate']=formsdata.toDate;
  console.log(this.EarningList.filterStatus);
  
    //this.EarningLists.filterStatus =event;
    this.APIparameters=this.EarningLists;
    //this.APIparameters.filter.push(filter)
    console.log(this.APIparameters);
    this.callApiToGetData();
   
}
}
