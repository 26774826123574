<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">SUPPORT</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              
                  <li class="breadcrumb-item">
                      <a [routerLink]="" ><i class="bx bx-home-alt"></i></a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Ticket
                  </li>
              </ol>
          </nav>
          <div class="card notification-card">
            
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
              <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                    <div class="text">
                        <h5 class="mb-0 text Uppercase">Question</h5>
                    </div>
              </div>
              <form
              (ngSubmit)="CreateTicketForm.form.valid &&
                      CreateTicket(CreateTicketForm,ticketFormData)"
                       #CreateTicketForm="ngForm" novalidate>
                       <div class="row" >            
                        <div class="col-md-6">
                            <div
                               class="form-group align-items-center mb-md-5 mb-3 user-details"
                              >
                            <label class="mb-0">
                              Subject <sup class="bx bxs-star danger"></sup
                             ></label>
                              <input
                                class="form-control"
                                type="text"
                                name="subject"
                                [(ngModel)]="ticketFormData.subject"
                                id="subject" 
                                #subject="ngModel"                           
                                autocomplete="off" maxlength="30" minlength="3"
                                required
                              />
                              <div *ngIf="((submitted || subject.touched || subject.dirty) && subject.invalid)" class="error-block">
                                <div [hidden]="!subject.errors.required">
                                  subject is required
                                </div>
                                <div [hidden]="!subject.errors.maxlength">
                                    exceed maximum limit
                                </div>
                                <div [hidden]="!subject.errors.pattern">
                                only alphanumeric allow
                                </div>
                                <div [hidden]="!subject.errors.minlength">
                                  Please Enter Atleast 3 Characters
                                </div>
                                <div [hidden]="!subject.errors.maxlength">
                                  Please Enter Atleast 15 Characters
                                </div>
                                
                              </div>  
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div
                               class="form-group align-items-center mb-md-5 mb-3 user-details"
                              >
                            <label class="mb-0">
                              question <sup class="bx bxs-star danger"></sup
                             ></label>
                              <input
                                class="form-control"
                                type="text"
                                name="question"
                                [(ngModel)]="ticketFormData.question"
                                id="question"
                                #question="ngModel"                           
                                autocomplete="off" minlength="3" maxlength="50"
                                required
                              />
                              <div *ngIf="((submitted || question.touched || question.dirty) && question.invalid)" class="error-block">
                                <div [hidden]="!question.errors.required">
                                  Question is required
                                </div>
                                <div [hidden]="!question.errors.pattern">
                                only alphanumeric allow
                                </div>
                                <div [hidden]="!question.errors.minlength">
                                  Please Enter Atleast 3 Characters
                                </div>
                                <div [hidden]="!question.errors.maxlength">
                                  Please Enter Atleast 15 Characters
                                </div>
                                
                              </div>  
                            </div>
                          </div>
                  </div>
                 <div class="text-right mt-md-5 mt-3">
                  <button class="btn btn-primary"[disabled]="!CreateTicketForm" >Save</button>
                  <button type="button" class="btn btn-outline-primary" [routerLink]="[URLConstants.DASHBOARD]">Cancel</button>
                 </div>
              </form>         
        </div>
      </div>
</div>
