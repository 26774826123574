import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { URLConstants } from '../../../common/constants/routerLink-constants';
// service
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [`$(".sub-menu-list ul a").hover(function(e){
    /** Remove selected class first **/
    $(".sub-menu li a").removeClass("selected");
    /** add selected class on hover**/ 
    $(this).addClass("selected");
 });
  `]
})
export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  roles:any;
  uploadImgUrl = environment.uploadImgUrl;
  public subUserMenu: boolean = false;
  public subContentMenu: boolean = false;
  public subSettingsMenu: boolean = false;
  public subBlogMenu: boolean = false;
  public subRoleMenu:boolean= false;
  public subManageMenu:boolean =false;
  public subProductMenu:boolean =false;
  public subTicketMenu:boolean=false;

  constructor(private getPermissionsService: GetPermissionsService,
    private commonService: CommonService,
     public localStorageService: LocalStorageService) {
       this.roles= this.localStorageService.getToken('role');
       console.log(this.roles)
      }


  ngOnInit(): void {
    this.openMenu();
    this.openMenuItem();
    $(".sub-menu li").on("click",function(){

      $(".sub-menu li").removeClass("active");

      $(this).addClass("active");

})
  }

  closeSidebarMenuItem(menuItem) {
    var  sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
  sidebar[0].style.display = 'none';
   if(menuItem != null){
    var elements = Array.from(document.getElementsByClassName('active-link'));
    elements.forEach(element => {
     element.classList.remove('active-link');
    });
   
       var activeElement = document.getElementById(menuItem);
       activeElement.classList.add("active-link");
       this.localStorageService.setToken('menuItem', menuItem);
      }
    }
  openCloseMenuItem(menuItem){

    var elements = Array.from(document.getElementsByClassName('active-link'));
      elements.forEach(element => {
       element.classList.remove('active-link');
      });
     
      var activeElement = document.getElementById(menuItem);
      if(activeElement != null)
      activeElement.classList.add("active-link");
     
 }
  closeSidebar() {
  
  var  sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
  sidebar[0].style.display = 'none';
  }

  openCloseSubMenu(subMenu) {
    var blog = document.getElementById('blog');
    this.localStorageService.setToken('subMenu', subMenu);

    if(subMenu === 'subUserMenu') {
      this.subUserMenu=!this.subUserMenu;
      this.subTicketMenu = this.subProductMenu = this.subContentMenu = this.subSettingsMenu = this.subBlogMenu =this.subRoleMenu= this.subManageMenu=false;
    } else if(subMenu === 'subContentMenu') {
      this.subContentMenu=!this.subContentMenu;
      this.subTicketMenu = this.subProductMenu = this.subUserMenu = this.subSettingsMenu = this.subBlogMenu =this.subRoleMenu= this.subManageMenu=false;
    } else if(subMenu === 'subSettingsMenu') {
      this.subSettingsMenu=!this.subSettingsMenu;
      this.subTicketMenu =this.subProductMenu =this.subProductMenu = this.subContentMenu = this.subUserMenu = this.subBlogMenu =this.subRoleMenu= this.subManageMenu=false;
    } else if(subMenu == 'subRoleMenu') {
      this.subRoleMenu=!this.subRoleMenu;
      this.subTicketMenu = this.subProductMenu = this.subContentMenu = this.subSettingsMenu = this.subUserMenu =this.subBlogMenu= this.subManageMenu=false;
    }
    else if(subMenu === 'subBlogMenu') {
      this.subBlogMenu=!this.subBlogMenu;
      this.subTicketMenu =this.subProductMenu =  this.subContentMenu = this.subSettingsMenu = this.subUserMenu =this.subRoleMenu= this.subManageMenu=false;
    }
    else if(subMenu === 'subManageMenu'){
      this.subManageMenu =!this.subManageMenu;
      this.subTicketMenu = this.subProductMenu = this.subContentMenu = this.subSettingsMenu = this.subUserMenu =this.subRoleMenu= this.subBlogMenu=false;
    }
    else if( subMenu ==='subProductMenu'){
      this.subProductMenu =!this.subProductMenu;
      this.subTicketMenu = this.subManageMenu = this.subContentMenu = this.subSettingsMenu = this.subUserMenu =this.subRoleMenu= this.subBlogMenu=false;
    }else if(subMenu ==='subTicketMenu'){
      this.subTicketMenu =!this.subTicketMenu;
      this.subProductMenu = this.subManageMenu = this.subContentMenu = this.subSettingsMenu = this.subUserMenu =this.subRoleMenu= this.subBlogMenu=false;
    }
    }

    myFunctionOne(){
      this.openMenu();
  
    }
    openMenu() {
    
      var menu = this.localStorageService.getToken('subMenu');
        if((menu != "" && menu != undefined)){
          if((menu == 'subUserMenu' && !this.subUserMenu) || 
          (menu == 'subContentMenu' && !this.subContentMenu) ||
          (menu == 'subSettingsMenu' && !this.subSettingsMenu) ||
          (menu == 'subBlogMenu' && !this.subBlogMenu)  ){
            this.openCloseSubMenu(menu);
          }
        
        }
    } 
  openMenuItem() {
    var item = this.localStorageService.getToken('menuItem');
    this.openCloseMenuItem(item);

  }
}
