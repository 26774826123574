
<!-- BEGIN : Show Skeleton Loader -->

  <!-- END : Show Skeleton Loader -->
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-2">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
                <div class="col-md-2">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
            <ngx-skeleton-loader count="1" [theme]="{ 'padding': '80px' }"></ngx-skeleton-loader>
        </div>

    </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="main-content-area">
    <div class="main-content-block">
        <h3 class="content-header-title">Roles & Permissions</h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
            
                <li class="breadcrumb-item"><a href="dashboard.html"><i class="bx bx-home-alt"></i></a></li>
                <li class="breadcrumb-item">Roles & Permissions</li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </nav>
        <form #form="ngForm" (ngSubmit)="createUpdateRole(form)">
            <div class="card role-card pb-0 mb-1">
                <div class="form-group mb-md-4 mb-3">
                    <label for="role">Name<span class="error" >*</span></label>
                    <input class="form-control" type="text" id="role" name="role" placeholder="Enter Role" [(ngModel)]="addOrEditRole.role" #roleName="ngModel" required autocomplete="off" />
                    <div class="error-block text-left" style="color:red" *ngIf="((submitted || roleName.touched) && roleName.invalid)">
                        {{showErrorService.showError('roleName', roleName.errors)}}
                    </div>
                </div>
                <!-- <div class="form-group mb-0">
                    <label for="Description">Description<span style="color:red">*</span></label>
                    <textarea rows="5" class="form-control" type="text" placeholder="Enter Description" name="description" [(ngModel)]="role.description" #description="ngModel" autocomplete="off" required></textarea>
                    <div class="error-block text-left" style="color:red"
                        *ngIf="((submitted || description.touched) && description.invalid)">
                        {{showErrorService.showError('description', description.errors)}}
                    </div>
                </div> -->
            </div>
            <div class="card role-card p-0">
                <div class="table-responsive">
                    <table class="role-table w-100">
                        <!-- <thead>
                            <tr>
                                <th>Module Permission</th>
                                <th *ngFor="let role of roleNames">
                                    <div class="custom-checkbox">
                                        <label [for]="role">
                                            <input type="checkbox" [name]="role" [id]="role" [checked]="roleValues[role]" (change)="multipleSelection(role,$event.target.checked)" autocomplete="off">
                                            <span></span>{{ role }}
                                        </label>
                                    </div>
                                </th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <!-- <tr *ngFor="let category of categoryNames | keyvalue;let i=index ">
                                <td>{{ category.value }}</td>
                                <td *ngFor="let item of roleNames">
                                    <div class="custom-checkbox" *ngIf="item.key !== category">
                                        <label [for]="category.key+'_'+item">
                                             <input *ngIf="!rolePermissions.length" type="checkbox" [name]="category.key+'_'+item" [id]="category.key+'_'+item" (change)="selectRole(category.key,item,$event.target.checked)" autocomplete="off"> 
                                            <input type="checkbox" [name]="category.key+'_'+item" [id]="category.key+'_'+item" [checked]="rolePermissions.length && rolePermissions[i] && rolePermissions[i]['category'] == category.key && rolePermissions[i][item] == true"  (change)="selectRole(category.key,item,$event.target.checked)" autocomplete="off">
                                            <span></span>
                                        </label>
                                    </div>
                                </td> 
                            </tr> -->

                            <!-- <tr>
                                <td>Content</td>
                                <td *ngFor="let item of roleNames">
                                    <div class="custom-checkbox">
                                        <label [for]="item">
                                            <input type="checkbox" [name]="item" [id]="item" [(ngModel)]="j.isSelected"
                                            (change)="role.categoryPermissions[outerindex].isSelected = false" autocomplete="off">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-0">
                                <td>Emails Templates</td>
                                <td *ngFor="let item of roleNames">
                                    <div class="custom-checkbox">
                                        <label [for]="item">
                                            <input type="checkbox" [name]="item" [id]="item" [(ngModel)]="j.isSelected"
                                            (change)="role.categoryPermissions[outerindex].isSelected = false" autocomplete="off">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                            </tr> -->
                            <!-- <tr *ngFor="let item of role.categoryPermissions;let i=index; let last=last">
                                <td [ngClass]="last ? 'border-0' : ''">{{ item.category }}</td>
                                <td *ngFor="let data of item.permissions; let j=index ">
                                    <div class="custom-checkbox">
                                        <label [for]="i+'isSelected'+j">
                                            <input type="checkbox" [id]="i+'isSelected'+j" [name]="i+'isSelected'+j" [(ngModel)]="data.isSelected" (change)="role.categoryPermissions[i].isSelected=false" />
                                            <span></span>{{ data.permission }}
                                        </label>
                                    </div>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card profile-card role-card p-0">
                <div class="table-responsive">              
                    <table class="role-table w-100 m-4">
                        <thead>
                            <th>Module Permission</th>
                            <th *ngFor="let cat of tableData">
                                <div class="custom-checkbox">
                                    <label [for]="cat">
                                        <input type="checkbox" [name]="cat"
                                         [id]="cat" [checked]=""
                                         (change)="multipleSelection(cat,$event.target.checked)"
                                          autocomplete="off">
                                        <span></span>{{ role }}
                                    </label>
                                </div>
                            </th>
                        </thead>
                            <tr *ngFor="let cat of tableData ">
                                <td class="pt-2">
                                    {{cat.category}}
                                </td>
                                <td *ngFor="let per of cat.permissions" class="pt-2"> 
                               
                                    <div class="custom-checkbox">
                                        <label>
                                            <!-- <input *ngIf="!rolePermissions.length" type="checkbox" [name]="category.key+'_'+item" [id]="category.key+'_'+item" (change)="selectRole(category.key,item,$event.target.checked)" autocomplete="off"> -->
                                            <input type="checkbox" [name]="per.permission" [id]="per._id" 
                                            (change)="selectRole(per._id,cat.categoryId,$event.target.checked)" 

                                            [checked]="per.isSelected"
                                            autocomplete="off">
                                            <span></span>
                                            {{per.permission}} 
                                        </label>
                                    </div>
                                </td>
                            </tr>
                    </table>
                </div>
            </div>
           
            <div class="text-right submit-btn-wrap" >
                <button class="btn btn-primary submit-btn mr-2">Save</button>
                <button type="button" class="btn btn-outline-primary submit-line-btn" [routerLink]="[URLConstants.CMS]">Cancel</button>
       
            </div> 
        </form>
    </div>
</div>

