<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <i class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></i>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="customSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>
<!--Start Login Wrapper-->
<div class="login-wrapper" style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-4">
                                        <h4 class="mb-2 mt-2">Sign Up</h4>
                                    </div>
                                    <div class="text-center">
                                        <p class="common-small-text"><small>Please enter your details to sign up and be part of our great community</small></p>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <form (ngSubmit)="onSignup(signupForm, user)" #signupForm="ngForm">
                                            <div class="row">
                                                <div class="form-group col-md-12">
                                                    <label>First Name<sup class="bx bxs-star danger"></sup></label>
                                                    <input [(ngModel)]="user.firstName" minlength="3" (keypress)="keyPressNumbers($event)" type="text" name="firstName" appAlphabetOnly class="form-control" placeholder="First Name" #firstName="ngModel" [ngClass]="{ 'is-invalid': (submitted || firstName.touched) && firstName.invalid }"
                                                        required>
                                                    <div *ngIf="(submitted || firstName.touched) && firstName.invalid" class="invalid-feedback">
                                                        <div *ngIf="firstName.errors.required">Please enter first name</div>
                                                        <div *ngIf="firstName.errors.minlength">Please enter atleast 3 alphabets</div>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="form-group mb-2">
                                                <label class="mb-0">Phone Number</label>
                                                <div class="">
                                                    <ngx-intl-tel-input [(ngModel)]="user.number"
                                                     maxLength="10"
                                                      type="number"
                                                       #number="ngModel" 
                                                       name="number" [preferredCountries]="preferredCountries" 
                                                       [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                                        [searchCountryFlag]="true" [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.India"  [phoneValidation]="false" [separateDialCode]="separateDialCode"></ngx-intl-tel-input>
                                                </div>
                                                <div *ngIf="submitted && errorMsg!=''" class="invalid-feedback" style="display: block;">
                                                    <div>{{errorMsg}}</div>
                                                    <!-- <div *ngIf="number.errors != null && number.errors.pattern">Please enter 10 digits phone number</div> -->
                                                </div>

                                            </div>
                                            <div class="form-group mb-2">
                                                <label>Email address<sup class="bx bxs-star danger"></sup></label>
                                                <input [(ngModel)]="user.email" type="text"
                                                 name="email" id="EmailAddress" 
                                                 aria-describedby="emailHelp"
                                                  placeholder="Enter email" class="form-control" 
                                                  #email="ngModel"
                                                   [ngClass]="{ 'is-invalid': (submitted || email.touched) && email.invalid }" required
                                                    email>
                                                <div *ngIf="(submitted || email.touched) && email.invalid" class="invalid-feedback">
                                                    <div *ngIf="email.errors.required">Please enter email</div>
                                                    <div *ngIf="email.errors.email">Please enter valid email</div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-4 position-relative" [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
                                                <label>Password<sup class="bx bxs-star danger"></sup></label>
                                                <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="user.password" id="password-field" type="password" name="password" class="form-control" #password="ngModel" value="secret" placeholder="Password" [ngClass]="{ 'is-invalid': (submitted || password.touched) && password.invalid }"
                                                    required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                                <span [tooltip]="fieldTextType ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                    'bx-bx-hide': !fieldTextType,
                                                    'bx-show': fieldTextType
                                                  }" (click)="toggleFieldTextType()"></span>

                                                <div *ngIf="(submitted || password.touched) && password.invalid" class="invalid-feedback">
                                                    <div *ngIf="password.errors.required">Please enter password</div>
                                                    <div *ngIf="password.errors.pattern">Password should contain atleast a special character (@, $, !, %, *, ?, & or #), a capital letter and a number and length should be between 6-20</div>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary glow position-relative btn-block">
                                                Sign Up<i class="icon-arrow bx bx-right-arrow-alt"></i>
                                            </button>
                                        </form>
                                        <div class="text-center common-small-text mt-3 mb-4"><small>Already have an account? <a routerLink="/login">Sign in</a></small></div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/register.webp" type="image/webp">
                                    <source srcset="assets/images/register.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/register.png" alt="sign up">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!--End Login Wrapper-->