import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs-category',
  templateUrl: './faqs-category.component.html',
  styleUrls: ['./faqs-category.component.scss']
})
export class FaqsCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
