import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// reusable
import { SharedModule } from 'src/app/shared/shared.module';

// components
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { ListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full',
  },
  {
    path: 'list', component: ListComponent, pathMatch: 'full',
  },
  {
    path: 'edit-details/:id',
    component: EditDetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'add-role',
    component: EditDetailsComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class ManageRolesRoutingModule { }
