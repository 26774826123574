import { Component, OnInit } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import {defaultPermissionConstants} from '../../common/constants/categoryType-constants'

// service
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  accessPermission: any;

  constructor(public commonService: CommonService, private showErrorService: ShowErrorService) { }

  ngOnInit() {
    // START: Role Permission //
    this.accessPermission = defaultPermissionConstants
    // END: Role Permission //
    this.setBreadcrumbs();
    this.getNotifications();
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Notifications', url: null },
    ];
    this.subHeaderData = {
      title: 'Notifications',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
    @Purpose     : Datatable config settings
    @Parameter   : NA
    @Return      : Datatable config
    @optional    : In tempData
                    sort: Boolean ( To enable Sort for that Column )
                isVisible: Boolean ( Show that Column Statically )
                columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  getNotifications() {
    const tempData = [
      { type: 'text', colName: 'Notifications', colFieldname: 'createdAt',mixColumns:['createdAt','notification'], sort: true, isVisible: true },
    ];
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'notificationList';
    this.tableSetupData.actions = [];
    this.tableSetupData.params = { };
    this.tableSetupData.conditions = { showTableFooter: true };
  }
  /*************************************************************/
}
