import { SharedModule } from './../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './faq.component';
import { ListComponent } from './list/list.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { VendorlistComponent } from './vendorlist/vendorlist.component';


@NgModule({
  declarations: [FaqComponent, ListComponent, EditDetailsComponent, EditCategoryComponent, VendorlistComponent],
  imports: [
    CommonModule,
    FaqRoutingModule,SharedModule
  ]
})
export class FaqModule { }
