import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// interfaces
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';
import { FaqsCategoryComponent } from 'src/app/shared/components/modals/faqs-category/faqs-category.component';
import { FaqsListCategoryComponent } from 'src/app/shared/components/modals/faqs-list-category/faqs-list-category.component';

// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { GetPermissionsService } from '../../../../common/service/get-permissions.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  tableSetupData: any = {};
  URLConstants = URLConstants;
  public accessPermission: any;
  tabs: any = {};

  constructor(private commonService: CommonService,public getPermissionsService: GetPermissionsService, public bsModalRef: BsModalRef, private modalService: BsModalService,public faqBsModalRef:BsModalRef) {
    this.setBreadcrumbs();
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('cms');
    // END: Role Permission //
  }

  ngOnInit() {
    this.tabs.faqList = true;
    this.setDTableInitialData();
  }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'Content', url: null },
      { localeKey: 'Static Page', url: null },
    ];
    this.subHeaderData = {
      title: 'Content',
      breadcrumbs: this.breadcrumbs,
    };
  }

  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/

  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true, columnVisibility: true, },
      { type: 'text', colName: 'Category', colFieldname: 'faqCategoryName', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'text', colName: 'Question', colFieldname: 'faqQuestion', sort: true, columnVisibility: true, isVisible: true, filter: true },
      { type: 'text', colName: 'Answer', colFieldname: 'faqAnswer', sort: true, columnVisibility: true, isVisible: true, filter: true },

      { type: 'switch', colName: 'Status', colFieldname: 'status', columnVisibility: true, isVisible: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },

    ];
    if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'faqsListing';
    this.tableSetupData.saveColumnKey = 'faqsListing';
    this.tableSetupData.actions = [
      // {
      //   id: 1, buttonTitle: 'view', tooltip: 'View', class: 'bx bx-show-alt',
      //   type: 'icon'
      // },
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'text-primary bx bx-edit',
        type: 'icon',permission: this.accessPermission.edit
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class: 'text-danger bx bx-trash-alt',
        type: 'icon',permission: this.accessPermission.delete
      },

    ];
    this.tableSetupData.params = { deleteParams: 'faqsIds', statusParams: 'faqsIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      model: true,
      showButton: { routerLink: this.URLConstants.ADD_FAQ, buttonName: 'Add Faq' },
    };
  }
  /*********************************************************************************************
 @Purpose     : Datatable config settings
 @Parameter   : NA
 @Return      : Datatable config
 @optional    : In tempData
                 sort: Boolean ( To enable Sort for that Column )
                 isVisible: Boolean ( Show that Column Statically )
                 columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                 filter: Boolean ( To enable Filter for that Column )
 /*********************************************************************************************/

  setDTableInitialData1() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true, columnVisibility: true, },
      { type: 'text', colName: 'Category', colFieldname: 'faqCategoryName', sort: true, columnVisibility: true, isVisible: true, filter: true },

      { type: 'switch', colName: 'Status', colFieldname: 'status', columnVisibility: true, isVisible: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },

    ];
    if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type = 'faqsCategoryListing';
    this.tableSetupData.saveColumnKey = 'faqsCategoryListing';
    this.tableSetupData.actions = [
      // {
      //   id: 1, buttonTitle: 'view', tooltip: 'View', class: 'bx bx-show-alt',
      //   type: 'icon'
      // },
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'text-primary bx bx-edit',
        type: 'icon',
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class: 'text-danger bx bx-trash-alt',
        type: 'icon',permission: this.accessPermission.delete
      },

    ];
    this.tableSetupData.params = { deleteParams: 'faqCategoriesIds', statusParams: 'faqCategoriesIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      model: true,
      showButton: { routerLink: this.URLConstants.ADD_CATEGORY, buttonName: 'Add FaqCategory' },
    };
  }
  /*********************************************************************************************/



  /*************************************************************
 @Purpose :To Select Faq List And FaqCategory Tabs.
 /*************************************************************/
  onSelect(event) {
    if (event == 'FaqList') {
      this.tabs.faqList = true;
      this.tabs.faqCategory = false;
      this.setDTableInitialData();
    }
    else if (event == 'FaqCategory') {
      this.tabs.faqList = false;
      this.tabs.faqCategory = true;
      this.setDTableInitialData1();
    }
  }
  /***************************************************************** */

}
