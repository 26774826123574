<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title"></h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              
                  <li class="breadcrumb-item">
                      <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                  </li>
                  <li class="breadcrumb-item">Cuisine</li>
  
                  <li class="breadcrumb-item active" aria-current="page">
                     View 
                  </li>
              </ol>
          </nav>
          <div class="card mb-3" style="max-width: 740px;">
            <div class="ml-2 pl-2"style="font-size: 25px;"> <b>View Details</b></div>
            <div class="row">
              <div class="col-md-4">
                <img [src]="cmsDetail.image?cmsDetail.image:'../../../assets/images/default-upload.png'" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                <div>
         
                    <p class="card-text"style="font-size: 17px;">Name:&nbsp;{{cmsDetail.name|uppercase}}</p> 
                    <p class="card-text"style="font-size: 17px;">Created On: &nbsp; {{cmsDetail.createdAt |date}}</p>
                </div>
                  <p class="card-text" ></p>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
          </div>
        
      </div>
  </div>