import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// inteface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants

import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;

  constructor(private getPermissionsService: GetPermissionsService,
     private commonService: CommonService) {
    this.setBreadcrumbs();
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('adminUser');
    // END: Role Permission //
    this.setBreadcrumbs();
    this.setDTableInitialData();
  }

  ngOnInit() { this.setDTableInitialData(); }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Ticket', url:null},
    
    ];
    this.subHeaderData = {
      title: 'Ticket Management',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                   sort: Boolean ( To enable Sort for that Column )
                   isVisible: Boolean ( Show that Column Statically )
                   columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                   filter: Boolean ( To enable Filter for that Column )
 /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },
      { type: 'text', colName: 'Answer', colFieldname: 'answer', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Question', colFieldname: 'question', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'switch', colName: 'Status', colFieldname: 'status', isVisible: true, columnVisibility: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='adminTicketlist';
    this.tableSetupData.actions = [
      {
        id: 2, buttonTitle: 'Answer', tooltip: 'Answer', class: 'bx bx-show-alt text-primary',
        type: 'icon', 
      }
    ];
     
    console.log(this.URLConstants.ADD_COUPAN)  
    this.tableSetupData.params = { deleteParams:'_id',statusParams:'_id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,       
    };
  }
}
