import { callAPIConstants } from './../../../../common/constants/callAPI-constants';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// interfaces
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';
import { FaqsCategoryComponent } from 'src/app/shared/components/modals/faqs-category/faqs-category.component';
import { FaqsListCategoryComponent } from 'src/app/shared/components/modals/faqs-list-category/faqs-list-category.component';

// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { GetPermissionsService } from '../../../../common/service/get-permissions.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { SELECT_VALUE_ACCESSOR } from 'ng-select';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public submitted = false;
  sdk: boolean;
  link: boolean;
  categorylist: any;
  CategoryId:any;
  ngOnInit(): void {
    
  }
  constructor(private commonService: CommonService, 
    private router: Router, 
    private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService, 
    public bsModalRef: BsModalRef,private activatedRoute: ActivatedRoute,
     private modalService: BsModalService) { 
      this.activatedRoute.params.subscribe((params) => { this.CategoryId = params.id; });
      (this.CategoryId === undefined)? null :this.getFaqsCategory();
  }
  saveCategoryFaqsFormData={
      faqCategoryName: "",
      faqCategoryId: ""
    }
  getFaqsCategory(){   
  this.commonService.callApi(this.callAPIConstants.FaqsCategoryDetails+this.CategoryId,'','get', false, false, false).then((success) => {
    if (success.status === 1) {
      this.saveCategoryFaqsFormData.faqCategoryId = success.data._id
      this.saveCategoryFaqsFormData.faqCategoryName = success.data.faqCategoryName;
      //this.showErrorService.popToast('success', success.message);
    } 
  }); 
  }
    saveFaqsCategory(form, formData): void {
      this.saveCategoryFaqsFormData={
        faqCategoryName: formData.faqCategoryName,
        faqCategoryId: formData.faqCategoryId
      }
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.AddUpdateFaqsCategory,this.saveCategoryFaqsFormData, 'post', false, false).then((resposnseData)=>{
        if (resposnseData.status === 1) {
          this.showErrorService.popToast('success', 'Updated Successfully!');
        } else { this.showErrorService.popToast('error', resposnseData.message); }
      });
    }
  }
  
}
