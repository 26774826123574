<div class="main-content-area">
    <div class="main-content-block">
      <h3 class="content-header-title">Vendor Faq</h3>
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
           
              <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item">List</li>

             
          </ol>
      </nav>
        <!-- filter bar buttons end -->
        <div class="m-content animated fadeIn">
            <!-- BEGIN: Responsive Data Table  -->
            <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
            </app-responsive-data-table>
            <!-- END: Responsive Data Table  -->
        </div>
    </div>
</div>