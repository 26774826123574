import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// reusable
import { SharedModule } from '../../shared/shared.module';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { ListComponent } from './list/list.component';

// routing
import { ManageRolesRoutingModule } from './manage-roles-routing.module';

@NgModule({
  declarations: [ListComponent,EditDetailsComponent],
  imports: [
    SharedModule,
    CommonModule,
    ManageRolesRoutingModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ManageRolesModule { }
