<div class="main-content-area">
      <div class="main-content-block">
<app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>
          <!-- filter bar buttons end -->
          <div class="m-content animated fadeIn">
              <!-- BEGIN: Responsive Data Table  -->
              <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
              </app-responsive-data-table>
              <!-- END: Responsive Data Table  -->
          </div>
      </div>
  </div>