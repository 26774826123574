<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <div class="card-title">
                <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-xl-2 col-sm-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-4">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card animated fadeIn">
        <div class="card-header text-left">
            <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="form-group row">
                    <div class="col-lg-2 col-sm-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xl-2 col-lg-3">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-10 co-lg-12">
                        <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-2">
                        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">Meal deal</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                 
                  <li class="breadcrumb-item">
                      <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                  </li>
                  <li class="breadcrumb-item">View</li>
  
                  <li class="breadcrumb-item active" aria-current="page">
                     
                  </li>
              </ol>
          </nav>
          <div class="card profile-card">
            <div class="user-detail-wrap">
            <div class="user-detail-img-wrap">
                <figure>
                    <img [src]="objectImage?objectImage:'../../../../assets/images/default-upload.png'" [ngStyle]="{'font-size.px':24,'height.px':200,'width.px':200}" alt="no img" />
                </figure>
            </div>
            <div class="user-detail-text-wrap">
                <div class="row">
                    <div class="col-md-6">
                        <div class="user-details">
                            <label>
               Create : </label>
                            <span>{{ (Detail?.createdAt) ? (Detail.createdAt|date)  : ' -- ' }}</span>

                        </div>
                        <div class="user-details">
                            <label>
                  Name :
                    </label>
                            <span>{{ (Detail?.name) ? (Detail.name) : '--'}}</span>

                        </div>
                        <div class="user-details">
                            <label>
                      Status :
                        </label>
                        <div class="m-4 custom-control col-md-3 custom-switch">
                          <input type="checkbox" 
                          class="custom-control-input" 
                          [(ngModel)]="Detail.status" [disabled]="true"
                          name= "status" #status="ngModel"
                          id="customSwitch2">
                          <label class="custom-control-label" for="customSwitch2"> </label>
                        </div>
                            
      
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="user-details">
                            <label>
                                Discount%:
                              </label>
                            <span>{{ (Detail?.discountPercentage) ? (Detail.discountPercentage ) : '--'}}</span>

                        </div>
                        <div class="user-details">
                            <label>
                                price:
                                    </label>
                            <span>{{ (Detail?.price|json) }}</span>

                        </div>
                        <div class="user-details">
                            <label>
                                description :
                                    </label>
                            <span>{{ (Detail?.description) ? Detail.description : '--'}}
                                    </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
         
      </div>
  </div>