// environment
import { environment } from 'src/environments/environment';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interface
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { defaultPermissionConstants } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {
  showSkeletonLoader1: boolean = true;
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  uploadImageUrl = environment.uploadImgUrl;
  public CmsId: any;
  objectImage:any = {};
  imageToShow: any;
  constructor(public commonService: CommonService,
    private sanitizer: DomSanitizer,public getPermissionsService: GetPermissionsService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => { this.CmsId = params.id; });
    console.log(this.CmsId)
    this.setBreadcrumbs();
    this.getPageDetails();

  }

  ngOnInit() {
     this.createImageFromBlob(this.objectImage)
   }
  
   createImageFromBlob(image: Blob) {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
         this.imageToShow = reader.result;
      }, false);
   
      if (image) {
         reader.readAsDataURL(image);
      }
   }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'CMS Management', url: this.URLConstants.CMS },
      { localeKey: 'Static Pages', url: this.URLConstants.CMS },
      { localeKey: 'View Details', url: null },
    ];
    this.subHeaderData = {
      title: 'View Details',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Get Details
  @Parameter   : NA
  @Return      : Detail
  /*************************************************************/
  
  public Detail: any = {};
  getPageDetails() {
    this.commonService.callApi(this.callAPIConstants.Category_Details + this.CmsId, '', 'get', false, false).then((success) => {
      if (success.status === 1) { 
        this.Detail = success.data;
         this.objectImage = this.uploadImageUrl+ success.data.image;
      }
    });
  }
  /*************************************************************/
}
