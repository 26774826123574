import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';

// interfaces
import { IBreadcrumbs, ITableSetupData } from '../../../common/interfaces/interfaces';

// constants
// import { defaultPermissionConstants } from '../../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../common/service/common.service';
import { GetPermissionsService } from '../../../common/service/get-permissions.service';
import{ResponsiveDataTableComponent} from '../../../shared/components/responsive-data-table/responsive-data-table.component'
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  public accessPermission: any;

  constructor(private getPermissionsService: GetPermissionsService, private commonService: CommonService) {
    this.setBreadcrumbs();
    // START: Role Permission //
    // this.accessPermission = defaultPermissionConstants;
    // END: Role Permission //
  }

  ngOnInit() { this.setDTableInitialData(); }

  ngDoCheck() {
    if (this.responsiveDataTable) { this.responsiveDataTable.showSkeletonLoader = this.commonService.showSkeletonLoader; }
  }

  /*************************************************************
  @Purpose     : Show Subheader with Breadcrumbs
  @Parameter   : NA
  @Return      : Breadcrumbs
  /*************************************************************/
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'home', url: this.URLConstants.DASHBOARD },
      { localeKey: 'Add', url: null},
   
    ];
    this.subHeaderData = {
      title: 'Product',
      breadcrumbs: this.breadcrumbs,
    };
  }
  /*************************************************************/

  /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [ 
      { type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },
      { type: 'text', colName: 'Name', colFieldname: 'name', isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'FoodType', colFieldname: 'foodType' ,isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Price', colFieldname: 'price', isNumber:true,isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Discount', colFieldname: 'discountPercentage',isNumber:true, isVisible: true, sort: true, columnVisibility: true, filter: true },
      { type: 'text', colName: 'Calories', colFieldname: 'calories', isVisible: true, isNumber:true,sort: true, columnVisibility: true, filter: true },
      { type: 'switch', colName: 'Status', colFieldname: 'status', isVisible: true, columnVisibility: true, filter: true },
      { type: 'action', colName: 'Action', colFieldname: '', isVisible: true },
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    console.log(this.URLConstants.ADD_ATTRIBUTE);
    this.tableSetupData.type ='productlist';
    this.tableSetupData.actions = [
      {
        id: 1, buttonTitle: 'view', tooltip: 'View', class: 'bx bx-show-alt',
        type: 'icon',
      },
      {
        id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit',
        type: 'icon',
      },
      {
        id: 3, buttonTitle: 'delete', tooltip: 'Delete', class: 'bx bx-trash-alt',
        type: 'icon',
      },
    ];
    this.tableSetupData.params = { deleteParams: '_id', statusParams: '_id' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: true,
      showButton: { routerLink: this.URLConstants.ADD_PRODUCT, buttonName: 'Add' },
    };
  }
  /*********************************************************************************************/


}
