import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';

import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
// routing
import {AppRoutingModule} from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// Common
import { CommonService } from './common/service/common.service';
import {CanAuthActivate, CanLoginActivate } from './common/service/auth-guard';
import { ErrorMessages } from './common/service/errorMessages';
 import { AgmCoreModule } from '@agm/core';

// plugins
// import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {OwlNativeDateTimeModule } from 'ng-pick-datetime';

// component
import { AppComponent } from './app.component';
@NgModule({
  bootstrap: [AppComponent],
  
  declarations: [
    AppComponent,   
  ],

  imports: [
    SharedModule,
    FormsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCc7PVGmsadb2ZoiaROparpua5ARrCjSZE',
      libraries:['places']
    })
    // OwlDateTimeModule, 
    // OwlNativeDateTimeModule,
  ],
  
  exports: [],
  providers: [
    CanLoginActivate,
    CommonService,
    CanAuthActivate,
    BsModalRef,
    // BsDropdownDirective,
    ErrorMessages
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
]
})
export class AppModule { }


