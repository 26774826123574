import { Component, HostListener, OnInit, TemplateRef  } from '@angular/core';

// services
import { LogoutService } from 'src/app/common/service/logout.service';
import {TranslateService} from '@ngx-translate/core';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import { CommonService } from '../../../common/service/common.service';
// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { ThemeService } from '../../../common/service/theme/theme.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public dropdown:boolean = false;
  public firstname: any;
  public lastname: any;
  public photo: any;
  public theme: string = null;
  public alignment: string = null;
  public status: boolean = false;
  passwordTypeOld: boolean;
  passwordTypeNew: boolean;
  public passdata: any = {};
  public submitted = false;
  modalRef: BsModalRef;
  fieldTextType: boolean;
  public notification: number = 0;
   
  constructor(public localStorageService: LocalStorageService,private themeService: ThemeService, 
    private logoutService: LogoutService, private showErrorService: ShowErrorService,
    private router: Router, public translate: TranslateService, private commonService: CommonService,
    private modalService: BsModalService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
   }

   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ngOnInit(): void {  
    if(this.localStorageService.getToken('role')==='vendor'){
      this.getcount();
    }
      var isDark=this.localStorageService.getToken('theme')=="Dark"?true:false;
      var isHorizontal=this.localStorageService.getToken('menuAlignment')=="Horizontal"?true:false;  
      this.themeService.switchThemeAfterLogin(isDark);
      if(isDark){
       document.getElementById("customSwitchHeaderTheme").setAttribute('checked','');
       }
        this.themeService.switchAlignmentAfterLogin(isHorizontal);
      if(isHorizontal){
         document.getElementById("sidebarSwitchBtn").setAttribute('checked','');
      }
  }

  @HostListener('window:scroll', [])
onWindowScroll(event: Event) {
    //set up the div "id=nav"
    if (document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0) {
        document.getElementById('header').classList.add('sticky');
    }
    else {
        document.getElementById('header').classList.remove('sticky');
       // this.innerWidth = 'auto';
    }
}

   /*************************************************************
  @Purpose     : Call API to Change Password
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  public showErr = false;
  changePassword(passwordForm) {
    this.submitted = true;
    if (passwordForm.valid) {
      const data = {
        oldPassword: passwordForm.value.oldPassword,
        newPassword: passwordForm.value.newPassword,
      };
      
        this.showErr = false;
        if(this.localStorageService.getToken('role') ==='superAdmin'){
          this.commonService.callApi(this.callAPIConstants.AdminChangePassword, data, 'post', false, false).then((success) => {
            if (success.status === 1) {
              this.modalRef.hide();
              this.showErrorService.popToast('success', success.message);
              this.router.navigate([this.URLConstants.DASHBOARD]);
            } else {
              this.modalRef.hide();
              this.showErrorService.popToast('error', success.message);
            }
          });
        }else if(this.localStorageService.getToken('role') ==='vendor'){
          this.commonService.callApi(this.callAPIConstants.UserChangePassword, data, 'post', false, false).then((success) => {
            if (success.status === 1) {
              this.modalRef.hide();
              this.showErrorService.popToast('success', success.message);
              this.router.navigate([this.URLConstants.DASHBOARD]);
            } else {
              this.modalRef.hide();
              this.showErrorService.popToast('error', success.message);
            }
          });
        }
       
      
    }
  }
  /*************************************************************/

  toggleFieldTextTypeOld() {
    this.passwordTypeOld = !this.passwordTypeOld;
    }

    toggleFieldTextTypeNew() {
      this.passwordTypeNew = !this.passwordTypeNew;
      }
  logout() {
    this.logoutService.logout();
  }

  showHideDropdown() {
     this.dropdown =! this.dropdown;
  }

  clickEvent() {
    this.status = !this.status;       
    }

    openSidebar() {
    //  document.getElementById('sidebar').setAttribute('class','sidebar-main');
      //  document.getElementById('sidebar').setAttribute('class','sidebar-close');
     
        // var  sidebar = document.getElementsByClassName('sidebar');
        // sidebar[0].style.display = 'none';
        // }
        var  sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
        sidebar[0].style.display = 'block';
        
}
  ngDoCheck(): void {
    this.firstname = this.localStorageService.getToken('firstname');
    this.lastname = this.localStorageService.getToken('lastname');
    // this.photo = this.localStorageService.getToken('photo');
    this.photo = this.getProfile();
    this.notification = parseInt(this.localStorageService.getToken('notification'));
  }
  /*************************************************************
	 @Purpose     : Get Profile Image
	 @Parameter   : NA
	 @Return      : url
	/*************************************************************/
	getProfile() {
    const url = this.localStorageService.getToken('photo');
		if (url == null || url === ' ' || !url) {
			return 'assets/images/NoProfile.png';
		} else {
			return url;
		}
	}
  /****************************************************************************/
  /**********************************************************************/
  public count:any=0;
  getcount(){
    this.commonService.callApi(this.callAPIConstants.getNotificationCount,
      '', 'get', false, false).then((success) => {
       console.log(success.data);
        this.count=success.data;
 
   });
  }
  /******************************************************************** */
  // toggle light/dark theme
  toggleTheme() {
    
    this.theme= this.themeService.switchTheme();
    this.alignment = this.localStorageService.getToken('menuAlignment') != null ? this.localStorageService.getToken('menuAlignment') : null;

    var obj = {
      "theme": this.theme,
      "menuAlignment": this.alignment
    }
    if(this.localStorageService.getToken('role')==='superAdmin'){
      this.commonService.callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false).then((success) => {
        if (success.status === 1) {
        //  this.showErrorService.popToast('success', success.message);
          this.localStorageService.setToken('theme', success.data.theme);
          this.localStorageService.setToken('menuAlignment',success.data.menuAlignment);
        } else {
          
           this.showErrorService.popToast('error', success.message);
           }
      });
    }else{
      console.log('update them');
      
    }
    

  }

  // toggle Alignment
  toggleAlignment() {
    this.alignment= this.themeService.switchAlignment();
    this.theme = this.localStorageService.getToken('theme') != null ? this.localStorageService.getToken('theme') : null;
    var obj = {
      "theme": this.theme,
      "menuAlignment": this.alignment
    }
    this.commonService.callApi(this.callAPIConstants.UpdateTheme, obj, 'post', false, false).then((success) => {
      if (success.status === 1) {
    //    this.showErrorService.popToast('success', success.message);
        this.localStorageService.setToken('theme', success.data.theme);
        this.localStorageService.setToken('menuAlignment',success.data.menuAlignment);

      } else {
         this.showErrorService.popToast('error', success.message);
         }
    });
  }
}

