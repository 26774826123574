import { callAPIConstants } from './../../../../common/constants/callAPI-constants';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// interfaces
import { IBreadcrumbs, ITableSetupData } from 'src/app/common/interfaces/interfaces';
import { FaqsCategoryComponent } from 'src/app/shared/components/modals/faqs-category/faqs-category.component';
import { FaqsListCategoryComponent } from 'src/app/shared/components/modals/faqs-list-category/faqs-list-category.component';

// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../../../common/service/common.service';
import { GetPermissionsService } from '../../../../common/service/get-permissions.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShowErrorService } from 'src/app/common/service/show-error.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {

  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public submitted = false;
  sdk: boolean;
  link: boolean;
  faqId: any;
 
  ngOnInit(): void {
    
  }
  constructor(private commonService: CommonService, private router: Router, private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService, private activatedRoute:ActivatedRoute,
    public bsModalRef: BsModalRef, private modalService: BsModalService) { 
      this.activatedRoute.params.subscribe((params) => { this.faqId = params.id; });
      (this.faqId === undefined)? null :this.getFaqsCategory();
  }
  saveFaqsFormData=    {
      faqQuestion: "",
      faqAnswer: "",
      faqCategoryName: "",
      faqId: ""
    }
    getFaqsCategory(){   
      this.commonService.callApi(this.callAPIConstants.FaqsDetails+this.faqId,'','get', false, false, false).then((success) => {
        if (success.status === 1) {
          this.saveFaqsFormData.faqQuestion = success.data.faqQuestion
          this.saveFaqsFormData.faqAnswer = success.data.faqAnswer;
          this.saveFaqsFormData.faqCategoryName = success.data.faqCategoryName;
          this.saveFaqsFormData.faqId = success.data._id;
          //this.showErrorService.popToast('success', success.message);
        }       }); 
      }
  saveFaqs(form, formData): void {
    let requestData = {
      faqQuestion:formData.faqQuestion,
      faqAnswer: formData.faqAnswer,
      faqCategoryName: formData.faqCategoryName,
      faqId: formData.faqId
    }
    this.submitted = true;
    if (form.valid) {
      // formData = JSON.parse(formData);
      this.commonService.callApi(this.callAPIConstants.AddUpdateFaqsCategory,requestData, 'post', false, false).then((resposnseData)=>{
        if (resposnseData.status === 1) {
          this.showErrorService.popToast('success', 'Updated Successfully!');
     
        } else { this.showErrorService.popToast('error', resposnseData.message); }
      });
    }
  }
 


}
