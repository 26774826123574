<div class="assign-order-modal">
	<div class="modal-header">
		<h4 class="modal-title text-center w-100">Answer Ticket</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
        <div class="col-md-6">
          <div
             class="form-group align-items-center mb-md-5 mb-3 user-details"
            >
          <label class="mb-0">
            Questioner Name:{{ this.QuestionerName|json |uppercase|titlecase}} <sup class="bx bxs-star danger"></sup
           ></label>         
          </div>
        </div>  
        <div class="col-md-6">
          <div
             class="form-group align-items-center mb-md-5 mb-3 user-details"
            >
          <label class="mb-0">
            Question:{{ this.commonObject.question|json}} <sup class="bx bxs-star danger"></sup
           ></label>         
          </div>
        </div>  
                        <form
                        (ngSubmit)="AnswerForm.form.valid &&
                        AnswerCall(AnswerForm,AnswerFormData)"
                                 #AnswerForm="ngForm" novalidate>
                                 <div class="row" >         
                                  <div class="col-md-6">
                                      <div
                                         class="form-group align-items-center mb-md-5 mb-3 user-details"
                                        >
                                      <label class="mb-0">
                                        Answer <sup class="bx bxs-star danger"></sup
                                       ></label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="CODE"
                                          [ngClass]="((submitted || answer.touched) && answer.invalid)?'has-danger':''"
                                          [(ngModel)]="AnswerFormData.answer"
                                          id="code"
                                          #answer="ngModel"                           
                                          autocomplete="off"
                                          required
                                        />
                                        <div *ngIf="((submitted || answer.touched) && answer.invalid)">  
                                          <span style="color: red;">Code Must be like Ex-0.0.0.0 </span>  
                                        </div>  
                                      </div>
                                  </div>
                                 </div>
                                
                           <div class="text-right mt-md-5 mt-3">
                            <button class="btn btn-primary"[disabled]="!AnswerForm.form.valid" >Save Changes</button>
                           </div>
                        </form> 
			</div>		
		</div>
	</div>
</div>
