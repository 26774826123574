import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VendorProfileComponent } from './vendor-profile.component';

const routes: Routes = [
  { path: '', component: VendorProfileComponent },
  { path: 'update', 
  component: VendorProfileComponent, pathMatch: 'full' 
 },
 { path: 'add', 
 component: VendorProfileComponent, pathMatch: 'full' 
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorProfileRoutingModule { }
