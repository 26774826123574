import { from } from 'rxjs';
import { SharedModule } from './../../shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorProfileRoutingModule } from './vendor-profile-routing.module';
import { VendorProfileComponent } from './vendor-profile.component';
import { AgmCoreModule } from '@agm/core';
import{ReactiveFormsModule} from'@angular/forms';

@NgModule({
  declarations: [VendorProfileComponent],
  imports: [
    CommonModule,
    VendorProfileRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCiWXQmF7JoDEed5eI9Zr9945b3t83wVbI',
      libraries: ['places']
    })
  ],schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class VendorProfileModule { }
