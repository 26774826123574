import { SharedModule } from './../../shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageOrderRoutingModule } from './manage-order-routing.module';
import { ManageOrderComponent } from './manage-order.component';
import { ListComponent } from './list/list.component';


@NgModule({
  declarations: [ManageOrderComponent, ListComponent],
  imports: [
    CommonModule,
    ManageOrderRoutingModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ManageOrderModule { }
