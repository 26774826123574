<!-- BEGIN: Sub Header  -->

<!-- END: Sub Header  -->

<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper cms-page" [hidden]="!commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <div class="card-title">
              <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
          </div>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-xl-2 col-sm-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-4">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '30px' }"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="card animated fadeIn">
      <div class="card-header text-left">
          <ngx-skeleton-loader count="1" [theme]="{ 'width': '15%' }"></ngx-skeleton-loader>
      </div>
      <div class="card-body">
          <div class="container-fluid">
              <div class="form-group row">
                  <div class="col-lg-2 col-sm-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-xl-2 col-lg-3">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-xl-10 co-lg-12">
                      <ngx-skeleton-loader count="1" [theme]="{ 'padding': '60px' }"></ngx-skeleton-loader>
                  </div>
              </div>
              <hr />
              <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
                  <div class="col-md-2">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="main-content-area">
      <div class="main-content-block">
        <h3 class="content-header-title">Meal Deals</h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                
                <li class="breadcrumb-item">
                  <a [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;"><i class="bx bx-home-alt"></i></a>
                </li>
                  <li class="breadcrumb-item active" aria-current="page">
                   {{MealValue|titlecase}}
                  </li>
              </ol>
          </nav>
          <div class="card notification-card">
            
            <div class="notification-title d-flex align-items-center text-uppercase mb-md-3 mb-2">
              <div class="icon d-flex align-items-center justify-content-center mr-1"><i class='bx bx-mail-send'></i></div>
                    <div class="text">
                        <h5 class="mb-0 text Uppercase">{{MealValue}} meal</h5>
                    </div>
              </div>
              <div class="content-wrapper " [hidden]="commonService.showSkeletonLoader">
                <form #form="ngForm" (ngSubmit)="form.form.valid &&
                addMeal(form,MealForm)">
                 <div class="row ">
                       <div class="col-md-4">
                       <div class="form-group">
                         <label for="">Name<sup class="bx bxs-star danger"></sup></label>
                         <input type="text" class="form-control"
                         [(ngModel)]="MealForm.name"
                          name="name"
                           id="name" pattern="^[a-zA-Z][a-zA-Z ]+"
                           #name="ngModel" required
                           minlength="3" maxlength="15"
                           >
                           <div *ngIf="((submitted || name.touched || name.dirty) && name.invalid)" class="error-block">
                            <div [hidden]="!name.errors.required">
                              Name is required
                            </div>
                            <div [hidden]="!name.errors.pattern">
                              Only alphabetsallowed
                            </div>
                            <div [hidden]="!name.errors.minlength">
                              Please Enter Atleast 3 Characters
                            </div>
                            <div [hidden]="!name.errors.maxlength">
                              Please Enter Atleast 15 Characters
                            </div>
                            
                          </div>
                       </div>                
                       </div>                
                      <div class="col-md-4">
                        <label for="">price<sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.price"
                         name="price"  
                         id="price" 
                         #price="ngModel" pattern="^[0-9]*$"
                         required>
                         <div *ngIf="((submitted || price.touched || price.dirty) && price.invalid)" class="error-block">
                          <div [hidden]="!price.errors.required">
                            Price is required
                          </div>
                          <div [hidden]="!price.errors.pattern">
                            Only Number allowed
                          </div>
                          <div [hidden]="!price.errors.minlength">
                            Please Enter Atleast up to 5 Digit
                          </div>
                          <div [hidden]="!price.errors.maxlength">
                            Please Enter Atleast up to 15digit
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group">
                        <label for="">discountPercentage<sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.discountPercentage"
                         name="discountPercentage" required 
                         id="discountPercentage" pattern="^[0-9]*$"
                         #discountPercentage="ngModel">
                         <div *ngIf="((submitted || discountPercentage.touched || discountPercentage.dirty) && discountPercentage.invalid)" class="error-block">
                          <div [hidden]="!discountPercentage.errors.required">
                            Discount% is required
                          </div>
                          <div [hidden]="!discountPercentage.errors.pattern">
                            Only Number allowed
                          </div>
                                                   
                        </div>
                      </div>
                      </div>
               
                      <!-- 1 categoryId -->
                      <div class="col-md-4 ">
                      <div class="form-group">
                        <label for="">foodType<sup class="bx bxs-star danger"></sup></label>
                        <ng-select [items]="foodTypeList" bindLabel="name" bindValue="name"
                        [searchable]='true' [multiple]="false"
                          [clearable]='false'  #foodType="ngModel"
                          name="foodType" 
                          id="foodType"
                          placeholder="foodType"  placeholder="foodType"
                          [(ngModel)]="MealForm.foodType" required
                         >
                        </ng-select>
                      </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Category_Product:catgeory<sup class="bx bxs-star danger"></sup></label>
                          <ng-select [items]="categoryList" bindLabel="name" bindValue="name"
                          [searchable]='true' [multiple]="false"
                            [clearable]='false'
                             name="categoryProducts_catgeory" placeholder="category"
                             id="categoryProducts_catgeory" required
                             #categoryProducts_catgeory="ngModel" (change)="categoryCount($event)"
                             [(ngModel)]="MealForm.categoryProducts[0].catgeory">
                          </ng-select>
                      </div>
                      </div>
                      
                      <!-- <div class="col-md-4">
                        <label for="">catgeory</label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.categoryProducts[0].catgeory"
                         name="categoryProducts_catgeory" 
                         id="categoryProducts_catgeory"
                         #categoryProducts_catgeory="ngModel">
                      </div> -->
                      <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Category_Product:maximumSelectableProducts<sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.categoryProducts[0].maximumSelectableProducts"
                         name="categoryProducts_maximumSelectableProducts"   pattern="^[0-9]*$" 
                         id="categoryProducts_maximumSelectableProducts" required
                         #categoryProducts_maximumSelectableProducts="ngModel">
                      </div>
                      </div>
                     
                      <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Category_Product:Product:Name<sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.categoryProducts[0].products[0].name"
                         name="categoryProductss_name" maxlength="10"
                         id="categoryProductss_name"  minlength="3"  pattern="[a-zA-Z][a-zA-Z ]+"
                         #categoryProductss_name="ngModel"
                         required>
                         <div *ngIf="((submitted || categoryProductss_name.touched || categoryProductss_name.dirty) && categoryProductss_name.invalid)" class="error-block">
                          <div [hidden]="!categoryProductss_name.errors.required">
                            category Products Name is required
                          </div>
                          <div [hidden]="!categoryProductss_name.errors.pattern">
                            Only alphabetsallowed
                          </div>
                          <div [hidden]="!categoryProductss_name.errors.minlength">
                            Please Enter Atleast 3 Characters
                          </div>
                          <div [hidden]="!categoryProductss_name.errors.maxlength">
                            Please Enter Atleast 10 Characters
                          </div>
                          
                        </div>
                      </div>
                      </div>
                     
                      <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Description<sup class="bx bxs-star danger"></sup></label>
                        <input type="text" class="form-control"
                        [(ngModel)]="MealForm.description" 
                        name="description" minlength="3"   pattern="[a-zA-Z][a-zA-Z ]+"
                        id="description" maxlength="75" placeholder="Description"
                        #description="ngModel" required
                        >
                        <div *ngIf="((submitted || description.touched || description.dirty) && description.invalid)" class="error-block">
                          <div [hidden]="!description.errors.required">
                           Description is required
                          </div>
                          <div [hidden]="!description.errors.pattern">
                            Only alphabetsallowed
                          </div>
                          <div [hidden]="!description.errors.minlength">
                            Please Enter Atleast 4 Characters
                          </div>
                          <div [hidden]="!description.errors.maxlength">
                            Please Enter Atleast 75 Characters
                          </div>
                          
                        </div>
                      </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label hidden for="">Image-type</label>
                          <input hidden type="text" class="form-control"
                          [(ngModel)]="MealForm.type" 
                          name="type" 
                          id="type"
                          #type="ngModel" 
                          >
                        </div>
                        </div>
                      <div class="col-md-4">
                      <div class="form-group mt-2">
                        <label for="name">Image</label>
                        <div class="upload-btn-wrapper">
                        
                          <div class="profile-upload" *ngIf="!this.mealID">
                            <img [src]="ImageURL?ImageURL:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <div class="profile-upload" *ngIf="this.mealID">
                            <img [src]="MealForm.image?ImageURL||MealForm.image:'../../../assets/images/default-upload.png'" class="default-img" />
                          </div>
                          <button class="btn">Upload a Image</button>
                          <input 
                          [(ngModel)]="MealForm.image"
                          id="image" 
                          type="file" 
                          name="image"
                          class="form-control"
                          #image="ngModel"
                          (change)="fileChangeEvent($event,template)">
                           
                         
                              <!-- <input type="file" name="myfile" /> -->
                            </div>
                       
                        </div>
                        </div>
                     
                   </div>
            
               <div class="text-right submit-btn-wrap" >
                <button type="submit" class="btn btn-primary submit-btn mr-2" [disabled]="!form.form.valid">Submit</button>
                <button type="button" class="btn btn-outline-primary submit-line-btn" [routerLink]="[URLConstants.MEAL_DEALS]">Cancel</button>
            </div> 
               </form>
            </div>    
        </div>
      </div>
  </div>
  <ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            Crop
            <a href="javascript:;" aria-label="Close" (click)="bsModalRef.hide()" class="modal-close">
                <em class="la la-close"></em>
            </a>
        </h4>

    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>