import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../../common/service/common.service';
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../../common/service/show-error.service';
import { RestrictKeyPressService } from '../../../../common/service/restrict-key-press.service';
import { Router } from '@angular/router';
// constants
import { URLConstants } from '../../../../common/constants/routerLink-constants';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-cusine-del-modal',
  templateUrl: './cusine-del-modal.component.html',
  styleUrls: ['./cusine-del-modal.component.scss']
})
export class CusineDelModalComponent implements OnInit {

  public callAPIConstants = callAPIConstants;
  title;
  searchText;
  commonObject:any = {};
  public dataResp;
  public city;
  public submitted = false;
  public data;
  public type;
  public apiname;
  public answerResponse :any=[];
  public orderList;
  public driver:any={};
  public  allDrviersList: any =[];
  public ID:any;
  public isVerify:any;
  public  QuestionerName:any;
  role: any;
  constructor(public modalService:BsModalService,
    public router: Router,
    public modalRef : BsModalRef, 
    public commonService : CommonService,
    public showErrorService: ShowErrorService, 
    public broadCasterService: BroadcasterService,
     public restrictKeyPressService: RestrictKeyPressService) {  
      
  }
  ngOnInit(): void {
    this.getUser();
    if(this.data){    
      this.commonObject= this.data;
      this.ID = this.data._id;
      console.log(this.ID);
      
      console.log(this.commonObject);
          
     }     
     
  }
  public appData:any={
    
  }
  getUser(){
   this.commonService.callApi(this.callAPIConstants.GetUserDetails + this.data._id,
    '', 'get', false, false).then((success) => {   
     this.title =success.data;
     this.isVerify = success.data.isVerifyByAdmin;
     this.title = success.data.fullName;
     this.role = success.data.role;
     console.log(this.title);
       
 });
  }
   //getcountries List API 
   UserApproval(): void{
    let obj = this.data._id;
    this.commonService.callApi(this.callAPIConstants.Approved_user + obj,
      '', 'get', false, false).then((success) => {   
       this.title =success.data;
       this.isVerify = success.data.isVerifyByAdmin
       console.log(this.title);
         
   });
}
}