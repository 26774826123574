import { languageType } from './../../common/constants/categoryType-constants';
import { Component, OnInit,ViewChild, ElementRef, NgZone, TemplateRef  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LoadedImage } from 'ngx-image-cropper';

import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss']
})
export class VendorProfileComponent implements OnInit {
 bsValue = new Date();
 fileToUpload: any;
  imageUrl: any;
 //bsValue1= new Date();
 uploadImageUrl = environment.uploadImgUrl;
 public latitude: any;
 public longitude: any;
   zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('address')
  public searchElementRef: ElementRef;
 public uploadvendor:any = {
    businessName:'',
    contactName: '',
    accountHolderType: '',
    bankName: '',
    accountHolderName: '',
    routingNumber: '',
    accountNumber: '',
    availability: [],
    deliveryTime:'',
    isProfileCompleted: false,
    address:'',
    latitude:'',
    longitude:'',
    restaurantLogo: '',
    foodManagerCertififcate:'',
    foodPremiseInspectionReport: '',
    foodCertificate: '',
    smartServeCertification:'',
    liquourLicense:'',
    bankProof: '',
    photo: '',
    isProvideTakeAway:false,
    
 }
  vendorID:any;
  productForm: FormGroup;
  profileForm:FormGroup;
  imageSrc: string;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  formData = new FormData();
  categoryList: any[] = [];
  foodTypeList:any[]=['Veg','Non-Veg','Eggetarian'];
  productCount:any={};
  public actID:any;
  Result: any;
  constructor(private activatedRoute: ActivatedRoute, 
  public commonService: CommonService, private router: Router,
  public showErrorService: ShowErrorService,
  public bsModalRef: BsModalRef,
  private modalService: BsModalService,
  public fb:FormBuilder,private mapsAPILoader: MapsAPILoader,
  private ngZone: NgZone,
  public localStorageService: LocalStorageService,
 ) {
   this.activatedRoute.params.subscribe((params) => { this.vendorID = params.id; });
   console.log(this.vendorID);  
 }


ngOnInit(): void {
  this.getProfile();
  this.productForm = this.fb.group({
    businessName: ['',Validators.required],
    contactName: [''],
    accountHolderType: [''],
    bankName: [''],
    accountHolderName: [''],
    routingNumber: [''],
    accountNumber: [''],
    deliveryTime:[''],
    isProfileCompleted: [false],
    address:[''],
    latitude:[''],
    longitude:[''],
    restaurantLogo: [''],
    foodManagerCertififcate:[''],
    foodPremiseInspectionReport: [''],
    foodCertificate: [''],
    smartServeCertification:[''],
    liquourLicense:[''],
    bankProof: [''],
    isProvideTakeAway:[false],
    availability: this.fb.array([]) ,
  });

  this.mapsAPILoader.load().then(() => {
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.formData.append('address',JSON.stringify(place.formatted_address))
        console.log(place);
        
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 12;
      });
    });
  });
  console.log(this.latitude);
}

availability() : FormArray {
  return this.productForm.get("availability") as FormArray
}
newavailability(day='',startTime='',endTime=''):FormGroup{
  return this.fb.group({
    day: [day],
    startTime: [startTime],
    endTime:[endTime]
  })
  
  
}
addavailability() {
  this.availability().push(this.newavailability());
}
removeavailability(i:number) {
  this.availability().removeAt(i);
}
handleFileInput(file: FileList,ph?) {
  this.fileToUpload = file.item(0);

  //Show image preview
  let reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageUrl = event.target.result;
  }
  reader.readAsDataURL(this.fileToUpload);
  this.formData.append('image', file[0]);
}
  public fileName:any;
  public photo:any;
 public certificates={
   photo:'',
   bankProof:'',
   liquourLicense:'',
   smartServeCertification:'',
   foodCertificate:'',
   foodPremiseInspectionReport:'',
   foodManagerCertififcate:'',
   restaurantLogo:''
 }
 tempImage = {
   name: '',
   type: ''
 };
 onFileSelect(e:any,ph?){
   this.tempImage.type = ph;
   console.log('setting image');
   console.log(e);
   this.fileName = e[0];
  if(ph ==='bankProof'){
    let bankProof =  this.formData.append('bankProof', e.target.value);
    this.certificates.bankProof= e.target.value;
   }else if(ph ==='liquourLicense'){
    this.certificates.liquourLicense =e.base64;
    this.formData.append('liquourLicense', e.target.value);
   }else if(ph ==='smartServeCertification'){
    this.certificates.smartServeCertification =e.base64
    this.formData.append('smartServeCertification', e[0]);
   }else if(ph ==='foodCertificate'){
    this.certificates.foodCertificate =e.base64
    this.formData.append('foodCertificate', e[0]);
   }else if(ph ==='foodPremiseInspectionReport'){
    this.certificates.foodPremiseInspectionReport =e.base64
    this.formData.append('foodPremiseInspectionReport', e[0]);
   }else if(ph ==='foodManagerCertififcate'){
    this.certificates.foodManagerCertififcate =e.base64
    this.formData.append('foodManagerCertififcate', e[0]);
   }else if(ph ==='restaurantLogo'){
    this.certificates.foodManagerCertififcate =e.base64
    this.formData.append('restaurantLogo', e[0]);
   }
 }
    // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      this.Result = results
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
/***************getprofileof vendor */
getProfile(){
  this.commonService.callApi(this.callAPIConstants.UserProfile,
    '', 'get', false, false).then((success) => {
      console.log(success.data)
     this.productForm.controls['contactName'].patchValue(success.data.contactName);
     this.productForm.controls['businessName'].patchValue(success.data.businessName);
     this.productForm.controls['accountHolderType'].patchValue(success.data.bankDetails.accountHolderName);
     this.productForm.controls['bankName'].patchValue(success.data.bankDetails.bankName);
     this.productForm.controls['accountHolderName'].patchValue(success.data.bankDetails.accountHolderName);
     this.productForm.controls['routingNumber'].patchValue(success.data.bankDetails.routingNumber);
     this.productForm.controls['accountNumber'].patchValue(success.data.bankDetails.accountNumber);
     this.productForm.controls['deliveryTime'].patchValue(success.data.deliveryTime);
     this.productForm.controls['isProfileCompleted'].patchValue(success.data.isProfileCompleted);
     let data =JSON.stringify(success.data.isProfileCompleted);
     this.productForm.controls['isProvideTakeAway'].patchValue(data);
     this.productForm.controls['address'].patchValue(success.data.address);
     this.productForm.controls['latitude'].patchValue(success.data.latitude);
     this.productForm.controls['longitude'].patchValue(success.data.longitude);
    //let res= this.uploadvendor+ this.productForm.controls['restaurantLogo'].patchValue(success.data.restaurantLogo);
     //console.log(res);
     this.certificates.restaurantLogo = this.uploadImageUrl+success.data.restaurantLogo;
     this.certificates.photo = this.uploadImageUrl+ success.data.photo;
     console.log(this.certificates.photo);
     
     this.bankProof = this.uploadImageUrl+success.data.bankDetails.bankProof;
     this.smartServeCertification = this.uploadImageUrl+ success.data.foodCertificate;
     this.foodCertificate = this.uploadImageUrl+success.data.foodManagerCertififcate  
     this.restaurantLogo = this.uploadImageUrl+success.data.restaurantLogo;   
     this.foodPremiseInspectionReport = this.uploadImageUrl + success.data.foodPremiseInspectionReport;
     this.smartServeCertification = this.uploadImageUrl + success.data.smartServeCertification; 
     this.liquourLicense = this.uploadImageUrl + success.data.liquourLicense;
     this.foodManagerCertififcate=this.uploadImageUrl + success.data.foodManagerCertififcate;
     
    success.data.availability.forEach(element => {
      console.log(element);
      
       const availabiltyFormGroup =  this.newavailability(element.day,element.startTime,element.endTime);
      console.log(availabiltyFormGroup);
      
       (<FormArray>this.productForm.get('availability')).push(availabiltyFormGroup);
       console.log(this.productForm);
       
    });
    //(this.productForm.get('availability').value as FormArray);
      
    });
     
 }
 alertevent(){
   alert()
 }
 onSubmit(){
   console.log(this.productForm.value); 
    this.formData.append('businessName',this.productForm.value.businessName);
   this.formData.append('contactName',this.productForm.value.contactName);
   this.formData.append('accountHolderType',this.productForm.value.accountHolderType);
    this.formData.append('bankName',this.productForm.value.bankName);
    this.formData.append('accountHolderName',this.productForm.value.accountHolderName);
   this.formData.append('routingNumber',this.productForm.value.routingNumber);
    this.formData.append('accountNumber',this.productForm.value.accountNumber);
   this.formData.append('photo',this.productForm.value.photo);
   this.formData.append('latitude',JSON.stringify(this.latitude));
    this.formData.append('longitude',JSON.stringify(this.longitude));
   this.formData.append('availability',JSON.stringify(this.productForm.value.availability));
   this.formData.append('isProfileCompleted',this.productForm.value.isProfileCompleted);
   this.formData.append('isProvideTakeAway',this.productForm.value.isProvideTakeAway);  
  this.commonService.callApi(this.callAPIConstants.UpdateUserProfile, this.formData,'post', false, true, false).then((success) => {
     if (success.status === 1) {
       console.log('success full');
       
       this.showErrorService.popToast('success', success.message);
     } else {
      this.showErrorService.popToast('error', success.message);
       
     }
   });
}

  
/****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
	/****************************************************************************/
  // image
  public file: any;
  imageChangedEvent = '';
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';
  ImageURL = "";
  liquourLicense = "";
  bankProof = "";
  smartServeCertification="";
  foodCertificate="";
  foodPremiseInspectionReport="";
  foodManagerCertififcate="";
  restaurantLogo="";
  imageCropped(event) {
    console.log(event);
    console.log(this.tempImage);
    
    if(this.tempImage.type==="liquourLicense"){
      this.liquourLicense = event.base64;
    } else if(this.tempImage.type ==='bankProof'){
      this.bankProof = event.base64;
    }else if(this.tempImage.type ==='smartServeCertification'){
       this.smartServeCertification=event.base64;
    }else if(this.tempImage.type ==='foodCertificate'){
      this.foodCertificate=event.base64;
    }else if(this.tempImage.type ==='foodPremiseInspectionReport'){
      this.foodPremiseInspectionReport=event.base64;
    }else if(this.tempImage.type ==='foodManagerCertififcate'){
       this.foodManagerCertififcate = event.base64;
    }else if(this.tempImage.type==='restaurantLogo'){
      this.restaurantLogo =event.base64;
    }
    
    this.croppedImage = event.base64;
    this.ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = this.ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);
  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  fileChangeEvent(event: any, template: TemplateRef<any>,ph?): void {
    console.log(ph);
    this.tempImage.type = ph;
    this.tempImage.name = event.target.files[0].name;
    this.imageChangedEvent = event;
    this.bsModalRef = this.modalService.show(template);  
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], this.tempImage.name, { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.bsModalRef.hide();
    const fd = new FormData();
  let event = {
    target : {
      value:""
    },
    base64: ""
  }
  //Show image preview

    // this.formData.append('image', this.file);
    console.log(this.file);
    if(this.tempImage.type ==='bankProof'){
      this.formData.append('bankProof', this.file);
    }else if(this.tempImage.type ==='liquourLicense'){
      this.formData.append('liquourLicense', this.file);
    }else if(this.tempImage.type ==='smartServeCertification'){
       this.formData.append('smartServeCertification',this.file);
    }else if(this.tempImage.type ==='foodCertificate'){
       this.formData.append('foodCertificate', this.file);
    }else if(this.tempImage.type ==='foodPremiseInspectionReport'){
       this.formData.append('foodPremiseInspectionReport', this.file);
    }else if(this.tempImage.type ==='foodManagerCertififcate'){
       this.formData.append('foodManagerCertififcate', this.file);
    }else if(this.tempImage.type ==='restaurantLogo'){
           this.formData.append('restaurantLogo', this.file);
      }
  }

  openfile(event: any) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  removePhoto() {
     
  }

}
