import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
// interface
import { IBreadcrumbs,ITableSetupData } from '../../../common/interfaces/interfaces';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {

  @ViewChild('responsiveDataTable') responsiveDataTable: any;
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  // setBreadcrumbs() {
  //   this.breadcrumbs = [
  //     { localeKey: 'home', url: this.URLConstants.DASHBOARD },
  //     { localeKey: 'Category', url: null},
  //   ];
  //   this.subHeaderData = {
  //     title: 'Menu Category',
  //     breadcrumbs: this.breadcrumbs,
  //   };
  // }
 
  constructor(private commonService: CommonService, private router: Router, 
    private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService, 
    public bsModalRef: BsModalRef, private modalService: BsModalService) { 
  }

  ngOnInit(): void {
    this.setDTableInitialData();
    console.log(' im  in cuisuin');
    console.log(this.tableSetupData);
    
    
  }
 /*********************************************************************************************
  @Purpose     : Datatable config settings
  @Parameter   : NA
  @Return      : Datatable config
  @optional    : In tempData
                  sort: Boolean ( To enable Sort for that Column )
                  isVisible: Boolean ( Show that Column Statically )
                  columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                  filter: Boolean ( To enable Filter for that Column )
  /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {} };
  setDTableInitialData() {
    const tempData = [
      
      { type: 'text', colName: 'Name', colFieldname: 'name', isVisible: true, sort: true, columnVisibility: true, filter: true },
      
    ];
    // if (!this.accessPermission.edit && !this.accessPermission.delete) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    this.tableSetupData.type ='vendorcuisineList';
    // this.tableSetupData.actions = [
    //   {
    //     id: 1, buttonTitle: 'view', tooltip: 'View', class: 'bx bx-show-alt',
    //     type: 'icon',
    //   },
    //    {
    //     id: 2, buttonTitle: 'Edit', tooltip: 'Edit', class: 'bx bx-edit text-primary',
    //     type: 'icon', 
    //   },
    //   {
    //     id: 3, buttonTitle: 'delete', tooltip: 'Delete', class:"bx bx-trash-alt text-danger",
    //     type: 'icon',
    //   }
    // ];
    this.tableSetupData.params = { deleteParams: 'cuisineId', statusParams: 'cuisineId' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: true,
      showExport: false,
      showColumnHide: true,
      showFilter: true,
      showFilterExport: false,
      showSaveFilter: false,
         };
  }
}
