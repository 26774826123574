import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {
  // Variable declarations
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen:boolean = false;
  fieldTextType: boolean;
  public resetPassword: any = {};
  constructor(private themeService: ThemeService,
    private localStorageService: LocalStorageService,private router: Router, private commonService: CommonService, private showErrorService: ShowErrorService
    ) { }

  ngOnInit(): void {
    var isDark=this.localStorageService.getToken('theme')=="Dark"?true:false;
    this.themeService.switchThemeAfterLogin(isDark);
    if(isDark)
    document.getElementById("resetPassSwitchTheme").setAttribute('checked','');
  }

  onResetPassword(form) {
    this.resetPassword={
      password: form.value.password,
      token: window.location.href.split("token=")[1]
      }
      if(this.localStorageService.getToken('role') ==='superAdmin'){
        this.commonService.callApi(this.callAPIConstants.ResetPassword, this.resetPassword, 'post', true, false).then((success) => {
          if (success.status === 1) {
             this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
           this.showErrorService.popToast('error', success.message);
        }
       });
      }else if(this.localStorageService.getToken('role') ==='vendor'){
        this.commonService.callApi(this.callAPIConstants.UserResetpass, this.resetPassword, 'post', true, false).then((success) => {
          if (success.status === 1) {
             this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
           this.showErrorService.popToast('error', success.message);
        }
       });
      }
    
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
 }

 toggleTheme() {
  var theme =  this.themeService.switchTheme();
  this.localStorageService.setToken('theme', theme);  
}

 toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
  }
}


