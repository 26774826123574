import { VendorListComponent } from './vendor-list/vendor-list.component';
import { CreateComponent } from './create/create.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';

import { TicketManagementComponent } from './ticket-management.component';
import { AnswerTicketComponent } from './answer-ticket/answer-ticket.component';

const routes: Routes = [
  {
    path: 'vendorlist', component: VendorListComponent, pathMatch: 'full',
  },

  {
    path: 'list', component: ListComponent, pathMatch: 'full',
  },
  {
    path: 'create',
    component: CreateComponent,
    pathMatch: 'full',
  },
  {
    path:'answer/:id',
    component:AnswerTicketComponent,
    pathMatch:'full'
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketManagementRoutingModule { }
