export const callAPIConstants = {
    //common
    CommonColumnVisibleSettings: 'saveColumnSettings',
    deleteDownloadTemplate: 'deleteTemplateSettings/',
    // AdminsaveFilter:'saveFilter',
    SaveDownloadTemplate: 'saveTemplateSettings',
    getDownloadTemplate: 'getTemplateSettings',
    // AdminDeleteFilter :'deleteFilter​/',
    //user
    UserLogin:'users/login',
    UserLogout:'users/logout',
    UserChangePassword:'users/changePassword',
    UserResetpass:'users/resetPassword',
    VendorProfile:'users/profile',
    UserProfile:'users/vendorProfile',
    UpdateVendorProfile:'users/updateUserProfile',
    UpdateUserProfile:'vendor/updateVendorProfile',  
    Add_User:'user/addUserByAdmin',
    AddAdminUser : 'admin/addAdminUser',
    UpdateAdminUser : 'admin/updateUser',
    AdminList: 'admin/userListing',
    AdminDeleteUsers: 'admin/deleteUsers',
    AdminChangeStatus: 'admin/changeStatus',
    GetAdminDetails: 'admin/userProfile/',
    downloadAdminUserFile: 'downloadAdminFile',
    Approved_user:'user/approveUser/',
    //admin
    //GetAdminDetails:'admin/userListing',
    AdminLogin: 'admin/login',
    AdminRegister: 'admin/register',
    VendorRegister:'vendor/register',
    VerifyOTP: 'admin/verifyOTP',
    SendOTP: 'admin/sendOTP',
    ForgotPasswordVendor:'users/forgotPassword',
    ForgotPassword: 'admin/forgotPassword',
    ResetPassword: 'admin/resetPassword',
    AdminLogout: 'admin/logout',
    AdminGetProfile: 'admin/profile',
    VenderGetProfile:'users/vendorProfile',
    AdminEditProfile: 'admin/editProfile',
    VendorEditProfile:'vendor/updateVendorProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries: 'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    //social media sdk
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    //social media link
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    //global setiing 
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    //payment setting
    getPaymentDetails: "getPaymentDetails",
    addPaymentDetails: "addPaymentDetails",
    //smtp setting
    addSMTPAndSMSSettings: "addSMTPAndSMSSettings",
    getSMTPAndSMSSettings: "getSMTPAndSMSSettings",
    //email notification
    getEmailNotificationSettings:"getEmailNotificationSettings",
    addEmailNotificationSettings:"addEmailNotificationSettings",

    // email-template
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmailColumnData: 'getEmailColumnValues',
    // EmailColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',

    //Faq
    VendorFaqsListing:'/faqslisting',
    FaqsListing: 'admin/faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    AddUpdateFaqs: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryDelete:'deleteFAQCategories',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',
    //cms
    cmsListing:'cmsList',
    cmsDetail​:'cmsDetail/',
    CmsAddUpdate:'addUpdateCMS',
    //role
    RoleList: 'listRole',
    GetRoleDetail: 'getRolePermission/',
    AddUpdateRole: 'addRole',
    RoleChangeStatus: 'changeRoleStatus',
    RoleGetColumnData: 'roleFieldsList',
    RoleGetaddedByColumnData: 'adminListInRoles',

    // static && Blogs
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddUpdateBlog: 'addUpdateBlog',

    // user
    UserList: 'user/userListing',
    UserFileUpload: 'users/fileUpload',
    UserDeleteUsers: 'user/deleteUsers',
    UserChangeStatus: 'user/changeStatus',
    GetUserDetails: 'user/userProfile/',
    UserCSVforDataTable: 'user/downloadCsv',
    UserEXCELforDataTable: 'user/downloadExcel',
    UserColumnVisibleSettings: 'user/columnSettings',
   
    downloadUserFile: 'downloadUserFile',
    UserGetColumnData: 'user/getColumnValues',
    UserGetFilters: 'user/getFilters',
    //vendor coupan manage
    CoupanList:'listCoupons',
    CoupanChangeStatus:'changeCouponStatus',
    AddCoupan:'addCoupon',
    GetCoupan:'getCouponDetails/',
    DeleteCoupan :'/deleteCoupon',
    // common
    deleteFilter: 'deleteFilter/',
    saveFilter: 'saveFilter',
    getNotifications:'notificationListing',
    getNotificationCount:'getNotificationCount',
    //Earning
    earningList:'earningListing',
    withdrawList:'withdrawalRequestListing',
       //mealDeal /vendor/addUpdateMealDeal
    AddMealDeal:'vendor/addUpdateMealDeal',
    mealdealList:'vendor/mealDealList',
    mealdealStatus:'vendor/changeMealDealStatus',
    mealdealDelete:'vendor/mealDealDelete/',
    DetailsMealdeals:'vendor/mealDealDetail/', 
    //Cuisine
    getCuisine:'getCuisineList',
    getCuisine_Admin:'admin/getCuisineList',
    AddCuisine:'admin/addUpdateCuisine',
    DeleteCuisine:'admin/deleteCuisine/',
    DetailCuisine:'admin/getCuisineDetails/',
    changeCuisineStatus:'admin/changeCuisineStatus',
       //attributelist
    getAttribute:'vendor/listAttributes',
​    changeAttributeStatus:'vendor/changeAttributeStatus',
    deleteAttribute:'vendor/deleteAttribute',
    getAttributeDetails:'vendor/getAttributeDetails/',
    addAttribute:'vendor/addAttribute',
    //vendor
    productList:'vendor/listProduct',
    categoryList:'vendor/listCategory',
    changeCategorystatus:'vendor/changeCategoryStatus',
    AddUpdateCategory:'vendor/addUpdateCategory',
    DeleteCategory:'vendor/deleteCategory',
    Category_Details:'vendor/getCategoryDetails/',
    ProductCategoryList:'vendor/listCategoryProduct',
    //Ticket
    createTicket:'createTicket',
    adminTicketList:'admin/listTickets',
    vendorTicketList:'listTickets',
    adminAnswerTicket:'admin/answerTicket',
    //role
    Role_Category:'/getCategory',
    Role_Permission :'/getAllPermission',
    //product
    Add_Product:'vendor/addUpdateProduct',
    Product_Detail:'vendor/getProductDetail/',
    Delete_Product:'vendor/deleteProduct',
    Product_Status:'vendor/changeProductStatus', 
    //order
    Orderlist:'newOrderListForVendor',  
    Reviewlist:'reviewListing',
    ​

};

