import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttributeRoutingModule } from './attribute-routing.module';
import { AttributeComponent } from './attribute.component';
import { ListComponent } from './list/list.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import{ReactiveFormsModule}from'@angular/forms';

@NgModule({
  declarations: [AttributeComponent, ListComponent, ViewDetailsComponent, EditDetailsComponent],
  imports: [
    CommonModule,
    AttributeRoutingModule,ReactiveFormsModule,
    SharedModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class AttributeModule { }
